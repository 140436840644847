import { useOrganization } from '@/modules/backend/hooks/use-organization';
import { Gutter } from '@/modules/layout/components/gutter';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { OrgPodcastList } from './org/podcast-list';
import { FlexRow } from '@/modules/layout/components/grid/flex-row';

export const ManagerOrg = () => {
  const { orgId } = useParams();
  const { organization, load } = useOrganization(orgId!);
  useEffect(() => { load() }, [orgId, load]);
  return (
    <Gutter>
      <FlexRow style={{alignItems: 'center', justifyContent: 'space-between'}}>
        <h1>{organization?.name}</h1>
      </FlexRow>
      <OrgPodcastList />
    </Gutter>
  );
}