import { useTicketStats } from '@/modules/backend/hooks/use-ticket-stats';
import { TicketStatus } from '@nimey/podcast-global-entity';
import { useEffect, useState } from 'react';
import { Fragment } from 'react/jsx-runtime';
import { TicketBoardColumn } from './column';
import { useHashStateFlagRegex } from '@/modules/global/hooks/use-hash-state-flag';

import styles from './board.module.scss';

import { TicketModal } from '@/modules/global/components/ticket/ticket-modal';
import { Hr } from '@/modules/layout/components/hr';


const TicketBoardModalWrapper = () => {
  const { state, matches, unset } = useHashStateFlagRegex(/^#ticket-(?<ticketId>.*)/);
  const ticketId = state && matches?.groups?.ticketId ? matches.groups.ticketId : undefined;
  return (
    <TicketModal ticketId={ticketId} isOpen={state} close={() => unset()} />
  );
}

export const TicketBoard = () => {
  const [ showClosed, setShowClosed ] = useState<boolean>(false);
  const {load, ticketStats} = useTicketStats();
  useEffect(() => {
    load();
  }, [])
  return (
    <Fragment>
      <div><button onClick={() => setShowClosed(c => !c)}>Toggle Closed Tickets</button></div>
      <Hr />
      <div className={styles.columns} style={{gridTemplateColumns: Object.keys(ticketStats).filter(status => showClosed || status !== TicketStatus.CLOSED).map(_ => '1fr').join(' ')}}>
        {Object.keys(ticketStats || {}).filter(status => showClosed || status !== TicketStatus.CLOSED).map((status: unknown) => 
          <TicketBoardColumn 
            key={status as string} 
            status={status as TicketStatus}
            count={ticketStats[status as string].count}
          />
        )}
      </div>
      <TicketBoardModalWrapper />
    </Fragment>
  );
}