export var ImageSize;
(function (ImageSize) {
    ImageSize["SMALL"] = "small";
    ImageSize["SMALL_2X"] = "small2x";
    ImageSize["SMALL_4X"] = "small4x";
    ImageSize["MEDIUM"] = "medium";
    ImageSize["MEDIUM_2X"] = "medium2x";
    ImageSize["MEDIUM_4X"] = "medium4x";
    ImageSize["LARGE"] = "large";
    ImageSize["LARGE_2X"] = "large2x";
    ImageSize["LARGE_4X"] = "large4x";
    ImageSize["IOS_96"] = "ios_96x96";
    ImageSize["IOS_128"] = "ios_128x128";
    ImageSize["IOS_192"] = "ios_192x192";
    ImageSize["IOS_256"] = "ios_256x256";
    ImageSize["IOS_384"] = "ios_384x384";
    ImageSize["IOS_512"] = "ios_512x512";
})(ImageSize || (ImageSize = {}));
