import { BaseController } from './base.controller.js';
export class TicketController extends BaseController {
    client;
    constructor(client) {
        super();
        this.client = client;
    }
    list(filter = {}, skip = 0, limit = 5) {
        const params = new URLSearchParams({
            skip: skip.toString(),
            limit: limit.toString(),
        });
        Object.entries(filter).forEach(e => params.set(`filter[${e[0]}]`, String(e[1])));
        return this.client.get('/ticket', params);
    }
    getById(id) {
        return this.client.get(`/ticket/${id}`);
    }
    stats() {
        return this.client.get(`/ticket/stats`);
    }
    create(createPayload) {
        return this.client.post('/ticket', createPayload);
    }
    deleteById(id) {
        return this.client.delete(`/ticket/${id}`);
    }
    getMessages(ticketId, skip = 0, limit = 5) {
        const params = new URLSearchParams({
            skip: skip.toString(),
            limit: limit.toString(),
        });
        return this.client.get(`/ticket/${ticketId}/message`, params);
    }
    addMessage(message) {
        return this.client.post(`/ticket/${message.ticketId}/message`, message);
    }
    getActions(ticketId) {
        return this.client.get(`/ticket/${ticketId}/action`);
    }
    execAction(ticketId, actionType, input) {
        return this.client.post(`/ticket/${ticketId}/action/${actionType}`, input);
    }
}
