import { Podcast, PodcastEpisode } from '@nimey/podcast-global-entity';
import { MediaPlayerSettings } from './media-player-state.js';

export enum MediaPlayerActionKind {
  SET_EPISODE_ID = 'set_episode_id',
  SET_EPISODE = 'set_episode',
  SET_EPISODE_LOADING = 'set_episode_loding',
  SET_PLAY = 'set_play',
  SET_AUDIO_REF = 'set_audio_ref',
  SET_SETTINGS = 'set_settings',
};

type PayloadType = {
  [MediaPlayerActionKind.SET_EPISODE_ID]: string | undefined;
  [MediaPlayerActionKind.SET_EPISODE]: PodcastEpisode | undefined;
  [MediaPlayerActionKind.SET_EPISODE_LOADING]: boolean;
  [MediaPlayerActionKind.SET_PLAY]: boolean;
  [MediaPlayerActionKind.SET_AUDIO_REF]: HTMLAudioElement | null;
  [MediaPlayerActionKind.SET_SETTINGS]: MediaPlayerSettings;
}


type PayloadTypeGenerator<T extends MediaPlayerActionKind> = {type: T, payload: PayloadType[T]};

export type MediaPlayerAction = PayloadTypeGenerator<MediaPlayerActionKind.SET_EPISODE_ID>
  | PayloadTypeGenerator<MediaPlayerActionKind.SET_EPISODE>
  | PayloadTypeGenerator<MediaPlayerActionKind.SET_EPISODE_LOADING>
  | PayloadTypeGenerator<MediaPlayerActionKind.SET_PLAY>
  | PayloadTypeGenerator<MediaPlayerActionKind.SET_AUDIO_REF>
  | PayloadTypeGenerator<MediaPlayerActionKind.SET_SETTINGS>