import { useOrganization } from '@/modules/backend/hooks/use-organization';
import { usePodcast } from '@/modules/backend/hooks/use-podcast';
import { Organization, Podcast } from '@nimey/podcast-global-entity';
import { PropsWithChildren, createContext, useContext, useEffect } from 'react';

export const OrganizationProviderContext = createContext<Organization | undefined>(undefined);

export const useLocalOrganization = () => {
  return useContext(OrganizationProviderContext);
}

type OrganizationProviderProps = PropsWithChildren<{
  orgId: string;
}>;

export const OrganizationName = () => {
  const org = useLocalOrganization();

  return <>{org?.name}</>
}

export const OrganizationProvider = (props: OrganizationProviderProps) => {
  const { organization, load } = useOrganization(props.orgId);
  useEffect(() => { load() }, [load, props.orgId])

  return (
    <OrganizationProviderContext.Provider value={organization}>
      {props.children}
    </OrganizationProviderContext.Provider>
  );
}