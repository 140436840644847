import { Fragment } from 'react/jsx-runtime';
import { TicketActionInputProps } from './base';
import {  RegexValidator, TextInputWithValidation } from '@nimey/react-ui';
import { TicketActionTextInputType } from '@nimey/podcast-global-entity';

export const TicketActionInputText = (props: TicketActionInputProps) => {
  const input = props.input as TicketActionTextInputType;

  const validator = new RegexValidator(input.validationRegex ? new RegExp(input.validationRegex) : /.*/).withMessage('invalid input');

  return (
    <Fragment>
      <TextInputWithValidation
        name={input.name}
        placeholder={input.label}
        validator={validator}
      />
    </Fragment>
  )
}