import { useUser } from '@/modules/backend/hooks/use-user';
import { useEffect } from 'react';
import { Fragment } from 'react/jsx-runtime';

export type UserNameInfoProps = {
  userId?: string;
}

export const UserNameInfo = (props: UserNameInfoProps) => {
  const {user, load} = useUser(props.userId)

  useEffect(() => { load() }, [props.userId, load])

  return (
    <Fragment>
      <span title={user?.email}>{user?.name || '...'}</span>
    </Fragment>
  )
}