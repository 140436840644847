import { useContext } from 'react';
import { BackendDispatchContext, BackendStateContext } from '../context/backend-context';
import { TicketFilter } from '@nimey/podcast-global-entity';
import { useEntityList } from './use-entity-list';



export const useTicketList = (filter: TicketFilter) => {
  const { asyncActions } = useContext(BackendDispatchContext)

  return useEntityList(filter, 'ticket', asyncActions!.loadTicketList.bind(asyncActions!))
}