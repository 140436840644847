import { BaseTicket, TicketActionType } from '@nimey/podcast-global-entity';
import { TicketActionInput } from './input/base';

import styles from './ticket-action.module.scss';
import { withValidator } from '@/modules/global/hoc/with-validator';
import { FormEvent, useRef } from 'react';
import { useIsValid, useValidationContext } from '@nimey/react-ui';
import { useExecuteTicketAction } from '@/modules/backend/hooks/use-execute-ticket-action';
import { Spinner } from '@/modules/layout/components/spinner';

type TicketActionProps = {
  ticket: BaseTicket,
  action: TicketActionType,
  load: (force?: boolean) => Promise<void>
}
const TicketActionForm = (props: TicketActionProps) => {
  const {ticket, action} = props;
  const formRef = useRef<HTMLFormElement>(null);
  const { execute, loading } = useExecuteTicketAction(props.ticket.id, action.type);
  const validation = useValidationContext();

  const onSubmitAction = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!formRef.current) return;
    const formData = new FormData(formRef.current);
    if (Object.values(validation.validationState || {}).find(o => !o?.result?.isValid)) {
      alert('form invalid');
      return;
    }

    execute(Object.fromEntries(formData.entries()) as Record<string, string>).then(() => {
      props.load(true);
    })
  }

  if (loading) return <li className={styles['list-item']}><Spinner size='2em' /></li>

  return (
    <form ref={formRef} onSubmit={onSubmitAction}>
      <li className={styles['list-item']}>
          {action.input.map(input => <TicketActionInput key={input.type} {...{ticket, input}} />)}
      </li>
    </form>
  )
  
}

export const TicketAction = withValidator(TicketActionForm, 'action')