import { Table, Td, Th, Tr } from '@/modules/layout/components/table';
import { PodcastClaimOwnershipTicket, PodcastRequestTicket } from '@nimey/podcast-global-entity';
import { Fragment } from 'react/jsx-runtime';
import { PodcastProvider, useLocalPodcast } from '../../podcast/podcast-provider';
import { PodcastTitle } from '../../podcast/title';
import { OrganizationName, OrganizationProvider } from '../../organization/organization-provider';

type TicketModalProps = {
  ticket: PodcastClaimOwnershipTicket;
  isOpen: boolean;
  close: () => void;
}


const CurrentOrgTitle = () => {
  const { podcast } = useLocalPodcast();
  if(!podcast || !podcast.orgId) return <>---</>

  return <OrganizationProvider orgId={podcast.orgId}><OrganizationName /></OrganizationProvider>
}

export const TicketTypePodcastClaimOwnership = (props: TicketModalProps) => {
  const { ticket } = props;

  return (
    <PodcastProvider podcastId={ticket.podcastId}>
      <pre>{JSON.stringify(ticket, null, 2)}</pre>
      <Table>
        <tbody>
          <Tr>
            <Th>Title</Th>
            <Td><PodcastTitle /></Td>
          </Tr>
          <Tr>
            <Th>Aktuelle Orgnisation</Th>
            <Td><CurrentOrgTitle /></Td>
          </Tr>
          <Tr>
            <Th>Neue Orgnisation</Th>
            <Td><OrganizationProvider orgId={ticket.orgId}><OrganizationName /></OrganizationProvider></Td>
          </Tr>
        </tbody>
      </Table>
    </PodcastProvider>
  );
}