import { useContext } from 'react';
import { BackendDispatchContext, BackendStateContext } from '../context/backend-context';
import { User } from '@nimey/podcast-global-entity';
import { AuthStateContext } from '@/modules/auth/context/auth-context';
import { UserRoles } from '../reducer/backend-async-actions';

export const useUserRoles = (id?: string) => {
  const { asyncActions } = useContext(BackendDispatchContext)
  const state = useContext(BackendStateContext);
  const authState = useContext(AuthStateContext);


  const currentAccountId = id || authState.currentAccountId!;

  const entity = currentAccountId && state.entities['user-roles'] ? state.entities['user-roles'][currentAccountId] || undefined : undefined;

  const isLoaded = !!entity;
  return {
    userRoles: (entity?.entity as UserRoles)?.roles || [] as string [],
    isLoaded: !!entity,
    load: async (force: boolean = false) => {
      if(currentAccountId && (!isLoaded || force)) asyncActions!.loadUserRoles(currentAccountId);
    }
  };
}