import { errorFactory } from '@nimey/podcast-global-entity';
export class Client {
    authenticated;
    backendUrl;
    constructor(authenticated, backendUrl) {
        this.authenticated = authenticated;
        this.backendUrl = backendUrl;
    }
    _generateUrl(apiPath, queryParams) {
        const url = new URL(this.backendUrl);
        url.pathname = apiPath;
        url.search = new URLSearchParams(queryParams).toString();
        return url.toString();
    }
    _wrapResponse(headers, response) {
        const extractTags = (key) => {
            const tags = new Set();
            if (!headers.has(key))
                return tags;
            headers.get(key).split(',').map(v => v.trim()).forEach(tags.add, tags);
            return tags;
        };
        return {
            response,
            cacheTags: extractTags('x-cache-tags'),
            flushCacheTags: extractTags('x-flush-cache-tags')
        };
    }
    async _generateHeaders(init) {
        const headers = new Headers(init);
        const token = await this.authenticated.getAccessToken();
        headers.set('authorization', `${token.token_type} ${token.access_token}`);
        return headers;
    }
    async get(apiPath, queryParams) {
        const headers = await this._generateHeaders();
        const response = await fetch(this._generateUrl(apiPath, queryParams), { headers });
        if (response.status !== 200) {
            await this._handleError(response);
        }
        const result = await response.json();
        return this._wrapResponse(response.headers, result);
    }
    async delete(apiPath) {
        const headers = await this._generateHeaders();
        const response = await fetch(this._generateUrl(apiPath), { method: 'DELETE', headers });
        if (response.status !== 200) {
            await this._handleError(response);
        }
        const result = await response.json();
        return this._wrapResponse(response.headers, result);
    }
    async post(apiPath, payload, queryParams) {
        const headers = await this._generateHeaders({
            'content-type': 'application/json'
        });
        const response = await fetch(this._generateUrl(apiPath, queryParams), {
            method: 'POST',
            headers,
            body: JSON.stringify(payload),
        });
        if (response.status !== 200) {
            await this._handleError(response);
        }
        const result = await response.json();
        return this._wrapResponse(response.headers, result);
    }
    async put(apiPath, payload) {
        const headers = await this._generateHeaders({
            'content-type': 'application/json'
        });
        const response = await fetch(this._generateUrl(apiPath), {
            method: 'PUT',
            headers,
            body: JSON.stringify(payload),
        });
        if (response.status !== 200) {
            await this._handleError(response);
        }
        const result = await response.json();
        return this._wrapResponse(response.headers, result);
    }
    async _handleError(response) {
        if (response.headers.has('content-type') && response.headers.get('content-type').match(/^application\/json/)) {
            const errData = await response.json();
            throw errorFactory(errData);
        }
        throw new Error('invalid response ' + response.status);
    }
}
