import { BaseController } from './base.controller.js';
export class TokenController extends BaseController {
    client;
    constructor(client) {
        super();
        this.client = client;
    }
    processToken(token) {
        return this.client.post(`/token/process`, { token });
    }
}
