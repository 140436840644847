import { Organization, Podcast, PodcastEpisodeListType, PodcastListType } from '@nimey/podcast-global-entity';
import { withCurrentOrg } from '../../hoc/with-current-org';
import  { Fragment, useEffect } from 'react';
import { usePodcastList } from '@/modules/backend/hooks/use-podcast-list';
import { PodcastProvider, useLocalPodcast } from '@/modules/global/components/podcast/podcast-provider';
import { PodcastTitle } from '@/modules/global/components/podcast/title';
import { LoadMore } from '@/modules/layout/components/load-more';
import styles from './podcsat-list.module.scss';
import { usePodcastEpisodeList } from '@/modules/backend/hooks/use-podcast-episode-list';
import { Link } from 'react-router-dom';
import { Button } from '@nimey/react-ui';

const PodcastRendererWithPodcast = ({podcast, org}: {podcast: Podcast, org: Organization}) => {
  const { meta, loadMore } = usePodcastEpisodeList({podcastId: podcast.id}, PodcastEpisodeListType.RECENT);
  useEffect(() => {
    loadMore();
  }, [])
  return (
    <Link to={`/manager/${org.id}/podcast/${podcast.id}`} className={styles.podcast}>
      <div className={[styles.flex, styles['space-between']].join(' ')}>
        <div className={styles.title}><PodcastTitle /></div>
      </div>
      <div className={[styles.flex, styles['space-between']].join(' ')}>
        <div>{meta?.totalCount} Episoden</div>
        <div>Letzte Episode: {podcast.latestEpisode ? `${(new Date(podcast.latestEpisode)).toLocaleDateString()} ${(new Date(podcast.latestEpisode)).toLocaleTimeString()}` : 'N/A'}</div>
      </div>
    </Link>
  );
}

const PodcastRenderer = ({org}: {org: Organization}) => {
  const { podcast } = useLocalPodcast();

  if(podcast) return <PodcastRendererWithPodcast {...{podcast, org}} />

  return <></>
}

const OrgPodcastListWithOrg = (props: {org: Organization}) => {
  const { org } = props;

  const { allIds, hasMore, loadMore } = usePodcastList({orgId: org.id}, PodcastListType.ALL);
  return (
    <Fragment>
      <Link to={`/manager/${org.id}/add`}><Button>Neuen Podcast hinzufügen</Button></Link>
      {allIds.length === 0 ? (
        <p>Diese Organisation verwaltet noch keine Podcasts</p>
      ) : ''}
      <ul className={styles['podcast-list']}>
        {allIds.map(id => (
          <li key={id} className={`${styles['list-entry']} ${styles.card}`}>
            <PodcastProvider podcastId={id}>
              <PodcastRenderer {...{org}} />
            </PodcastProvider>
          </li>
        ))}
        <LoadMore {...{hasMore, loadMore}} />
      </ul>
      
    </Fragment>
  );
}

export const OrgPodcastList = withCurrentOrg<{}>(OrgPodcastListWithOrg);