import { Fragment } from 'react/jsx-runtime';
import { MenuItem } from './menu-item';
import { MdHelp, MdOutlinePodcasts, MdRssFeed, MdSupportAgent } from 'react-icons/md';
import { RoleGuard } from '@/modules/backend/components/role-guard';
import { FeatureGuard } from '@/modules/backend/components/feature-guard';
import { FeatureFlag } from '@nimey/podcast-global-entity';
export const MenuDefault = () => {
  return (
    <Fragment>
      <MenuItem title='Player' icon={MdOutlinePodcasts} to='/player' />
      <FeatureGuard flag={FeatureFlag.MANAGER}>
        <MenuItem title='Manager' icon={MdRssFeed} to='/manager' />
      </FeatureGuard>

      <FeatureGuard flag={FeatureFlag.HELP_CENTER}>
        <MenuItem title='Help Center' icon={MdHelp} to='/help' />
      </FeatureGuard>

      <RoleGuard roles={['supporter', 'super-admin']}>
        <MenuItem title='Support' icon={MdSupportAgent} to='/support' />
      </RoleGuard>
    </Fragment>
  );
}