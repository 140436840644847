import { useCurrentAccount } from '@/modules/auth/hooks/use-current-account'
import { useCallback, useContext, useEffect, useState } from 'react';
import { BackendDispatchContext, BackendStateContext } from '../context/backend-context';
import { usePlaylist } from './use-playlist';
import { BackendActionKind } from '../types/backend-action';

export const useCurrentPlaylist = () => {
  const acc = useCurrentAccount();
  const { playlist, load, isLoaded} = usePlaylist(acc?.id)

  useEffect(() => {
    if(!isLoaded) load();
  }, [acc, isLoaded, load])

  return { playlist };
}