import { Gutter } from '@/modules/layout/components/gutter';

import { AboPodcasts } from './list/abo-podcasts';
import { RecentEpisodesWithAbo } from './list/recent-episodes-with-abo';
import { withCurrentUserId } from '@/modules/backend/hoc/with-current-userid';
import { SelectAccountBanner } from '@/modules/auth/components/select-account-banner';
import { AnonymousPodcasts } from './list/anonymous-podcasts';

export const PlayerHomeWithUser = () => {
  return (
    <Gutter>
      <AboPodcasts />
      <RecentEpisodesWithAbo />
    </Gutter>
  );
}

const PlayerHomeAnonymous = () => {
  return (
    <Gutter>
      <p>Du bist nicht angemeldet. Wähle jetzt dein Konto oder erstelle ein neues, um alle Funktionen zu nutzten.</p>
      <SelectAccountBanner />
      <AnonymousPodcasts limit={16} />
    </Gutter>
  );
}

export const PlayerHome = withCurrentUserId(PlayerHomeWithUser, PlayerHomeAnonymous)