import { withCurrentUserId } from '@/modules/backend/hoc/with-current-userid';
import { usePodcastEpisodeUserHistory } from '@/modules/backend/hooks/use-podcast-episode-user-history';
import { ArtworkWrapper } from '@/modules/global/components/podcast/artwork';
import { EpisodeArtwork } from '@/modules/global/components/podcast/episode/artwork';
import { EpisodeProvider } from '@/modules/global/components/podcast/episode/episode-provider';
import { EpisodeTitle } from '@/modules/global/components/podcast/episode/title';
import { Gutter } from '@/modules/layout/components/gutter';
import { LoadMore } from '@/modules/layout/components/load-more';
import { PlayEpisode } from '@/modules/media-player/components/play-episode';
import styles from './history.module.scss';
import { Hr } from '@/modules/layout/components/hr';
import { useTranslation } from 'react-i18next';
import { ImageSize } from '@nimey/podcast-global-entity';
import { EpisodeRendererList } from './list/episode';

type Props = {
  userId: string;
}

export const History = (props: Props) => {
  const { allIds, loadMore, hasMore } = usePodcastEpisodeUserHistory(props.userId)
  const { t } = useTranslation('player');
  return (
    <Gutter>
      <h1 className={styles.headline}>{t('history.title')}</h1>
      <ul className={styles.list}>
        {allIds.map(episodeId => (
          <li className={styles.entry} key={episodeId}>
            <EpisodeRendererList id={episodeId} hideDescription={true} hidePubDate={true} />
          </li>
        ))}
        <li><LoadMore loadMore={loadMore} hasMore={hasMore} /></li>
      </ul>
    </Gutter>
  );
}

const LoginRequired = () => (
  <p>Du musst angemeldet sein, um den Verlauf zu verwenden</p>
)

export const PodcastHistory = withCurrentUserId(History, LoginRequired)