import { useLocalEpisode } from './episode-provider';

import styles from './title.module.scss';

type EpisodeTitleProps = {
  maxLines?: number;
}

export const EpisodeTitle = (props: EpisodeTitleProps) => {
  const { episode } = useLocalEpisode();
  const maxLines = props.maxLines || 2;

  return (
    <span style={{'--number-of-lines': maxLines}} className={styles['max-lines']}>{episode?.title}</span>
  );
}