import { BaseTicket, TicketActionInputTypes, isTicketActionCtaInput, isTicketActionTextInput } from '@nimey/podcast-global-entity';
import { TicketActionInputCta } from './cta';
import { TicketActionInputText } from './text';
export type TicketActionInputProps = {
  ticket: BaseTicket;
  input: TicketActionInputTypes
}

export const TicketActionInput = (props: TicketActionInputProps) => {
  const { input } = props

  if(isTicketActionCtaInput(input)) return <TicketActionInputCta {...props} />
  if(isTicketActionTextInput(input)) return <TicketActionInputText {...props} />

  return (
    <pre>{JSON.stringify(input, null, 2)}</pre>
  )
}