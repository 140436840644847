import { useTicket } from '@/modules/backend/hooks/use-ticket';
import { UserNameInfo } from '@/modules/global/components/user/user-name-info';
import { useCalculatedColor } from '@/modules/global/hooks/use-calculated-color';
import { useHashStateFlag } from '@/modules/global/hooks/use-hash-state-flag';
import { Fragment } from 'react/jsx-runtime';
import styles from './tickets.module.scss';
import { Spinner } from '@/modules/layout/components/spinner';
import { TicketTypeLabel } from './type-label';
import { TicketStatusLabel } from './status-label';

export type TicketCardProps = {
  ticketId: string;
}

export const TicketCard = (props: TicketCardProps) => {
  const { ticket } = useTicket(props.ticketId);
  const color = useCalculatedColor(ticket?.type || '');
  const [_, setModal] = useHashStateFlag(`ticket-${props.ticketId}`)
  return (
    <Fragment>
      <div className={`${styles.card} ${styles[ticket?.status || '']}`} style={{backgroundColor: `${color}99`}} onClick={() => setModal(true)}>
        <div>
          <div>{ticket?.title}</div>
          <small>Author: <UserNameInfo userId={ticket?.creatorId} /></small>
        </div>
        <div>
          <div>{ticket ? <TicketTypeLabel type={ticket.type} /> : <Spinner size='1em' />}</div>
          <div>{ticket ? <TicketStatusLabel status={ticket.status} /> : <Spinner size='1em' />}</div>
        </div>
      </div>
    </Fragment>
  );
}