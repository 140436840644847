import { Fragment } from 'react/jsx-runtime';
import { useCurrentAccount } from '../hooks/use-current-account';
import { AccountIcon } from './account-icon';

import styles from './account-menu.module.scss';
import { createPortal } from 'react-dom';
import { SetStateAction, useState } from 'react';
import { useAccountList } from '../hooks/use-account-list';
import { useIsCurrentAccount } from '../hooks/use-is-current-account';
import { useSetCurrentAccount } from '../hooks/use-set-current-account';
import { useStartLogin } from '../hooks/use-start-login';
import { useTranslation } from 'react-i18next';
import { Button } from '@nimey/react-ui';
import { useLogout } from '../hooks/use-logout';

const MenuPortal = (props: {open: boolean, setOpen: (v: SetStateAction<boolean>) => void}) => {
  const currentAccount = useCurrentAccount();
  const accountList = useAccountList();
  const isCurrentAcc = useIsCurrentAccount();
  const setCurrentAccount = useSetCurrentAccount();
  const startLogin = useStartLogin();
  const logout = useLogout();

  const { t } = useTranslation('auth');

  if(!currentAccount) return <></>;

  return createPortal((
    <Fragment>
      {props.open ? (
        <div className={styles.outer} onClick={() => props.setOpen(false)}>
          <div className={styles.menu}>
            <div className={styles['current-mail']}>{currentAccount.email}</div>
            <div className={styles['icon']}><AccountIcon account={currentAccount} size='3em' /></div>
            <div className={styles['logout']}><Button onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              logout()
            }}>Logout</Button></div>
            <div className={styles['account-list']}>
              <div>{t('menu.account.select')}</div>
              {accountList.filter(acc => true || !isCurrentAcc(acc)).map(acc => (
                <div onClick={(e) => {
                  e.preventDefault();
                  setCurrentAccount(acc)
                }} key={acc.id} className={styles['account-list-entry']}>
                  <AccountIcon account={acc} />
                  <div className={styles.acc}>
                    <span className={styles.name}>{acc.name}</span>
                    <span className={styles.email}>{acc.email}</span>
                  </div>
                </div>
              ))}
              <div className={styles['add-account']}>
                <button onClick={(e) => {
                  e.preventDefault();
                  startLogin({forceLogin: true});
                }}>{t('menu.account.add')}</button>
              </div>
            </div>
          </div>
        </div>
      ) : ''}
    </Fragment>
    
  ), document.getElementById('modal-portal-target')!)
}

export const AccountMenu = () => {
  const [open, setOpen] = useState<boolean>(false);
  const startLogin = useStartLogin()
  const currentAccount = useCurrentAccount();
  if(!currentAccount) return (
    <Fragment>
      <AccountIcon 
        account={{
          email: '?',
          familyName: '?',
          firstName: '?',
          name: '',
          id: '?'
        }}
        onClick={() => {
          startLogin()
        }}
      />
    </Fragment>
  );
  return (
    <Fragment>
      <AccountIcon account={currentAccount!} onClick={() => setOpen(true)} />
      <MenuPortal {...{open, setOpen}} />
    </Fragment>
  );
}