import { useUser } from '@/modules/backend/hooks/use-user';
import { Link } from 'react-router-dom';
import { UserIcon } from './user-icon';

import styles from './user.module.scss';

type UserCardProps = {
  userId: string;
}

export const UserCard = (props: UserCardProps) => {
  const { user } = useUser(props.userId);

  if(!user) return <></>

  return (
    <li className={styles.card}>
      <Link to={`/support/user/${props.userId}`}>
        <UserIcon user={user} />
        <div>
          <p><strong>{user?.email || '...'}</strong></p>
          <p><small>{user?.name || '...'}</small></p>
        </div>
      </Link>
    </li>
  )
}