import { Route, Routes as RouterRoutes, ScrollRestoration, useLocation } from 'react-router-dom';
import { BasePage } from '../../layout/components/base-page';
import { allPages } from '../pages/index';
import { useUserRoles } from '@/modules/backend/hooks/use-user-roles';
import { useEffect, useMemo } from 'react';


export const Routes = () => {
  const location = useLocation();
  const {userRoles, load} = useUserRoles();
  useEffect(() => {
    load()
  }, [])

  const filteredPages = useMemo(() => {
    return allPages.filter(page => {
      if(!page.roles || !Array.isArray(page.roles) || page.roles.length === 0) return true;
      if(page.roles.find(r => (userRoles || []).includes(r))) return true;

      return false;
    })
  }, [userRoles])

  return <></>;
}