import { Fragment } from 'react/jsx-runtime';
import { MenuItem } from './menu-item';
import { MenuDefault } from './menu-default';

export const MenuManager = () => {
  return (
    <Fragment>
      <MenuDefault />
      <MenuItem title='FOO' to='/foo' />
    </Fragment>
  );
}