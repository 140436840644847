import { usePodcastEpisode } from '@/modules/backend/hooks/use-podcast-episode';
import { PodcastEpisode } from '@nimey/podcast-global-entity';
import { PropsWithChildren, createContext, useContext, useEffect } from 'react';

export const EpisodeProviderContext = createContext<{episode: PodcastEpisode | undefined}>({episode: undefined});

export const useLocalEpisode = () => {
  return useContext(EpisodeProviderContext);
}
type EpisodeProps = PropsWithChildren<{
  episodeId?: string;
}>

export const EpisodeProvider = (props: EpisodeProps) => {
  const { episode, load } = usePodcastEpisode(props.episodeId);

  useEffect(() => { load() }, [props.episodeId])

  return (
    <EpisodeProviderContext.Provider value={{episode}}>
      {props.children}
    </EpisodeProviderContext.Provider>
  );
}