import { useMemo } from 'react';
import { Account } from '../types/account';
import styles from './account-icon.module.scss';

export interface AccountIconProps extends React.HTMLAttributes<HTMLDivElement> {
  account: Account,
  size?: string | number
}

export const AccountIcon = (props: AccountIconProps) => {
  const {
    size, account,
    ...passProps
  } = props;

  const color = useMemo(() => {
    const str = account.email;
    let hash = 0;
    str.split('').forEach(char => {
      hash = char.charCodeAt(0) + ((hash << 5) - hash)
    })

    let color = '#'
    for (let i = 0; i < 3; i++) {
      const value = (hash >> (i * 8)) & 0xff
      color += value.toString(16).padStart(2, '0')
    }
    return color
  }, [account.email])

  return (
    <div {...passProps} style={{
      backgroundColor: color,
      ...(size ? {
        fontSize: size,
      } : {})
    }} className={styles.icon}>{(props.account.firstName || '').substring(0, 1)}</div>
  )
}