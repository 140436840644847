import { Fragment, PropsWithChildren } from 'react'
import { useHasFeature } from '../hooks/use-has-feature';
import { FeatureFlag } from '@nimey/podcast-global-entity';

type FeatureGuardProps = PropsWithChildren<{
  flag: FeatureFlag;
}>;
export const FeatureGuard = (props: FeatureGuardProps) => {

  const hasFeature = useHasFeature(props.flag)

  if(!hasFeature) return <></>

  return (
    <Fragment>
      {props.children}
    </Fragment>
  )
}