;
export class BaseError extends Error {
    code;
    options;
    httpStatus = 500;
    constructor(code, message, options) {
        super(message, options);
        this.code = code;
        this.options = options;
    }
    toString() {
        return `${this.constructor.name} ${this.code} ${this.message}`;
    }
}
