import { Fragment, PropsWithChildren, useEffect } from 'react'
import { useUserRoles } from '../hooks/use-user-roles';

type RoleGuardProps = PropsWithChildren<{
  roles: string[];
}>;
export const RoleGuard = (props: RoleGuardProps) => {
  const {userRoles, load} = useUserRoles();
  useEffect(() => {
    load();
  }, [])

  if(!props.roles.find(role => (userRoles || []).includes(role))) return <></>

  return (
    <Fragment>
      {props.children}
    </Fragment>
  )
}