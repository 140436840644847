import { useCallback, useContext, useEffect } from 'react';
import { BackendDispatchContext } from '../context/backend-context';
import { usePlaylist } from './use-playlist';
import { InternalError, Playlist } from '@nimey/podcast-global-entity';

export const useAddToPlaylist = (userId: string, listId: string) => {
  const { asyncActions } = useContext(BackendDispatchContext)

  const { playlist, load } = usePlaylist(userId)
  useEffect(() => { load() }, [load])

  const addCallback = useCallback(async (episodeId: string) => {
    if(!playlist) throw new InternalError(1, 'playlist not loaded');
    const newEpisodes = new Set<string>(playlist.episodeIds);
    newEpisodes.add(episodeId);
    const newPlaylist: Playlist = {
      ...playlist,
      episodeIds: Array.from(newEpisodes),
    };
    return asyncActions?.updatePlaylist(userId, newPlaylist);
  }, [asyncActions, userId, playlist])


  return addCallback;
}