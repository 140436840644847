import { PropsWithChildren, useEffect, useRef } from 'react';
import { Outlet } from 'react-router-dom';
import { Header } from './header';
import { Footer } from './footer';
import styles from './base-page.module.scss';
import { useHistory } from '@/modules/router/hooks/use-history';

export const BasePage = (props: PropsWithChildren) => {
  const contentRef = useRef<HTMLElement>(null);
  const history = useHistory();
  const pathNameRef = useRef(history.location?.pathname);
  const searchRef = useRef(history.location?.search);
  useEffect(() => {
    const elem = contentRef.current;
    const onScroll = (e: Event) => {
      if(elem) window.sessionStorage.setItem(`scroll_restoration_${history.location?.key}`, `${elem.scrollTop || 0}`)
    }
    if(elem) {
      elem.addEventListener('scroll', onScroll)

      if(history.location?.pathname !== pathNameRef.current || history.location?.search !== searchRef.current) {
        pathNameRef.current = history.location?.pathname;
        searchRef.current = history.location?.search;
        const restoredValue = parseInt(window.sessionStorage.getItem(`scroll_restoration_${history.location?.key}`) || '0');
        elem.scrollTo({
          top: restoredValue
        });
      }
    }

    return () => {
      if(elem) elem.removeEventListener('scroll', onScroll)
    }

  }, [history])

  return (
    <div className={styles.page}>
      <section className={styles.header}><Header /></section>
      <section ref={contentRef} className={styles.content}>
        <Outlet />
      </section>
      <section className={styles.footer}><Footer /></section>
    </div>
  );
}