import { useOrganization } from '@/modules/backend/hooks/use-organization';
import { UserNameInfo } from '@/modules/global/components/user/user-name-info';
import { useEffect } from 'react';

import styles from './organization.module.scss';
import { Link } from 'react-router-dom';
import { OrganizationIcon } from './organization-icon';

export type OrganizationTitleProps = {
  orgId: string;
}

export const OrganizationTile = (props: OrganizationTitleProps) => {
  const {organization, load} = useOrganization(props.orgId);
  useEffect(() => { load() }, [load, props.orgId]);

  return (
    <Link to={organization ? `/manager/${organization.id}` : ''} className={styles.card}>
      {organization ? <OrganizationIcon org={organization} /> : <div />}
      <div>
        <p><strong>{organization?.name || 'LOADING'}</strong></p>
        <p><small>Besitzer: <UserNameInfo userId={organization?.ownerId} /></small></p>
      </div>
    </Link>
  );
}