import { MdClose, MdDone } from 'react-icons/md';
import { useLocalEpisode } from '@/modules/global/components/podcast/episode/episode-provider';
import styles from './media-player.module.scss';
import { useSetPlaybackState } from '@/modules/backend/hooks/use-set-playback-state';
import { PodcastEpisode } from '@nimey/podcast-global-entity';
import { withCurrentUserId } from '@/modules/backend/hoc/with-current-userid';
import { useTranslation } from 'react-i18next';
import { useNotify } from '@/modules/notification/hooks/use-notify';
import { NotificationChannel } from '@/modules/notification/types/notification';

const CompleteNotification = (props: {episode?: PodcastEpisode}) => {
  return (
    <p>Episode {props.episode ? `"${props.episode.title}"` : ''} als abgespielt markiert</p>
  );
}

const ResetNotification = (props: {episode?: PodcastEpisode}) => {
  const { t } = useTranslation();
  return (
    <p>Episode {props.episode ? `"${props.episode.title}"` : ''} als nicht abgespielt markiert</p>
  );
}

export const CompleteEpisodeWithEpisode = (props: {episode: PodcastEpisode}) => {
  const { episode } = useLocalEpisode();
  const notify = useNotify();
  const setPlaybackState = useSetPlaybackState(episode?.id || '')

  if(episode?.playbackState?.completed) return (
    <button onClick={() => {
      setPlaybackState({completed: false, playedSeconds: 0})
      notify(NotificationChannel.SLIM, () => <ResetNotification {...{episode}} />)
    }} className={styles.button}><MdClose /></button>
  );

  return <button onClick={() => {
    setPlaybackState({completed: true})
    notify(NotificationChannel.SLIM, () => <CompleteNotification {...{episode}} />)
  }} className={styles.button}><MdDone /></button>
}

const CompleteEpisodeWithUserId = () => {
  const { episode } = useLocalEpisode();

  if(!episode) return <></>
  
  return <CompleteEpisodeWithEpisode episode={episode} />
}

const CompleteEpisodeAnonymous = () => {
  return <></>
}


export const CompleteEpisode = withCurrentUserId(CompleteEpisodeWithUserId, CompleteEpisodeAnonymous)