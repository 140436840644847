import { useOrganization } from '@/modules/backend/hooks/use-organization';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

export const useCurrentOrg = () => {
  const { orgId } = useParams()

  const {load, organization} = useOrganization(orgId);
  useEffect(() => {
    load();
  }, [orgId, load]);

  return organization;
}