import { Link } from 'react-router-dom';

import styles from './menu.module.scss';

export const MenuItem = (props: {title: string, to: string, icon?: React.FC, light?: boolean}) => {
  const IconComponent = props.icon || (() => <span />)

  return (
    <li onClick={(e) => {e.target.dispatchEvent(new Event('menu-close', {bubbles: true}))}} className={`${styles['menu-item']} ${props.light ? styles['light'] : ''}`}><Link to={props.to}><IconComponent /> {props.title}</Link></li>
  );
}