import { NotFoundContent } from '@/translations/content';
import { Gutter } from './gutter';

export const NotFound = () => {
  return (
    <Gutter>
      <div style={{height: '1em'}} />
      <NotFoundContent />
    </Gutter>
  );
}