import { useContext } from 'react';
import { BackendDispatchContext, BackendStateContext } from '../context/backend-context';
import { AuthStateContext } from '@/modules/auth/context/auth-context';
import { TicketStats, UserRoles } from '../reducer/backend-async-actions';

export const useTicketStats = () => {
  const { asyncActions } = useContext(BackendDispatchContext)
  const state = useContext(BackendStateContext);
  const id = 'global';

  const entity = id && state.entities['ticket-stats'] ? state.entities['ticket-stats'][id] || undefined : undefined;

  const isLoaded = !!entity;
  return {
    ticketStats: (entity?.entity as TicketStats)?.stats || {},
    isLoaded: !!entity,
    load: async (force: boolean = false) => {
      if(id && (!isLoaded || force)) asyncActions!.loadTicketStats();
    }
  };
}