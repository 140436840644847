import { useCurrentAccount } from '@/modules/auth/hooks/use-current-account';
import { useUserQuota } from '@/modules/backend/hooks/use-user-quota';
import { Textarea } from '@/modules/layout/components/form/textarea';
import { QuotaDefinition, QuotaDefinitionType, QuotaName } from '@nimey/podcast-global-entity'
import { RegexValidator, TextInputWithValidation } from '@nimey/react-ui';
import { useEffect, useMemo, useState } from 'react';

export const QuotaRequestInput = () => {
  const [selected, setSelected] = useState<QuotaName>(QuotaName.CREATE_ORGANISATION);
  const account = useCurrentAccount();
  const {userQuota, load} = useUserQuota(account?.id || '', selected)
  useEffect(() => {
    load();
  }, [selected])
  const quotas = useMemo(() => {
    const result = [];
    for(const quotaName of Object.keys(QuotaDefinition)) {
      const def = QuotaDefinition[quotaName as QuotaName];
      if(!def.isAdjustable) continue;
      result.push({
        name: quotaName as QuotaName,
        defaultValue: def.defaultValue,
      })
    }

    return result;
  }, [])

  return (
    <div>
      <p>Quota Request</p>
      <select name={'quotaName'} value={selected} onChange={(e) => setSelected(e.target.value as QuotaName)}>
        {quotas.map(q => <option key={q.name} value={q.name}>{q.name}</option>)}        
      </select>

      <div>
        <p>Current: {userQuota?.value || '---'}</p>
        <p>Default: {QuotaDefinition[selected].defaultValue}</p>
      </div>

      <TextInputWithValidation placeholder='request value' name='requestValue' validator={new RegexValidator(/^[0-9]+$/).withMessage('number required')}/>

      <Textarea style={{minHeight: '2em'}} placeholder='reason' required={true} name='requestReason' />
    </div>
  )
}