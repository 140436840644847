import { usePodcastEpisodeList } from '@/modules/backend/hooks/use-podcast-episode-list';
import { useLocalPodcast } from '../podcast-provider';
import { Fragment, PropsWithChildren } from 'react';
import { Podcast, PodcastEpisodeListType } from '@nimey/podcast-global-entity';
import { LoadMore } from '@/modules/layout/components/load-more';
import { EpisodeProvider } from './episode-provider';
import { ListMeta } from '@/modules/backend/types/backend-state.js';

type PodcastEpisodeListForPodcastProps =  PropsWithChildren<{
  episodeRenderer: React.FC;
  headerRenderer: React.FC<{meta: ListMeta}>;
  className?: string;
  innerClassName?: string
}>

const WithPodcast = (props: PropsWithChildren<{podcast: Podcast, className?: string, innerClassName?: string, episodeRenderer: React.FC, headerRenderer: React.FC<{meta: ListMeta}>}>) => {
  const { allIds, loadMore, hasMore, meta } = usePodcastEpisodeList({podcastId: props.podcast.id}, PodcastEpisodeListType.RECENT);
  const EpisodeRenderer = props.episodeRenderer;
  const HeaderRenderer = props.headerRenderer;
  return (
    <Fragment>
      <HeaderRenderer {...{meta}} />
      <ul className={props.className}>
        {allIds.map(id => (
          <li className={props.innerClassName} key={id}><EpisodeProvider episodeId={id}>
            <EpisodeRenderer />
          </EpisodeProvider></li>))}
        <li><LoadMore {...{loadMore, hasMore}} /></li>
      </ul>
    </Fragment>
  );
}

export const PodcastEpisodeListForPodcast = (props: PodcastEpisodeListForPodcastProps) => {
  const { podcast } = useLocalPodcast();
  if(!podcast) return <></>;
  
  return <WithPodcast {...{podcast}} {...props} />
}