import { useEffect, useContext, useRef, Dispatch } from 'react';
import { BackendDispatchContext } from '../context/backend-context';
import { Duration } from '@nimey/units';
import { useDebounceWithTimeout } from '@/modules/global/hooks/use-debounce-with-timeout';
import { BackendAction, BackendActionKind } from '../types/backend-action';

export const useUpdatePlaybackState = (episodeId?: string, playedSeconds?: number) => {
  const lastPush = useRef<string>();
  const lastValue = useRef<number>();
  const lastEpisodeId = useRef<string>();
  const { asyncActions, dispatch } = useContext(BackendDispatchContext);
  const value = [episodeId, playedSeconds, dispatch];
  lastValue.current = playedSeconds;
  // @ts-ignore
  const debouncedValue = useDebounceWithTimeout(value, Duration.seconds(1), Duration.minutes(5)) as [string, number, Dispatch<BackendAction>];

  useEffect(() => {
    if(debouncedValue[0] && asyncActions && debouncedValue[1]) {
      const pushKey = `${debouncedValue[0]}-${debouncedValue[1]}`;
      if(pushKey !== lastPush.current) {
        asyncActions.updatePlaybackState(debouncedValue[0], { episodePlayedSeconds: Math.round(debouncedValue[1]) }).then(result => {
          debouncedValue[2]({type: BackendActionKind.UPDATE_LOCAL_PLAYBACK_STATE, payload: {episodeId: debouncedValue[0], playbackState: result.response}})
        });
        lastPush.current = pushKey;
      }
    }
  }, [debouncedValue])

  useEffect(() => {
    const localEpisodeId = lastEpisodeId.current;
    if(lastEpisodeId.current && lastValue.current && asyncActions) asyncActions.updatePlaybackState(lastEpisodeId.current, { episodePlayedSeconds: Math.round(lastValue.current) }).then(result => {
      dispatch({type: BackendActionKind.UPDATE_LOCAL_PLAYBACK_STATE, payload: {episodeId: localEpisodeId, playbackState: result.response}})
    });
    
    lastEpisodeId.current = episodeId;
  }, [episodeId])
}