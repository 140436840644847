import React from 'react';
import { AuthDispatchContext, AuthStateContext } from '../context/auth-context';
import { AuthActionKind } from '../types/auth-action';
import { Account } from '../types/account';

export const useSetCurrentAccount = () => {
  const { dispatch, asyncActions } = React.useContext(AuthDispatchContext);
  const { accounts } = React.useContext(AuthStateContext);

  return React.useCallback((input: string | {id: string} | Account) => {
    const id = typeof input === 'string' ? input : input.id;
    dispatch({type: AuthActionKind.SET_CURRENT, payload: { id }});
    asyncActions?.startLogin({forceLogin: true, email: accounts[id]?.email})
  }, [dispatch, asyncActions, accounts])
}