import { Duration } from '@nimey/units';
import { useEffect, useRef, useState } from 'react';

type ValueType = string | number | undefined | boolean;

export function useDebounce(value: ValueType, delay: Duration | number = Duration.millis(200)) {
  const timerRef = useRef<ReturnType<typeof setTimeout>>();
  const [debouncedValue, setDebouncedValue] = useState<ValueType>(value);

  useEffect(() => {
    const currentTimer = timerRef.current;

    if(currentTimer) clearTimeout(timerRef.current);

    timerRef.current = setTimeout(() => {
      setDebouncedValue(value);
    }, +delay)

    return () => {
      if(currentTimer) clearTimeout(currentTimer);
    }

  }, [value, delay])

  return debouncedValue;
}