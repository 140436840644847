import { PropsWithChildren, useMemo } from 'react';
import { AuthProvider } from './modules/auth/components/auth-provider';
import { NotificationProvider } from './modules/notification/components/notification-provider';
import {I18nextProvider} from 'react-i18next';
import i18next from 'i18next';
import { resources } from './translations/all';
import { RegisterAccountChange } from './modules/notification/components/register-account-change';
import { BackendProvider } from './modules/backend/components/backend-provider';
import { AnimatePresence } from 'framer-motion';
import { createBrowserHistory } from 'history';
import { HistoryBrowserRouter } from './modules/router/components/history-browser-router';
import { MediaPlayerProvider } from './modules/media-player/components/provider';

i18next.init({
  interpolation: { escapeValue: false },
  lng: window.localStorage.getItem('podcast/common/lang') || 'de',
  resources,
});

const LocationProvider = (props: PropsWithChildren) => <AnimatePresence>{props.children}</AnimatePresence>
const history = createBrowserHistory();


export const Providers = (props: PropsWithChildren) => {
  return (
    <I18nextProvider i18n={i18next}>
      <HistoryBrowserRouter history={history}>
        <></>
      </HistoryBrowserRouter>  
    </I18nextProvider>    
  );
}