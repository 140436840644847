import { MdPause, MdPlayArrow, MdPlayCircle, MdPlayCircleOutline } from 'react-icons/md';
import { CSSProperties, useMemo } from 'react';
import { useSetCurrentEpisodeId } from '../hooks/use-set-current-episode-id';
import { useTogglePlay } from '../hooks/use-toggle-play';
import { useCurrentEpisode } from '../hooks/use-current-episode';
import { PodcastEpisode } from '@nimey/podcast-global-entity';
import { useLocalEpisode } from '@/modules/global/components/podcast/episode/episode-provider';
import { useTranslation } from 'react-i18next';


import styles from './media-player.module.scss';

type PlayEpisodeProps = {
  episodeId: string;
}

const usePlayedPercent = (episode?: PodcastEpisode) => {
  return useMemo(() => {
    if(!episode) return 0;
    if(!episode.length) return 0;
    if(!episode.playbackState) return 0;
    if(episode.playbackState.completed) return 100;
    
    return Math.round((episode.playbackState.episodePlayedSeconds / episode.length) * 100)
  }, [episode])
}

const EpisodeLength = (props: { episode?: PodcastEpisode }) => {
  const { t } = useTranslation('player');
  const { hours, minutes, started, completed } = useMemo(() => { 
    const result = {hours: 0, minutes: 0, started: false, completed: false}
    const playbackState = props.episode?.playbackState;
    if(playbackState && playbackState.episodePlayedSeconds > 20)  {
      result.completed = !!playbackState.completed;
      result.started = true;
    }
    if(props?.episode?.length) {
      let length = props?.episode?.length || 0
      if(playbackState?.episodePlayedSeconds) length = Math.max(0, length - playbackState.episodePlayedSeconds)
      result.hours = Math.floor(length / (60 * 60));
      result.minutes = Math.round((length - (60 * 60 * result.hours)) / 60)
    }
    

    return result;
  }, [props.episode])

  if(!props.episode) return <></>;
  if(!props.episode.enclosure[0]) return <></>
  if(completed) return <span>{t('completed')}</span>
  return <span>{started ? t('remaining') : ''}{ hours ? `${hours} h` : ''} { minutes ? `${minutes} min` : ''}</span>
}

export const PlayEpisode = (props: PlayEpisodeProps) => {
  const { t } = useTranslation('player');
  const setEpisodeId = useSetCurrentEpisodeId();
  const { episode } = useLocalEpisode();
  const currentEpisode = useCurrentEpisode();
  const {toggle, play} = useTogglePlay();
  const playedPercent = usePlayedPercent(episode);

  const isPlaying = useMemo(() => {
    if (!play) return false;
    if (!currentEpisode) return false;
    if (!play) return false;
    return currentEpisode.id === props.episodeId;
  }, [currentEpisode, play, props.episodeId])

  const isCurrentEpisode = useMemo(() => {
    if (!currentEpisode) return false;
    return currentEpisode.id === props.episodeId;
  }, [currentEpisode, props.episodeId])

  const inlineStyles = {
    '--played-percent': `${playedPercent}%`,
  } as CSSProperties

  if(isPlaying) return <div className={styles['play-episode']}><button onClick={() => toggle()}><MdPause className={styles['icon']} />{t('playing')}</button></div>;

  if(isCurrentEpisode) return <div style={inlineStyles} className={styles['play-episode']}><button onClick={() => toggle()}><MdPlayCircleOutline className={styles['icon']} /><EpisodeLength episode={episode} /></button></div>;

  return <div style={inlineStyles} className={styles['play-episode']}><button onClick={() => { setEpisodeId(props.episodeId) }}><MdPlayCircleOutline className={styles['icon']} /><EpisodeLength episode={episode} /></button></div>;
}