import styles from './header.module.scss';
import { ReactComponent as Logo } from '../assets/full.min.svg';
import { Link, Route, Routes } from 'react-router-dom';
import { AccountMenu } from '../../auth/components/account-menu';
import { Menu } from './header/menu';
import { SearchInput } from './header/search';
import { useTranslation } from 'react-i18next';

export const Header = () => {
  const { t } = useTranslation('common')
  return (
    <header className={styles.header}>
      <div className={styles['menu-trigger']}><Menu /></div>
      <Routes>
        <Route path='/manager' element={<Link to='/manager' className={styles.logo}><Logo /> <span>{t('app.title')}</span></Link>} />
        <Route path='/manager/*' element={<Link to='/manager' className={styles.logo}><Logo /> <span>{t('app.title')}</span></Link>} />
        <Route path='/player' element={<Link to='/player' className={styles.logo}><Logo /> <span>{t('app.title')}</span></Link>} />
        <Route path='/player/*' element={<Link to='/player' className={styles.logo}><Logo /> <span>{t('app.title')}</span></Link>} />
        <Route path='*' element={<Link to='/' className={styles.logo}><Logo /> <span>{t('app.title', 'foo')}</span></Link>} />
      </Routes>
      <div className={styles.search}><SearchInput /></div>
      <div><AccountMenu /></div>
    </header>
  );
}