import { useContext } from 'react';
import { BackendDispatchContext, BackendStateContext } from '../context/backend-context';
import { User } from '@nimey/podcast-global-entity';

export const useUser = (id?: string) => {
  const { asyncActions } = useContext(BackendDispatchContext)
  const state = useContext(BackendStateContext);
  const entity = id && state.entities['user'] ? state.entities['user'][id] || undefined : undefined;
  const isLoaded = !!entity;
  return {
    user: entity?.entity as User | undefined,
    isLoaded: !!entity,
    load: async (force: boolean = false) => {
      if(id && (!isLoaded || force)) asyncActions!.loadUser(id);
    }
  };
}