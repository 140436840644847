import { Duration } from '@nimey/units';
import { useTranslation } from 'react-i18next';

type DateDurationProps = {
  from?: Date;
  fromTimestamp?: number;
};

const useDurationString = (duration: Duration) => {
  const { t } = useTranslation('common');
  if(duration.toSeconds() < 30) return t('now');

  const info = duration.toJSON();
  if(info.year) return `${info.year} ${info.year === 1 ? t('year') : t('years')}`
  if(info.month) return `${info.month} ${info.month === 1 ? t('month') : t('months')}`
  if(info.week) return `${info.week} ${info.week === 1 ? t('week') : t('weeks')}`
  if(info.day) return `${info.day} ${info.day === 1 ? t('day') : t('days')}`
  if(info.hour) return `${info.hour} ${info.hour === 1 ? t('hour') : t('hours')}`
  if(info.minute) return `${info.minute} ${info.minute === 1 ? t('minute') : t('minutes')}`
  if(info.second) return `${info.second} ${info.second === 1 ? t('second') : t('seconds')}`

  return duration.toString()
}


export const DateDuration = (props: DateDurationProps) => {
  const { t } = useTranslation();


  const targetTimestamp = props.fromTimestamp || props.from?.getTime() || 0;
  const now = Date.now();
  const direction = targetTimestamp < now ? 'FUTURE' : 'PAST';

  const duration = Duration.millis(direction === 'PAST' ? targetTimestamp - now : now - targetTimestamp)

  const date = props.from || new Date(props.fromTimestamp!) || new Date();

  const text = useDurationString(duration);

  if(!props.from && !props.fromTimestamp) return <></>


  return (
    <span title={date.toLocaleString()}>{direction === 'FUTURE' ? t('vor') : t('in')}{' '}{text}</span>
  );
}