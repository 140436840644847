import { useRef, RefObject, useCallback, useMemo, useState, useEffect } from 'react'

export const useIsVisible = (elementRef: RefObject<HTMLElement>, threshold: number = 0) => {
  const [ isVisible, setIsVisible ] = useState<boolean>(false);

  const handleIntersection = useCallback((entries: IntersectionObserverEntry[]) => {
    let newState = false;
    for (const entry of entries) {
      if (entry.intersectionRatio > threshold) newState = true;
      else newState = false;
    }

    setIsVisible(newState)
  }, [threshold, isVisible])

  const definedThresholds = useMemo(() => {

    let thresholds = [];
    let numSteps = 10;

    for (let i = 1.0; i <= numSteps; i++) {
      let ratio = i / numSteps;
      thresholds.push(ratio);
    }

    thresholds.push(0);
    return thresholds;
  }, [])

  const observerRef = useRef<IntersectionObserver>(new IntersectionObserver(handleIntersection, {
    root: null,
    rootMargin: '0px',
    threshold: definedThresholds
  }));

  useEffect(() => {
    const observer = observerRef.current;
    const element = elementRef.current
    if(observer && element) {
      observer.observe(element)
    }

    return () => {
      if(observer) observer.disconnect();
    }
  }, [elementRef])


  return isVisible;
}