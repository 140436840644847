import styles from './spinner.module.scss';

const Logo = (props: {size?: string }) => (
  <div style={{width: props.size || '2em'}} className={styles.wrapper}>
    <svg width="75.901mm" height="85.852mm" version="1.1" viewBox="0 0 75.901 85.852" xmlSpace="preserve" xmlns="http://www.w3.org/2000/svg">
      <g transform="translate(-63.823 -180.42)" fill="none">
        <path className={styles['path-grey']} style={{stroke: '#737373'}} transform="translate(-6.2484 39.872)" d="m81.15 156.37s-17.562 20.716 0.65848 44.789c-1.2384 10.422-10.433 25.314-4.0797 20.952 8.2213-5.6442 17.576-12.214 18.676-11.767 16.662 6.7804 31.556-3.6217 31.556-3.6217" imageRendering="auto" stroke="#737373" strokeWidth="7" />
        <g stroke="#500">
          <path className={styles['path-short-red']} style={{stroke: '#550000'}} transform="translate(-6.2484 39.872)" d="m122.24 187.03-0.22857-26.858" strokeWidth="7.3" />
          <path className={styles['path-inner-red']} style={{stroke: '#550000', paintOrder: 'normal'}} transform="translate(-6.2484 39.872)" d="m122.28 174.72s-2.4681-15.336-18.819-11.141c-15.564 3.9931-12.59 30.34 4.9144 29.744 9.6987-0.12621 14.273-10.217 14.273-10.217" strokeWidth="7" />
          <path className={styles['path-outer-red']} style={{stroke: '#550000'}} transform="translate(-6.2484 39.872)" d="m87.069 150.77s20.457-15.591 42.935 0.5991c18.131 16.036 14.632 45.736 1.3349 45.218-9.8156 0.0953-9.204-12.824-9.204-12.824" strokeWidth="7" />
        </g>
      </g>
    </svg>
  </div>

)

export const Spinner = (props: {size?: string }) => {
  return (<Logo {...props} />);
}