import { useCallback, useContext } from 'react';
import { BackendDispatchContext, BackendStateContext } from '../context/backend-context';
import { Podcast } from '@nimey/podcast-global-entity';

export const usePodcast = (id?: string) => {
  const { asyncActions } = useContext(BackendDispatchContext)
  const state = useContext(BackendStateContext);

  const loadCallback = useCallback((id: string) => asyncActions!.loadPodcast(id), [asyncActions])

  const entity = (id && state.entities.podcast) ? (state.entities.podcast[id] || undefined) : undefined;
  const isLoaded = !!entity;

  const load = useCallback(async (force: boolean = false) => (!isLoaded || force) && id && loadCallback(id), [id, loadCallback, isLoaded])

  return {
    podcast: entity?.entity as Podcast | undefined,
    isLoaded: !!entity,
    load,
  };
}