import { PropsWithChildren, useEffect, useReducer, useState } from 'react';
import { AuthStateContext,AuthDispatchContext, initialState } from '../context/auth-context';
import { authReducer } from '../reducer/auth-reducer';
import { getAsyncActions } from '../reducer/auth-async-actions';
import { useEffectOnce } from '../hooks/use-effect-once';
import { SelectAccountBanner } from './select-account-banner';

export const AuthProvider = (props: PropsWithChildren) => {
  const [ mode, setMode ] = useState<'CONTENT' | 'SELECT_ACCOUNT' | 'PENDING' | 'LOGIN'>('SELECT_ACCOUNT');
  const [state, dispatch] = useReducer(authReducer, initialState);
  const asyncActions = getAsyncActions(state, dispatch);

  useEffectOnce(() => {
    const url = new URL(window.location.href);
    const searchParams = url.searchParams;
    if(searchParams.size >= 3 && searchParams.has('session_state') && searchParams.has('iss') && searchParams.has('code')) {
      asyncActions.processLoginCode(searchParams.get('code')!, searchParams.get('iss')!, searchParams.get('session_state')!).then(() => {
        const newUrl = new URL(window.location.href);
        const searchParams = newUrl.searchParams;
        searchParams.delete('session_state');
        searchParams.delete('iss');
        searchParams.delete('code');
        newUrl.search = searchParams.toString();
        window.history.replaceState({}, '', newUrl);
      })
    }
  }, [asyncActions])

  useEffect(() => {
    let newMode: 'CONTENT' | 'SELECT_ACCOUNT' | 'PENDING' = 'CONTENT';
    if(!state.currentAccountId) {
      newMode = 'SELECT_ACCOUNT';
    }

    const url = new URL(window.location.href);
    const searchParams = url.searchParams;
    if(searchParams.size >= 3 && searchParams.has('session_state') && searchParams.has('iss') && searchParams.has('code')) {
      newMode = 'PENDING'
    }


    if(newMode !== mode) setMode(newMode);

  }, [state, mode, asyncActions])

  return (
    <AuthStateContext.Provider value={state}>
      <AuthDispatchContext.Provider value={{dispatch, asyncActions}}>
        {mode === 'CONTENT' || mode === 'SELECT_ACCOUNT' ? (props.children) : ''}
        {mode === 'PENDING' ? (<div>PENDING</div>) : ''}
      </AuthDispatchContext.Provider>
    </AuthStateContext.Provider>
  );
}
