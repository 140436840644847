import { PropsWithChildren, useState } from 'react';
import { NotificationRenderer } from './notification-renderer';
import { NotificationContext } from '../context/notification-context';
import { Notification, NotificationChannel } from '../types/notification';

export const NotificationProvider = (props: PropsWithChildren) => {
  const [notifications, setNotifications] = useState<Notification[]>([]);

  const addNotification = (notif: Notification) => {
    if(notif.channel === NotificationChannel.SLIM) {
      setNotifications(n => [notif, ...n]);
      setTimeout(() => {
        setNotifications(n => n.filter(m => m.id !== notif.id));
      }, 10000)
    } else {
      setNotifications(n => [notif, ...n]);
    }
  }

  return (
    <NotificationContext.Provider value={{notifications, setNotifications, addNotification}}>
      {props.children}
      <NotificationRenderer />
    </NotificationContext.Provider>
  )
}