import { ManagerFooterHome } from '@/modules/manager/components/footer/home';
import { ManagerFooterOrg } from '@/modules/manager/components/footer/org';
import { PlayerFooter } from '@/modules/player/components/footer';
import { SupportFooter } from '@/modules/support/components/footer/home';
import { Route, Routes, matchRoutes, useLocation } from 'react-router-dom';
import styles from './footer.module.scss';
import { PlayerController } from '@/modules/media-player/components/player-controller';
import { useMemo } from 'react';

const routes = [
  {path: '/manager/:orgId/*', classes: ['manager', 'manager-org']},
  {path: '/manager/:orgId', classNames: ['manager', 'manager-org']},
  {path: '/manager', classNames: ['manager']},
  {path: '/support/*', classNames: ['supprt']},
  {path: '/support', classNames: ['supprt']},
  {path: '/support/*', classNames: ['supprt']},
  {path: '/support', classNames: ['supprt']},
  {path: '/*', classNames: ['root']},
  {path: '/', classNames: ['root']},
];

export const Footer = () => {
  const location = useLocation()

  const routeBasedClasses = useMemo(() => {
    const route = matchRoutes(routes, location)

    if(!Array.isArray(route)) return [];

    return route[0].route.classNames?.map((c: string) => styles[c]).filter((c: string | undefined) => !!c);
  }, [location, routes])

  return (
    <footer className={`${styles.footer} ${routeBasedClasses?.join(' ')}`}>
      <div className={styles.inner}>
        <div className={styles['context-footer']}>
          <Routes>
            <Route path={`/manager/:orgId/*`} element={<ManagerFooterOrg />} />
            <Route path={`/manager/:orgId`} element={<ManagerFooterOrg />} />
            <Route path={`/manager`} element={<ManagerFooterHome />} />
            <Route path={`/support/*`} element={<SupportFooter />} />
            <Route path={`/support`} element={<SupportFooter />} />
            <Route path={`/*`} element={<PlayerFooter />} />
            <Route path={`/`} element={<PlayerFooter />} />
          </Routes>
        </div>
        <div className={styles['player-footer']}>
          <PlayerController />
        </div>
      </div>
    </footer>
  );
}