import { InternalError } from '@nimey/podcast-global-entity';

export class Token {
  public readonly exp: number;
  public readonly iat: number;
  public readonly authTime: Date;

  public readonly jti: string;
  public readonly iss: string;
  public readonly aud: string;
  public readonly sub: string;
  public readonly typ: string;
  public readonly azp: string;
  public readonly sessionState: string;
  public readonly acr: string;
  public readonly scope: string;
  public readonly sid: string;

  public readonly name: string;
  public readonly preferredUsername: string;
  public readonly givenName: string;
  public readonly familyName: string;
  public readonly email: string;

  public readonly emailVerified: boolean;
  

  constructor(protected rawToken: string) {
    if(!rawToken) {
      throw new InternalError(1, 'INVALID_TOKEN');
    }
    const token = JSON.parse(atob(rawToken.split('.')[1]));
    this.exp = token.exp as number;
    this.iat = token.iat as number;
    this.authTime = new Date(token.auth_time * 1000);
    this.jti = token.jti as string;
    this.iss = token.iss as string;
    this.aud = token.aud as string;
    this.sub = token.sub as string;
    this.typ = token.typ as string;
    this.azp = token.azp as string;
    this.sessionState = token.session_state as string;
    this.acr = token.acr as string;
    this.scope = token.scope as string;
    this.sid = token.sid as string;

    this.name = token.name as string;
    this.preferredUsername = token.preferred_username as string;
    this.givenName = token.given_name as string;
    this.familyName = token.family_name as string;
    this.email = token.email as string;

    this.emailVerified = token.email_verified as boolean;
  }

  toString() {
    return this.rawToken;
  }

}
