import { withCurrentUserId } from '@/modules/backend/hoc/with-current-userid';
import { Gutter } from '@/modules/layout/components/gutter';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { MdDragHandle, MdStopCircle } from 'react-icons/md';
import styles from './playlist.module.scss';
import { useTranslation } from 'react-i18next';
import { useCurrentPlaylist } from '@/modules/backend/hooks/use-current-playlist';
import { EpisodeProvider, useLocalEpisode } from '@/modules/global/components/podcast/episode/episode-provider';
import { EpisodeTitle } from '@/modules/global/components/podcast/episode/title';
import { Hr } from '@/modules/layout/components/hr';
import { Link } from 'react-router-dom';
import { EpisodePubDate } from '@/modules/global/components/podcast/episode/pub-date';
import { ArtworkWrapper } from '@/modules/global/components/podcast/artwork';
import { EpisodeArtwork } from '@/modules/global/components/podcast/episode/artwork';
import { ImageSize, Playlist as PlaylistEntity } from '@nimey/podcast-global-entity';
import { SlideOutAction } from '@/modules/layout/components/slide-out-action';
import { useRemoveFromPlaylist } from '@/modules/backend/hooks/use-remove-from-playlist';
import { Active, DndContext, MouseSensor, TouchSensor, useSensor, useSensors } from '@dnd-kit/core';
import {SortableContext, arrayMove, useSortable, verticalListSortingStrategy} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { useUpdatePlaylist } from '@/modules/backend/hooks/use-update-playlist';
import { EpisodeActions } from './epispode-actions';
import { PodcastProvider } from '@/modules/global/components/podcast/podcast-provider';
import { PodcastTitle } from '@/modules/global/components/podcast/title';

const useIsTouch = () => {
  return useMemo(() => {
    return (('ontouchstart' in window) ||
      (navigator.maxTouchPoints > 0) ||
      ('msMaxTouchPoints' in navigator && (navigator as {msMaxTouchPoints: number}).msMaxTouchPoints > 0));
  }, []);
}

const PlalistStopper = (props: {userId: string, playlist: PlaylistEntity}) => {
  const removeFromPlaylist = useRemoveFromPlaylist(props.userId, props.playlist.id);

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({
    id: `pause`,
  })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <Fragment>
      <Hr />
      <li
        ref={setNodeRef} style={style}
        className={styles['pause-after-entry']}
      >
        <div className={styles.inner} onClick={() => removeFromPlaylist('pause')}>
          <p className={styles.title}>Playlist Stopper</p>
          <p className={styles.description}>Die automatische Wiedergabe wird an dieser Stelle pausiert. Klicke auf diese Zeile, um den Stopper ans Ende zu verschieben.</p>
        </div>
        <div className={styles['drag-handle']}><button {...attributes} {...listeners}><MdDragHandle /></button></div>
    </li>
    </Fragment>
  );
}

const PlaylistItem = (props: {episodeId: string, userId: string, playlist: PlaylistEntity}) => {
  const { episodeId, userId, playlist } = props;
  const removeFromPlaylist = useRemoveFromPlaylist(userId, playlist?.id || '');
  const isTouch = useIsTouch();

  const { episode } = useLocalEpisode();

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({
    id: props.episodeId,
  })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <Fragment>
      <Hr />
      <li
        ref={setNodeRef} style={style}
        className={styles['episode-list-entry']}
      >
        <SlideOutAction
          right={isTouch}
          onRight={() => {
            removeFromPlaylist(episodeId)
          }}
        >
          <div style={{display: 'flex', alignItems: 'center'}}>
            <div style={{width: '100%'}}>
                <Link to={`/player/pod/${episode?.podcastId}/${episodeId}`}>
                  <ArtworkWrapper width={60}><EpisodeArtwork size={ImageSize.SMALL} /></ArtworkWrapper>
                  <div className={styles['episode-info']}>
                    <p className={styles['episode-pub-date']}><EpisodePubDate /></p>
                    {episode?.podcastId ? (
                      <p className={styles['podcast-title']}><PodcastProvider podcastId={episode.podcastId}><PodcastTitle /></PodcastProvider></p>
                    ) : ''}
                    <p className={styles['episode-title']}><EpisodeTitle maxLines={1} /></p>
                  </div>
                </Link>
                <div className={styles['episode-action-bar']}>
                  <EpisodeActions />
                </div>
            </div>
            <div className={styles['drag-handle']}><button {...attributes} {...listeners}><MdDragHandle /></button></div>
          </div>
        </SlideOutAction>
      </li>
    </Fragment>
  );
}

const PlaylistWithUserId = (props: { userId: string }) => {
  const { playlist } = useCurrentPlaylist();
  const { t } = useTranslation('player');

  const updatePlaylist = useUpdatePlaylist(props.userId);

  const [episodeIds, setEpisodeIds] = useState<string[]>([]);
  useEffect(() => {
    let newEpisodeIds: string[] = [];
    const allEpisodes = (playlist?.episodeIds || [])
    let counter = 0;
    for(const episodeId of allEpisodes) {
      if(episodeId === 'pause' && newEpisodeIds.includes('pause')) continue;
      newEpisodeIds.push(episodeId);
    }

    if(!newEpisodeIds.includes('pause')) newEpisodeIds.push('pause');
    setEpisodeIds(newEpisodeIds);
  }, [playlist, setEpisodeIds])

  const [active, setActive] = useState<Active | null>(null);
  const activeItem = useMemo(
    () => episodeIds.find((item) => item === active?.id),
    [active, episodeIds]
  );

  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: { distance: 10 }
  })

  const touchSensor = useSensor(TouchSensor, {
    activationConstraint: { 
      delay: 20,
      tolerance: 8
    }
  })

  const sensors = useSensors(mouseSensor, touchSensor)

  return (
    <Gutter>
      <h1>Deine Playlist</h1>
      {playlist ? (
        <DndContext
          sensors={sensors}
          modifiers={[
            restrictToVerticalAxis
          ]}
          onDragStart={({ active }) => {
            setActive(active);
          }}
          onDragCancel={() => {
            setActive(null);
          }}
          onDragEnd={({ active, over }) => {
            if (over && active.id !== over?.id) {
              const activeIndex = episodeIds.findIndex((id) => id === active.id);
              const overIndex = episodeIds.findIndex((id) => id === over.id);
              const newEpisodes = arrayMove(episodeIds, activeIndex, overIndex);
              setEpisodeIds(newEpisodes)
              const newPlaylist = {
                ...playlist,
                episodeIds: newEpisodes
              }

              updatePlaylist(newPlaylist);
            }
            setActive(null);
          }}
        >
          <SortableContext items={episodeIds} strategy={verticalListSortingStrategy}>
            <ul className={styles['episode-list']}>
              {playlist.episodeIds.length === 0 ? <li>{t('playlist.no-episodes')}</li> : ''}
              {episodeIds.map((episodeId, idx) => (
                <Fragment key={episodeId}>
                  {episodeId === 'pause' ? (
                    <PlalistStopper userId={props.userId} playlist={playlist} />
                  ) : (
                    <EpisodeProvider key={episodeId} episodeId={episodeId}>
                      <PlaylistItem {...{episodeId, playlist, userId: props.userId}} />
                    </EpisodeProvider>
                  )}
                </Fragment>
              ))}
            </ul>
          </SortableContext>
        </DndContext>
      ) : ''}
    </Gutter>
  );
}

const LoginRequired = () => (
  <p>Du musst angemeldet sein, um die Playlist zu verwenden</p>
)

export const Playlist = withCurrentUserId(PlaylistWithUserId, LoginRequired);