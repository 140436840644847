import { useValueByPath } from '..';
import { QuestionProps } from '../types'


export const QuestionSelection = (props: QuestionProps) => {
  const [ value, setValue ] = useValueByPath(props.path);
  if(!props.question.options) return <></>;

  const options = props.question.options;

  return (
    <div>
      <div><strong>{props.question.label}</strong></div>
      {Object.keys(options).map((v, idx) => (
        <div key={idx}><label><input type='radio' checked={value === v} onChange={(e) => {
          setValue(v)
        }} name={props.path} />{options[v]}</label></div>
      ))}
    </div>
  );
}
