import { useCreateOrganization } from '@/modules/backend/hooks/use-create-organization';
import { FlexRow } from '@/modules/layout/components/grid/flex-row';
import { Modal } from '@/modules/layout/components/modal';
import { AllValidator, Button, RegexValidator, TextInputWithValidation, Validator, useIsValid } from '@nimey/react-ui';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdAdd, MdArrowForward, MdCircle } from 'react-icons/md';
import { useLocation, useNavigate } from 'react-router-dom';
import { Fragment } from 'react/jsx-runtime';
const Form = () => {
  const [ name, setName ] = useState<string>('');
  const [ showError, setShowError ] = useState<boolean>(false)

  const { t } = useTranslation('manager')
  const navigate = useNavigate();
  const orgNameValidator = useMemo(() => {
    return new AllValidator().withChildren(
      new RegexValidator(/^[a-z]/i).withMessage(t('modal.create-org.validation.alpha-start')),
      new RegexValidator(/^[a-z0-9äöüß ]*$/i).withMessage(t('modal.create-org.validation.alphanumeric')),
      new RegexValidator(/^[a-z0-9äöüß ]{3,}$/i).withMessage(t('modal.create-org.validation.min-length')),
    )
  }, [t])

  const {loading, lastError, create} = useCreateOrganization();
  useEffect(() => {
    setShowError(false);
  }, [name])

  const isValid = useIsValid('name');
  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if(!isValid) return;
    create({name}).then((org) => {
      if(org) {
        setName('');
        navigate(`/manager/${org.id}`)
      }
      else setShowError(true);
    })
  }
  return (
    <form onSubmit={onSubmit}>
      <div><TextInputWithValidation value={name} onChange={v => setName(v as string)} name='name' placeholder='Name' validator={orgNameValidator} /></div>
      <FlexRow style={{margin: '1em 0'}}>
        <div style={{width: '100%'}}>{showError ? lastError?.toString() || '' : ''}</div>
        <Button primary type='sumbit' disabled={loading}>
          <span style={{whiteSpace: 'nowrap', display: 'flex', gap: 5, alignItems: 'center'}}>
            { loading ? <MdCircle /> : <MdArrowForward /> } {' '}
            {t('modal.create-org.create-button')}
          </span>
        </Button>
      </FlexRow>
    </form>
  );
}
export const CreateOrganizationModal = () => {
  const { t } = useTranslation('manager')
  const location = useLocation()
  const navigate = useNavigate()
  const showModal = location.hash === '#create-org'
  const setShowModal = (v: boolean) => {
    if(showModal === v) return;

    if(v) navigate('#create-org');
    else navigate('#');
  }

  return (
    <Fragment>
      <Button primary onClick={() => setShowModal(true)}><MdAdd /></Button>
      <Modal
        title={t('modal.create-org.title')}
        isOpen={showModal}
        close={() => setShowModal(false)}
      >
        <Validator name='create-org'>
          <Form />
        </Validator>
      </Modal>
    </Fragment>
  );
}
