export var PodcastLanguage;
(function (PodcastLanguage) {
    PodcastLanguage["mr"] = "mr";
    PodcastLanguage["bs"] = "bs";
    PodcastLanguage["ee_TG"] = "ee_TG";
    PodcastLanguage["ms"] = "ms";
    PodcastLanguage["kam_KE"] = "kam_KE";
    PodcastLanguage["mt"] = "mt";
    PodcastLanguage["ha"] = "ha";
    PodcastLanguage["es_HN"] = "es_HN";
    PodcastLanguage["ml_IN"] = "ml_IN";
    PodcastLanguage["ro_MD"] = "ro_MD";
    PodcastLanguage["kab_DZ"] = "kab_DZ";
    PodcastLanguage["he"] = "he";
    PodcastLanguage["es_CO"] = "es_CO";
    PodcastLanguage["my"] = "my";
    PodcastLanguage["es_PA"] = "es_PA";
    PodcastLanguage["az_Latn"] = "az_Latn";
    PodcastLanguage["mer"] = "mer";
    PodcastLanguage["en_NZ"] = "en_NZ";
    PodcastLanguage["xog_UG"] = "xog_UG";
    PodcastLanguage["sg"] = "sg";
    PodcastLanguage["fr_GP"] = "fr_GP";
    PodcastLanguage["sr_Cyrl_BA"] = "sr_Cyrl_BA";
    PodcastLanguage["hi"] = "hi";
    PodcastLanguage["fil_PH"] = "fil_PH";
    PodcastLanguage["lt_LT"] = "lt_LT";
    PodcastLanguage["si"] = "si";
    PodcastLanguage["en_MT"] = "en_MT";
    PodcastLanguage["si_LK"] = "si_LK";
    PodcastLanguage["luo_KE"] = "luo_KE";
    PodcastLanguage["it_CH"] = "it_CH";
    PodcastLanguage["teo"] = "teo";
    PodcastLanguage["mfe"] = "mfe";
    PodcastLanguage["sk"] = "sk";
    PodcastLanguage["uz_Cyrl_UZ"] = "uz_Cyrl_UZ";
    PodcastLanguage["sl"] = "sl";
    PodcastLanguage["rm_CH"] = "rm_CH";
    PodcastLanguage["az_Cyrl_AZ"] = "az_Cyrl_AZ";
    PodcastLanguage["fr_GQ"] = "fr_GQ";
    PodcastLanguage["kde"] = "kde";
    PodcastLanguage["sn"] = "sn";
    PodcastLanguage["cgg_UG"] = "cgg_UG";
    PodcastLanguage["so"] = "so";
    PodcastLanguage["fr_RW"] = "fr_RW";
    PodcastLanguage["es_SV"] = "es_SV";
    PodcastLanguage["mas_TZ"] = "mas_TZ";
    PodcastLanguage["en_MU"] = "en_MU";
    PodcastLanguage["sq"] = "sq";
    PodcastLanguage["hr"] = "hr";
    PodcastLanguage["sr"] = "sr";
    PodcastLanguage["en_PH"] = "en_PH";
    PodcastLanguage["ca"] = "ca";
    PodcastLanguage["hu"] = "hu";
    PodcastLanguage["mk_MK"] = "mk_MK";
    PodcastLanguage["fr_TD"] = "fr_TD";
    PodcastLanguage["nb"] = "nb";
    PodcastLanguage["sv"] = "sv";
    PodcastLanguage["kln_KE"] = "kln_KE";
    PodcastLanguage["sw"] = "sw";
    PodcastLanguage["nd"] = "nd";
    PodcastLanguage["sr_Latn"] = "sr_Latn";
    PodcastLanguage["el_GR"] = "el_GR";
    PodcastLanguage["hy"] = "hy";
    PodcastLanguage["ne"] = "ne";
    PodcastLanguage["el_CY"] = "el_CY";
    PodcastLanguage["es_CR"] = "es_CR";
    PodcastLanguage["fo_FO"] = "fo_FO";
    PodcastLanguage["pa_Arab_PK"] = "pa_Arab_PK";
    PodcastLanguage["seh"] = "seh";
    PodcastLanguage["ar_YE"] = "ar_YE";
    PodcastLanguage["ja_JP"] = "ja_JP";
    PodcastLanguage["ur_PK"] = "ur_PK";
    PodcastLanguage["pa_Guru"] = "pa_Guru";
    PodcastLanguage["gl_ES"] = "gl_ES";
    PodcastLanguage["zh_Hant_HK"] = "zh_Hant_HK";
    PodcastLanguage["ar_EG"] = "ar_EG";
    PodcastLanguage["nl"] = "nl";
    PodcastLanguage["th_TH"] = "th_TH";
    PodcastLanguage["es_PE"] = "es_PE";
    PodcastLanguage["fr_KM"] = "fr_KM";
    PodcastLanguage["nn"] = "nn";
    PodcastLanguage["kk_Cyrl_KZ"] = "kk_Cyrl_KZ";
    PodcastLanguage["kea"] = "kea";
    PodcastLanguage["lv_LV"] = "lv_LV";
    PodcastLanguage["kln"] = "kln";
    PodcastLanguage["tzm_Latn"] = "tzm_Latn";
    PodcastLanguage["yo"] = "yo";
    PodcastLanguage["gsw_CH"] = "gsw_CH";
    PodcastLanguage["ha_Latn_GH"] = "ha_Latn_GH";
    PodcastLanguage["is_IS"] = "is_IS";
    PodcastLanguage["pt_BR"] = "pt_BR";
    PodcastLanguage["cs"] = "cs";
    PodcastLanguage["en_PK"] = "en_PK";
    PodcastLanguage["fa_IR"] = "fa_IR";
    PodcastLanguage["zh_Hans_SG"] = "zh_Hans_SG";
    PodcastLanguage["luo"] = "luo";
    PodcastLanguage["ta"] = "ta";
    PodcastLanguage["fr_TG"] = "fr_TG";
    PodcastLanguage["kde_TZ"] = "kde_TZ";
    PodcastLanguage["mr_IN"] = "mr_IN";
    PodcastLanguage["ar_SA"] = "ar_SA";
    PodcastLanguage["ka_GE"] = "ka_GE";
    PodcastLanguage["mfe_MU"] = "mfe_MU";
    PodcastLanguage["id"] = "id";
    PodcastLanguage["fr_LU"] = "fr_LU";
    PodcastLanguage["de_LU"] = "de_LU";
    PodcastLanguage["ru_MD"] = "ru_MD";
    PodcastLanguage["cy"] = "cy";
    PodcastLanguage["zh_Hans_HK"] = "zh_Hans_HK";
    PodcastLanguage["te"] = "te";
    PodcastLanguage["bg_BG"] = "bg_BG";
    PodcastLanguage["shi_Latn"] = "shi_Latn";
    PodcastLanguage["ig"] = "ig";
    PodcastLanguage["ses"] = "ses";
    PodcastLanguage["ii"] = "ii";
    PodcastLanguage["es_BO"] = "es_BO";
    PodcastLanguage["th"] = "th";
    PodcastLanguage["ko_KR"] = "ko_KR";
    PodcastLanguage["ti"] = "ti";
    PodcastLanguage["it_IT"] = "it_IT";
    PodcastLanguage["shi_Latn_MA"] = "shi_Latn_MA";
    PodcastLanguage["pt_MZ"] = "pt_MZ";
    PodcastLanguage["ff_SN"] = "ff_SN";
    PodcastLanguage["haw"] = "haw";
    PodcastLanguage["zh_Hans"] = "zh_Hans";
    PodcastLanguage["so_KE"] = "so_KE";
    PodcastLanguage["bn_IN"] = "bn_IN";
    PodcastLanguage["en_UM"] = "en_UM";
    PodcastLanguage["to"] = "to";
    PodcastLanguage["id_ID"] = "id_ID";
    PodcastLanguage["uz_Cyrl"] = "uz_Cyrl";
    PodcastLanguage["en_GU"] = "en_GU";
    PodcastLanguage["es_EC"] = "es_EC";
    PodcastLanguage["en_US_POSIX"] = "en_US_POSIX";
    PodcastLanguage["sr_Latn_BA"] = "sr_Latn_BA";
    PodcastLanguage["is"] = "is";
    PodcastLanguage["luy"] = "luy";
    PodcastLanguage["tr"] = "tr";
    PodcastLanguage["en_NA"] = "en_NA";
    PodcastLanguage["it"] = "it";
    PodcastLanguage["da"] = "da";
    PodcastLanguage["bo_IN"] = "bo_IN";
    PodcastLanguage["vun_TZ"] = "vun_TZ";
    PodcastLanguage["ar_SD"] = "ar_SD";
    PodcastLanguage["uz_Latn_UZ"] = "uz_Latn_UZ";
    PodcastLanguage["az_Latn_AZ"] = "az_Latn_AZ";
    PodcastLanguage["de"] = "de";
    PodcastLanguage["es_GQ"] = "es_GQ";
    PodcastLanguage["ta_IN"] = "ta_IN";
    PodcastLanguage["de_DE"] = "de_DE";
    PodcastLanguage["fr_FR"] = "fr_FR";
    PodcastLanguage["rof_TZ"] = "rof_TZ";
    PodcastLanguage["ar_LY"] = "ar_LY";
    PodcastLanguage["en_BW"] = "en_BW";
    PodcastLanguage["asa"] = "asa";
    PodcastLanguage["zh"] = "zh";
    PodcastLanguage["ha_Latn"] = "ha_Latn";
    PodcastLanguage["fr_NE"] = "fr_NE";
    PodcastLanguage["es_MX"] = "es_MX";
    PodcastLanguage["bem_ZM"] = "bem_ZM";
    PodcastLanguage["zh_Hans_CN"] = "zh_Hans_CN";
    PodcastLanguage["bn_BD"] = "bn_BD";
    PodcastLanguage["pt_GW"] = "pt_GW";
    PodcastLanguage["om"] = "om";
    PodcastLanguage["jmc"] = "jmc";
    PodcastLanguage["de_AT"] = "de_AT";
    PodcastLanguage["kk_Cyrl"] = "kk_Cyrl";
    PodcastLanguage["sw_TZ"] = "sw_TZ";
    PodcastLanguage["ar_OM"] = "ar_OM";
    PodcastLanguage["et_EE"] = "et_EE";
    PodcastLanguage["or"] = "or";
    PodcastLanguage["da_DK"] = "da_DK";
    PodcastLanguage["ro_RO"] = "ro_RO";
    PodcastLanguage["zh_Hant"] = "zh_Hant";
    PodcastLanguage["bm_ML"] = "bm_ML";
    PodcastLanguage["ja"] = "ja";
    PodcastLanguage["fr_CA"] = "fr_CA";
    PodcastLanguage["naq"] = "naq";
    PodcastLanguage["zu"] = "zu";
    PodcastLanguage["en_IE"] = "en_IE";
    PodcastLanguage["ar_MA"] = "ar_MA";
    PodcastLanguage["es_GT"] = "es_GT";
    PodcastLanguage["uz_Arab_AF"] = "uz_Arab_AF";
    PodcastLanguage["en_AS"] = "en_AS";
    PodcastLanguage["bs_BA"] = "bs_BA";
    PodcastLanguage["am_ET"] = "am_ET";
    PodcastLanguage["ar_TN"] = "ar_TN";
    PodcastLanguage["haw_US"] = "haw_US";
    PodcastLanguage["ar_JO"] = "ar_JO";
    PodcastLanguage["fa_AF"] = "fa_AF";
    PodcastLanguage["uz_Latn"] = "uz_Latn";
    PodcastLanguage["en_BZ"] = "en_BZ";
    PodcastLanguage["nyn_UG"] = "nyn_UG";
    PodcastLanguage["ebu_KE"] = "ebu_KE";
    PodcastLanguage["te_IN"] = "te_IN";
    PodcastLanguage["cy_GB"] = "cy_GB";
    PodcastLanguage["uk"] = "uk";
    PodcastLanguage["nyn"] = "nyn";
    PodcastLanguage["en_JM"] = "en_JM";
    PodcastLanguage["en_US"] = "en_US";
    PodcastLanguage["fil"] = "fil";
    PodcastLanguage["ar_KW"] = "ar_KW";
    PodcastLanguage["af_ZA"] = "af_ZA";
    PodcastLanguage["en_CA"] = "en_CA";
    PodcastLanguage["fr_DJ"] = "fr_DJ";
    PodcastLanguage["ti_ER"] = "ti_ER";
    PodcastLanguage["ig_NG"] = "ig_NG";
    PodcastLanguage["en_AU"] = "en_AU";
    PodcastLanguage["ur"] = "ur";
    PodcastLanguage["fr_MC"] = "fr_MC";
    PodcastLanguage["pt_PT"] = "pt_PT";
    PodcastLanguage["pa"] = "pa";
    PodcastLanguage["es_419"] = "es_419";
    PodcastLanguage["fr_CD"] = "fr_CD";
    PodcastLanguage["en_SG"] = "en_SG";
    PodcastLanguage["bo_CN"] = "bo_CN";
    PodcastLanguage["kn_IN"] = "kn_IN";
    PodcastLanguage["sr_Cyrl_RS"] = "sr_Cyrl_RS";
    PodcastLanguage["lg_UG"] = "lg_UG";
    PodcastLanguage["gu_IN"] = "gu_IN";
    PodcastLanguage["ee"] = "ee";
    PodcastLanguage["nd_ZW"] = "nd_ZW";
    PodcastLanguage["bem"] = "bem";
    PodcastLanguage["uz"] = "uz";
    PodcastLanguage["sw_KE"] = "sw_KE";
    PodcastLanguage["sq_AL"] = "sq_AL";
    PodcastLanguage["hr_HR"] = "hr_HR";
    PodcastLanguage["mas_KE"] = "mas_KE";
    PodcastLanguage["el"] = "el";
    PodcastLanguage["ti_ET"] = "ti_ET";
    PodcastLanguage["es_AR"] = "es_AR";
    PodcastLanguage["pl"] = "pl";
    PodcastLanguage["en"] = "en";
    PodcastLanguage["eo"] = "eo";
    PodcastLanguage["shi"] = "shi";
    PodcastLanguage["kok"] = "kok";
    PodcastLanguage["fr_CF"] = "fr_CF";
    PodcastLanguage["fr_RE"] = "fr_RE";
    PodcastLanguage["mas"] = "mas";
    PodcastLanguage["rof"] = "rof";
    PodcastLanguage["ru_UA"] = "ru_UA";
    PodcastLanguage["yo_NG"] = "yo_NG";
    PodcastLanguage["dav_KE"] = "dav_KE";
    PodcastLanguage["gv_GB"] = "gv_GB";
    PodcastLanguage["pa_Arab"] = "pa_Arab";
    PodcastLanguage["es"] = "es";
    PodcastLanguage["teo_UG"] = "teo_UG";
    PodcastLanguage["ps"] = "ps";
    PodcastLanguage["es_PR"] = "es_PR";
    PodcastLanguage["fr_MF"] = "fr_MF";
    PodcastLanguage["et"] = "et";
    PodcastLanguage["pt"] = "pt";
    PodcastLanguage["eu"] = "eu";
    PodcastLanguage["ka"] = "ka";
    PodcastLanguage["rwk_TZ"] = "rwk_TZ";
    PodcastLanguage["nb_NO"] = "nb_NO";
    PodcastLanguage["fr_CG"] = "fr_CG";
    PodcastLanguage["cgg"] = "cgg";
    PodcastLanguage["zh_Hant_TW"] = "zh_Hant_TW";
    PodcastLanguage["sr_Cyrl_ME"] = "sr_Cyrl_ME";
    PodcastLanguage["lag"] = "lag";
    PodcastLanguage["ses_ML"] = "ses_ML";
    PodcastLanguage["en_ZW"] = "en_ZW";
    PodcastLanguage["ak_GH"] = "ak_GH";
    PodcastLanguage["vi_VN"] = "vi_VN";
    PodcastLanguage["sv_FI"] = "sv_FI";
    PodcastLanguage["to_TO"] = "to_TO";
    PodcastLanguage["fr_MG"] = "fr_MG";
    PodcastLanguage["fr_GA"] = "fr_GA";
    PodcastLanguage["fr_CH"] = "fr_CH";
    PodcastLanguage["de_CH"] = "de_CH";
    PodcastLanguage["es_US"] = "es_US";
    PodcastLanguage["ki"] = "ki";
    PodcastLanguage["my_MM"] = "my_MM";
    PodcastLanguage["vi"] = "vi";
    PodcastLanguage["ar_QA"] = "ar_QA";
    PodcastLanguage["ga_IE"] = "ga_IE";
    PodcastLanguage["rwk"] = "rwk";
    PodcastLanguage["bez"] = "bez";
    PodcastLanguage["ee_GH"] = "ee_GH";
    PodcastLanguage["kk"] = "kk";
    PodcastLanguage["as_IN"] = "as_IN";
    PodcastLanguage["ca_ES"] = "ca_ES";
    PodcastLanguage["kl"] = "kl";
    PodcastLanguage["fr_SN"] = "fr_SN";
    PodcastLanguage["ne_IN"] = "ne_IN";
    PodcastLanguage["km"] = "km";
    PodcastLanguage["ms_BN"] = "ms_BN";
    PodcastLanguage["ar_LB"] = "ar_LB";
    PodcastLanguage["ta_LK"] = "ta_LK";
    PodcastLanguage["kn"] = "kn";
    PodcastLanguage["ur_IN"] = "ur_IN";
    PodcastLanguage["fr_CI"] = "fr_CI";
    PodcastLanguage["ko"] = "ko";
    PodcastLanguage["ha_Latn_NG"] = "ha_Latn_NG";
    PodcastLanguage["sg_CF"] = "sg_CF";
    PodcastLanguage["om_ET"] = "om_ET";
    PodcastLanguage["zh_Hant_MO"] = "zh_Hant_MO";
    PodcastLanguage["uk_UA"] = "uk_UA";
    PodcastLanguage["fa"] = "fa";
    PodcastLanguage["mt_MT"] = "mt_MT";
    PodcastLanguage["ki_KE"] = "ki_KE";
    PodcastLanguage["luy_KE"] = "luy_KE";
    PodcastLanguage["kw"] = "kw";
    PodcastLanguage["pa_Guru_IN"] = "pa_Guru_IN";
    PodcastLanguage["en_IN"] = "en_IN";
    PodcastLanguage["kab"] = "kab";
    PodcastLanguage["ar_IQ"] = "ar_IQ";
    PodcastLanguage["ff"] = "ff";
    PodcastLanguage["en_TT"] = "en_TT";
    PodcastLanguage["bez_TZ"] = "bez_TZ";
    PodcastLanguage["es_NI"] = "es_NI";
    PodcastLanguage["uz_Arab"] = "uz_Arab";
    PodcastLanguage["ne_NP"] = "ne_NP";
    PodcastLanguage["fi"] = "fi";
    PodcastLanguage["khq"] = "khq";
    PodcastLanguage["gsw"] = "gsw";
    PodcastLanguage["zh_Hans_MO"] = "zh_Hans_MO";
    PodcastLanguage["en_MH"] = "en_MH";
    PodcastLanguage["hu_HU"] = "hu_HU";
    PodcastLanguage["en_GB"] = "en_GB";
    PodcastLanguage["fr_BE"] = "fr_BE";
    PodcastLanguage["de_BE"] = "de_BE";
    PodcastLanguage["saq"] = "saq";
    PodcastLanguage["be_BY"] = "be_BY";
    PodcastLanguage["sl_SI"] = "sl_SI";
    PodcastLanguage["sr_Latn_RS"] = "sr_Latn_RS";
    PodcastLanguage["fo"] = "fo";
    PodcastLanguage["fr"] = "fr";
    PodcastLanguage["xog"] = "xog";
    PodcastLanguage["fr_BF"] = "fr_BF";
    PodcastLanguage["tzm"] = "tzm";
    PodcastLanguage["sk_SK"] = "sk_SK";
    PodcastLanguage["fr_ML"] = "fr_ML";
    PodcastLanguage["he_IL"] = "he_IL";
    PodcastLanguage["ha_Latn_NE"] = "ha_Latn_NE";
    PodcastLanguage["ru_RU"] = "ru_RU";
    PodcastLanguage["fr_CM"] = "fr_CM";
    PodcastLanguage["teo_KE"] = "teo_KE";
    PodcastLanguage["seh_MZ"] = "seh_MZ";
    PodcastLanguage["kl_GL"] = "kl_GL";
    PodcastLanguage["fi_FI"] = "fi_FI";
    PodcastLanguage["kam"] = "kam";
    PodcastLanguage["es_ES"] = "es_ES";
    PodcastLanguage["af"] = "af";
    PodcastLanguage["asa_TZ"] = "asa_TZ";
    PodcastLanguage["cs_CZ"] = "cs_CZ";
    PodcastLanguage["tr_TR"] = "tr_TR";
    PodcastLanguage["es_PY"] = "es_PY";
    PodcastLanguage["tzm_Latn_MA"] = "tzm_Latn_MA";
    PodcastLanguage["lg"] = "lg";
    PodcastLanguage["ebu"] = "ebu";
    PodcastLanguage["en_HK"] = "en_HK";
    PodcastLanguage["nl_NL"] = "nl_NL";
    PodcastLanguage["en_BE"] = "en_BE";
    PodcastLanguage["ms_MY"] = "ms_MY";
    PodcastLanguage["es_UY"] = "es_UY";
    PodcastLanguage["ar_BH"] = "ar_BH";
    PodcastLanguage["kw_GB"] = "kw_GB";
    PodcastLanguage["ak"] = "ak";
    PodcastLanguage["chr"] = "chr";
    PodcastLanguage["dav"] = "dav";
    PodcastLanguage["lag_TZ"] = "lag_TZ";
    PodcastLanguage["am"] = "am";
    PodcastLanguage["so_DJ"] = "so_DJ";
    PodcastLanguage["shi_Tfng_MA"] = "shi_Tfng_MA";
    PodcastLanguage["sr_Latn_ME"] = "sr_Latn_ME";
    PodcastLanguage["sn_ZW"] = "sn_ZW";
    PodcastLanguage["or_IN"] = "or_IN";
    PodcastLanguage["ar"] = "ar";
    PodcastLanguage["as"] = "as";
    PodcastLanguage["fr_BI"] = "fr_BI";
    PodcastLanguage["jmc_TZ"] = "jmc_TZ";
    PodcastLanguage["chr_US"] = "chr_US";
    PodcastLanguage["eu_ES"] = "eu_ES";
    PodcastLanguage["saq_KE"] = "saq_KE";
    PodcastLanguage["vun"] = "vun";
    PodcastLanguage["lt"] = "lt";
    PodcastLanguage["naq_NA"] = "naq_NA";
    PodcastLanguage["ga"] = "ga";
    PodcastLanguage["af_NA"] = "af_NA";
    PodcastLanguage["kea_CV"] = "kea_CV";
    PodcastLanguage["es_DO"] = "es_DO";
    PodcastLanguage["lv"] = "lv";
    PodcastLanguage["kok_IN"] = "kok_IN";
    PodcastLanguage["de_LI"] = "de_LI";
    PodcastLanguage["fr_BJ"] = "fr_BJ";
    PodcastLanguage["az"] = "az";
    PodcastLanguage["guz_KE"] = "guz_KE";
    PodcastLanguage["rw_RW"] = "rw_RW";
    PodcastLanguage["mg_MG"] = "mg_MG";
    PodcastLanguage["km_KH"] = "km_KH";
    PodcastLanguage["gl"] = "gl";
    PodcastLanguage["shi_Tfng"] = "shi_Tfng";
    PodcastLanguage["ar_AE"] = "ar_AE";
    PodcastLanguage["fr_MQ"] = "fr_MQ";
    PodcastLanguage["rm"] = "rm";
    PodcastLanguage["sv_SE"] = "sv_SE";
    PodcastLanguage["az_Cyrl"] = "az_Cyrl";
    PodcastLanguage["ro"] = "ro";
    PodcastLanguage["so_ET"] = "so_ET";
    PodcastLanguage["en_ZA"] = "en_ZA";
    PodcastLanguage["ii_CN"] = "ii_CN";
    PodcastLanguage["fr_BL"] = "fr_BL";
    PodcastLanguage["hi_IN"] = "hi_IN";
    PodcastLanguage["gu"] = "gu";
    PodcastLanguage["mer_KE"] = "mer_KE";
    PodcastLanguage["nn_NO"] = "nn_NO";
    PodcastLanguage["gv"] = "gv";
    PodcastLanguage["ru"] = "ru";
    PodcastLanguage["ar_DZ"] = "ar_DZ";
    PodcastLanguage["ar_SY"] = "ar_SY";
    PodcastLanguage["en_MP"] = "en_MP";
    PodcastLanguage["nl_BE"] = "nl_BE";
    PodcastLanguage["rw"] = "rw";
    PodcastLanguage["be"] = "be";
    PodcastLanguage["en_VI"] = "en_VI";
    PodcastLanguage["es_CL"] = "es_CL";
    PodcastLanguage["bg"] = "bg";
    PodcastLanguage["mg"] = "mg";
    PodcastLanguage["hy_AM"] = "hy_AM";
    PodcastLanguage["zu_ZA"] = "zu_ZA";
    PodcastLanguage["guz"] = "guz";
    PodcastLanguage["mk"] = "mk";
    PodcastLanguage["es_VE"] = "es_VE";
    PodcastLanguage["ml"] = "ml";
    PodcastLanguage["bm"] = "bm";
    PodcastLanguage["khq_ML"] = "khq_ML";
    PodcastLanguage["bn"] = "bn";
    PodcastLanguage["ps_AF"] = "ps_AF";
    PodcastLanguage["so_SO"] = "so_SO";
    PodcastLanguage["sr_Cyrl"] = "sr_Cyrl";
    PodcastLanguage["pl_PL"] = "pl_PL";
    PodcastLanguage["fr_GN"] = "fr_GN";
    PodcastLanguage["bo"] = "bo";
    PodcastLanguage["om_KE"] = "om_KE";
})(PodcastLanguage || (PodcastLanguage = {}));
