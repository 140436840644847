import { MdHelp, MdPodcasts, MdRssFeed, MdSupport, MdSupportAgent } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { Fragment } from 'react/jsx-runtime';
import { PlayerHome } from '@/modules/player/components/home';

import styles from './home.module.scss';
import { useTranslation } from 'react-i18next';
import { withMotion } from '../hoc/with-motion';
import { RoleGuard } from '@/modules/backend/components/role-guard';
import { FeatureGuard } from '@/modules/backend/components/feature-guard';
import { FeatureFlag } from '@nimey/podcast-global-entity';

const Page = () => {
  const { t } = useTranslation('common');
  return (
    <Fragment>
      <div className={styles.apps}>
        <Link to='/manager' className={styles.app}>
          <div className={styles.icon}><MdRssFeed /></div>
          <div className={styles.description}>
            <h1>{t('app.manager.title')}</h1>
            <p>{t('page.home.manager.description')}</p>
          </div>
        </Link>

        <Link to='/player' className={styles.app}>
          <div className={styles.icon}><MdPodcasts /></div>
          <div className={styles.description}>
            <h1>{t('app.player.title')}</h1>
            <p>{t('page.home.player.description')}</p>
          </div>
        </Link>
        <FeatureGuard flag={FeatureFlag.HELP_CENTER}>
          <Link to='/help' className={styles.app}>
            <div className={styles.icon}><MdHelp /></div>
            <div className={styles.description}>
              <h1>{t('app.help-center.title')}</h1>
              <p>{t('page.home.help-center.description')}</p>
            </div>
          </Link>
        </FeatureGuard>
        <RoleGuard roles={['super-admin', 'supporter']}>
          <Link to='/support' className={styles.app}>
            <div className={styles.icon}><MdSupportAgent /></div>
            <div className={styles.description}>
              <h1>{t('app.support.title')}</h1>
              <p>{t('page.home.support.description')}</p>
            </div>
          </Link>
        </RoleGuard>
      </div>
    </Fragment>
  );
}

const AnimatedPage = withMotion(PlayerHome);
export const roles: string[] = [];
export const path = '/';
export const element = <AnimatedPage />