import { Organization, PodcastClaimOwnershipTicketCreatePayload, PodcastListType, PodcastRequestTicketCreatePayload, TicketType } from '@nimey/podcast-global-entity';
import { withCurrentOrg } from '../../hoc/with-current-org';
import { Gutter } from '@/modules/layout/components/gutter';
import { Assistant } from '@/modules/global/components/assistant';
import { addPodcastAssistantTree } from './add-pocast.assistant';
import { AssistantState } from '@/modules/global/components/assistant/types';
import { useEffect, useMemo, useState } from 'react';
import { usePodcastList } from '@/modules/backend/hooks/use-podcast-list';
import { PodcastProvider } from '@/modules/global/components/podcast/podcast-provider';
import { Link } from 'react-router-dom';
import { Button } from '@nimey/react-ui';
import { useCurrentOrg } from '../hooks/use-current-org';
import { usePodcast } from '@/modules/backend/hooks/use-podcast';
import { PodcastTitle } from '@/modules/global/components/podcast/title';
import { useCreateTicket } from '@/modules/backend/hooks/use-create-ticket';

enum AddMode {
  NONE = 'none',
  ADD_FEED_LISTING = 'add_feed_listing',
};


const usePodcastIdByFeedUrl = (feedUrl: string) => {
  const { allIds, loadPage } = usePodcastList({feedUrl}, PodcastListType.ALL);
  useEffect(() => {
    loadPage(1);
  }, [feedUrl]);

  return allIds[0] || undefined;
}

type AddPodcastFeedListing = {feedUrl: string, claimOwnership: boolean};
const AddPodcastFeedListing = (props: AddPodcastFeedListing) => {
  const podcastId = usePodcastIdByFeedUrl(props.feedUrl);
  const {podcast, load} = usePodcast(podcastId);
  const [requestCreated, setRequestCreated] = useState<boolean>(false);
  useEffect(() => { load() }, [podcastId]);
  const org = useCurrentOrg();
  const createTicketObj = useCreateTicket();

  if(podcastId && !props.claimOwnership) {
    return (
      <div>
        <PodcastProvider podcastId={podcastId}>
          <p>Der von dir gewünschte Podcast existiert bereits. Da du dich entschieden hast, die Inhaberschaft nicht zu übernehmen, sind keine weiteren Handlungen nötig.</p>
          <div><Link to={`/player/pod/${podcastId}`}><Button>Zum Podcast</Button></Link></div>
          <p>Möchtest du trotzdem Zugriff auf den Podcast erhalten, wende dich an den aktuellen Inhaber. Dieser kann dir Zugriff auf die entsprechende Organisation erteilen.</p>
        </PodcastProvider>
      </div>
    )
  }

  if(podcastId && props.claimOwnership) {
    if(podcast && podcast.orgId === org?.id) {
      return (
        <div>
          <PodcastProvider podcastId={podcastId}>
          <p>Der von dir gewünschte Podcast existiert bereits und gehöhrt zur Organisation "{org?.name}"</p>

          <Link to={`/manager/${podcast.orgId}/podcast/${podcast.id}`}><Button>Zum Podcast</Button></Link>

        </PodcastProvider>
        </div>
      );
    }

    if(requestCreated) return (
      <div>Deine Anfrage wird jetzt bearbeitet. Du solltest in kürze eine E-Mail erhalten, um die Anfrage abzuschließen</div>
    )

    return (
      <div>
        <PodcastProvider podcastId={podcastId}>
          <p>Der von dir gewünschte Podcast "{podcast?.title}" existiert bereits. Möchtest du wirklich die Inhaberschaft für diesen Podcast übernehmen?</p>
          <p>Nach erfolgreicher Bestätigung, wird die aktuelle Organisation als Inahber entfernt und die Organisation "{org?.name}" als Inahber eingetragen.</p>
          <p><Button onClick={() => {
            if(!org || !podcast) return;
            const ticket: PodcastClaimOwnershipTicketCreatePayload = {
              title: podcast.title,
              type: TicketType.PODCAST_CLAIM_OWNERSHIP,
              orgId: org.id,
              podcastId: podcast.id
            }
            createTicketObj.create(ticket).then(() => {
              setRequestCreated(true);
            })
          }}>Jetzt Inhaberschaft beantragen</Button></p>

          <p><small>Wenn du die Inhaberschaft beantragst, wird eine E-Mail an die im Feed hinterlegte Adresse verschickt. In dieser E-Mail findet sich ein Link zur Bestätigung.</small></p>
        </PodcastProvider>
      </div>
    )
  }

  if(!podcastId) {

    if(requestCreated) {
      return (
        <p>Deine Anfrage wurde erstellt. Du erhälst eine Benachrichtung, sobald der Podcast hinzugefügt wurde.</p>
      );
    }

    return (
      <div>
        <p>Möchtest du den Podcast mit der URL "{props.feedUrl}" zu @nimey podcast hinzufügen?</p>
        {props.claimOwnership ? (
          <p>Nachdem der Import abgeschlossen ist, erhälst du eine E-Mail an die im Feed hinterlege E-Mailadresse, um die Inhaberschaft zu bestätigen.</p>
        ) : ''}
        <Button primary onClick={(e) => {
          const ticket: PodcastRequestTicketCreatePayload = {
            title: `Add Podcast ${props.feedUrl}`,
            type: TicketType.PODCAST_REQUEST,
            orgId: props.claimOwnership ? org?.id : undefined,
            feedUrl: props.feedUrl,
          }
          createTicketObj.create(ticket).then(() => {
            setRequestCreated(true);
          })
        }}>Podcast hinzufügen</Button>
        <Button>abbrechen</Button>
      </div>
    );
  } 

  return (
    <div>
    </div>
  );
}

const OrgAddPodcastWithOrg = (props: {org: Organization}) => {
    const [ baseValue, setBaseValue ] = useState<AssistantState>({});
  
    const { mode, addProps } = useMemo(() => {
      let mode = AddMode.NONE;
      let addProps: Record<string, any> = {};
      if(baseValue['add-podcast.hosting_managed_selection'] === 'listing' && baseValue['add-podcast.listing.feed_url'] && typeof baseValue['add-podcast.listing.claim_ownershop'] !== 'undefined') {
        try {
          const feedUrl = new URL(baseValue['add-podcast.listing.feed_url']);
          if(feedUrl.protocol.startsWith('http')) {
            mode = AddMode.ADD_FEED_LISTING;
            addProps.feedUrl = baseValue['add-podcast.listing.feed_url'];
            addProps.claimOwnership = baseValue['add-podcast.listing.claim_ownershop'];
          }
        } catch {}
      }

      return {
        mode,
        addProps
      }
    }, [ baseValue ])

    return (
      <Gutter>
        <h1>Podcast hinzufügen</h1>
        <Assistant
          tree={addPodcastAssistantTree}
          setResult={(v) => setBaseValue(v)}
        />
        {mode === AddMode.ADD_FEED_LISTING ? <AddPodcastFeedListing {...(addProps as AddPodcastFeedListing)} /> : ''}
      </Gutter>
    );
  }
  
  export const OrgAddPodcast = withCurrentOrg<{}>(OrgAddPodcastWithOrg);