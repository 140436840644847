import { PropsWithChildren, useContext, useEffect, useReducer, useRef } from 'react';
import { backendReducer } from '../reducer/backend-reducer';
import { getAsyncActions } from '../reducer/backend-async-actions';
import { BackendDispatchContext, BackendStateContext, initialState } from '../context/backend-context';
import { FeatureController, OrganizationController, PlaylistController, PodcastController, TicketController, TokenController, UserController } from '@nimey/podcast-client';
import { BackendState } from '../types/backend-state';
import { Authenticated } from '../util/authenticated';
import { AuthDispatchContext } from '@/modules/auth/context/auth-context';
import { Client } from '../util/client';
import { useCurrentAccount } from '@/modules/auth/hooks/use-current-account';
import { BackendActionKind } from '../types/backend-action';
import { BACKEND_API_URL } from '../config/api';


export const BackendProvider = (props: PropsWithChildren) => {
  const { asyncActions: authAsyncActions } = useContext(AuthDispatchContext);

  const acc = useCurrentAccount()

  const authenticatedRef = useRef<Authenticated>(new Authenticated(authAsyncActions!))
  const clientRef = useRef<Client>(new Client(authenticatedRef.current, BACKEND_API_URL));
  const controllerRef = useRef<BackendState["controller"]>({
    organization: new OrganizationController(clientRef.current),
    user: new UserController(clientRef.current),
    ticket: new TicketController(clientRef.current),
    podcast: new PodcastController(clientRef.current),
    feature: new FeatureController(clientRef.current),
    playlist: new PlaylistController(clientRef.current),
    token: new TokenController(clientRef.current),
  })

  useEffect(() => {
    authenticatedRef.current.setAsyncActions(authAsyncActions!);
  }, [authAsyncActions])
  const [state, dispatch] = useReducer(backendReducer, {
    ...initialState,
    client: clientRef.current,
    controller: controllerRef.current
  });
  const asyncActions = getAsyncActions(state, dispatch);

  useEffect(() => {
    dispatch({type: BackendActionKind.RESET, payload: undefined});
  }, [acc, dispatch])

  return (
    <BackendStateContext.Provider value={state}>
      <BackendDispatchContext.Provider value={{dispatch, asyncActions}}>
        {props.children}
      </BackendDispatchContext.Provider>
    </BackendStateContext.Provider>
  );
}
