import { PlayerController } from '@/modules/media-player/components/player-controller';
import { MdHistory, MdKeyboardArrowLeft, MdList, MdSettings } from 'react-icons/md';
import styles from './footer.module.scss';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useHistory } from '@/modules/router/hooks/use-history';

export const PlayerFooter = () => {
  const { pathname } = useLocation();
  const {hasNavigated}  = useHistory()
  const navigate = useNavigate();
  return (
    <div className={styles['footer-icon-bar']}>
      <button title='Zurück' onClick={() => navigate(-1)} disabled={!hasNavigated} className={`${styles.button} ${styles.icon}`}><MdKeyboardArrowLeft /></button>
      <Link title='Verlauf' to='/player/history' className={`${styles.icon} ${pathname === '/player/history' ? styles.active : ''}`}><MdHistory /></Link>
      <Link title='Playlist' to='/player/playlist' className={`${styles.icon} ${pathname === '/player/playlist' ? styles.active : ''}`}><MdList /></Link>
      {/* <Link to='/player/settings' className={`${styles.icon} ${pathname === '/player/settings' ? styles.active : ''}`}><MdSettings /></Link> */}
    </div>
  );
}