import { useCurrentAccount } from '@/modules/auth/hooks/use-current-account';

export function withCurrentUserId<P extends object>(
  WrappedComponent: React.ComponentType<P>,
  FallbackComponent?: React.ComponentType<P>,
) {
  const WithUserId: React.FC<P> = (props) => {
    const acc = useCurrentAccount();

    const Fallback = FallbackComponent || WrappedComponent;

    if(!acc) return <Fallback {...props} />;

    return <WrappedComponent {...props} userId={acc.id} />
  }

  return WithUserId;
}