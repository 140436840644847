import { useLocalEpisode } from './episode-provider';
import Markdown from 'react-markdown'

import styles from './description.module.scss';

type EpisodeDescriptionProps = {
  maxLines?: number | string;
}

export const EpisodeDescription = (props: EpisodeDescriptionProps) => {
  const { episode } = useLocalEpisode();
  const maxLines = props.maxLines || 2;

  return (
    <div style={{'--number-of-lines': maxLines}} className={`${styles['max-lines']} ${styles['markdown']}`}>
      <Markdown>{episode?.description}</Markdown>
    </div>
  );
}