import React, { useContext } from 'react';
import { NotificationChannel, NotificationComponent } from '../types/notification';
import { NotificationContext } from '../context/notification-context';

export const useNotify = () => {
  const { addNotification  } = useContext(NotificationContext)
  const notify = React.useCallback((channel: NotificationChannel, component: NotificationComponent) =>  {
    const id = window.crypto.randomUUID();
    addNotification({
      id,
      channel,
      component
    })
  }, [addNotification])

  return notify;
}