import { useContext } from 'react';
import { BackendDispatchContext } from '../context/backend-context';
import { useEntityList } from './use-entity-list';
import { PodcastFilter, PodcastListType } from '@nimey/podcast-global-entity';

export const usePodcastList = (filter: PodcastFilter, listType: PodcastListType) => {
  const { asyncActions } = useContext(BackendDispatchContext)
  const filterKey = JSON.stringify({...filter, listType});
  
  return useEntityList(filter, 'podcast', async (filter: PodcastFilter, pageNumber?: number, pageSize?: number) => {
    return asyncActions!.loadPodcastList(filter, listType, pageNumber, pageSize)
  }, filterKey)
}