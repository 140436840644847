import { useTicket } from '@/modules/backend/hooks/use-ticket';
import { Modal } from '@/modules/layout/components/modal';
import { Fragment, useEffect } from 'react';

import styles from './tickets.module.scss';

import { DateDuration } from '@/modules/global/components/date-duration';
import { Link } from 'react-router-dom';
import { UserNameInfo } from '@/modules/global/components/user/user-name-info';
import { Hr } from '@/modules/layout/components/hr';
import { TicketType } from './ticket-type/index';
import { TicketActions } from './ticket-actions';
import { TicketMessages } from './ticket-messages';
import { Spinner } from '@/modules/layout/components/spinner';
import { TicketTypeLabel } from './type-label';
import { TicketStatusLabel } from './status-label';
import { RoleGuard } from '@/modules/backend/components/role-guard';

export type TicketModalProps = {
  ticketId?: string;
  isOpen: boolean;
  close: () => void;
}

export const TicketModalFooter = (props: TicketModalProps) => {
  return (
    <div>
    </div>
  );
}
export const TicketModal = (props: TicketModalProps) => {
  const { ticket, load } = useTicket(props.ticketId);
  useEffect(() => {
    load();
  }, [props.ticketId])

  return (
    <Modal {...{isOpen: props.isOpen, close: props.close}}
      title={ticket?.title || ''}
      footer={<TicketModalFooter {...props} />}
    >
      <div className={styles['ticket-modal']}>
        <div className={styles.header}>
          <div className={styles.base}>
            <p className={styles.title}>{ticket?.title}</p>
            <p className={styles.author}>Author: <Link to={`/support/user/${ticket?.creatorId}`}><UserNameInfo userId={ticket?.creatorId} /></Link></p>
            <p className={styles['created-at']}>Erstellt: <DateDuration fromTimestamp={ticket?.createdAt} /></p>
          </div>
          <div className={styles.type}>
            <p>{ticket ? <TicketTypeLabel type={ticket.type} /> : <Spinner size='1em' />}</p>
            <p>{ticket ? <TicketStatusLabel status={ticket.status} /> : <Spinner size='1em' />}</p>
          </div>
        </div>
        <Hr />
        <TicketType {...props} ticket={ticket} />
        {ticket ? (
          <Fragment>
            <RoleGuard roles={['super-admin', 'supporter']}>
              <TicketActions {...{ticket}} />
            </RoleGuard>
            <TicketMessages ticketId={ticket.id} />
          </Fragment>
        ) : ''}
      </div>
    </Modal>
  );
}