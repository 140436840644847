import { useCallback, useContext } from 'react';
import { BackendDispatchContext, BackendStateContext } from '../context/backend-context';
import { PodcastEpisode } from '@nimey/podcast-global-entity';

export const usePodcastEpisode = (id?: string) => {
  const { asyncActions } = useContext(BackendDispatchContext)
  const state = useContext(BackendStateContext);

  const loadCallback = useCallback((id: string) => asyncActions!.loadPodcastEpisode(id), [asyncActions])

  const entity = (id && state.entities['podcast-episode']) ? (state.entities['podcast-episode'][id] || undefined) : undefined;
  const isLoaded = !!entity;

  const load = useCallback(async (force: boolean = false) => (!isLoaded || force) && id && loadCallback(id), [id, loadCallback, isLoaded])

  return {
    episode: entity?.entity as PodcastEpisode | undefined,
    isLoaded: !!entity,
    load,
  };
}