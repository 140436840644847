import { useAddUserFeature } from '@/modules/backend/hooks/use-add-user-feature';
import { useFeatureFlags } from '@/modules/backend/hooks/use-feature-flags';
import { useGlobalFeatureFlags } from '@/modules/backend/hooks/use-global-feature-flags';
import { useRemoveUserFeature } from '@/modules/backend/hooks/use-remove-user-feature';
import { FeatureFlag } from '@nimey/podcast-global-entity';
import { useEffect, useMemo } from 'react';
import { MdAdd, MdRemove } from 'react-icons/md';

import styles from './user.module.scss';

export type UserFeaturesProps = {
  userId: string;
}

const FeatureWithRemove = (props: {userId: string, flag: FeatureFlag}) => {
  const { remove } = useRemoveUserFeature(props.flag, props.userId);;
  return (
    <li className={`${styles['tag']} ${styles['user']}`}>{props.flag} <button onClick={remove}><MdRemove /></button></li>
  )
}

const FeatureWithAdd = (props: {userId: string, flag: FeatureFlag}) => {
  const { add } = useAddUserFeature(props.flag, props.userId);;
  return (
    <li className={`${styles['tag']} ${styles['missing']}`}>{props.flag} <button onClick={add}><MdAdd /></button></li>
  )
}


export const UserFeatures = (props: UserFeaturesProps) => {
  const { features: globalFeatures, load: globalLoad } = useGlobalFeatureFlags();
  useEffect(() => { globalLoad() }, [])

  const { features, load } = useFeatureFlags(props.userId);
  useEffect(() => { load() }, [props.userId])

  const {
    allGlobal,
    allUser,
    allMissing,
  } = useMemo(() => {
    const allGlobal = globalFeatures?.flags || [];
    const allUser = (features?.flags || []).filter(flag => !allGlobal.includes(flag));
    const all = [...allGlobal, ...allUser];
    const allMissing = Object.values(FeatureFlag).filter(flag => !all.includes(flag));
    return {
      allGlobal,
      allUser,
      allMissing
    }
  }, [features, globalFeatures])

  return (
    <div>
      <h2>User Funktionen</h2>
      <h3>Global aktivierte Funktionen</h3>
      <ul className={styles['feature-list']}>
        {allGlobal.map(flag => (
          <li className={`${styles['tag']} ${styles['global']}`} key={`global-${flag}`}>G {flag}</li>
        ))}
      </ul>
      <h3>Benutzerdefinierte Funktionen</h3>
      <ul className={styles['feature-list']}>
        {allUser.map(flag => (
          <FeatureWithRemove key={`user-${flag}`} {...{userId: props.userId, flag}} />
        ))}
      </ul>
      <h3>Inaktive Funktionen</h3>
      <ul className={styles['feature-list']}>
        {allMissing.map(flag => (
          <FeatureWithAdd key={`missing-${flag}`} {...{userId: props.userId, flag}} />
          ))}
      </ul>
    </div>
  );
}