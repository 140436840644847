import { useCallback, useContext, useEffect } from 'react';
import { BackendDispatchContext, BackendStateContext } from '../context/backend-context';
import { UserFeatures } from '@nimey/podcast-global-entity';

export const useFeatureFlags = (userId?: string) => {
  const { asyncActions } = useContext(BackendDispatchContext)
  const state = useContext(BackendStateContext);

  const id = userId || 'anonymous'

  const entity = state.entities['feature'] ? state.entities['feature'][id] || undefined : undefined;

  const isLoaded = !!entity;

  const load = useCallback(async (force: boolean = false) => {
    if(id && (!isLoaded || force)) asyncActions!.loadUserFeatureFlags(id);
  }, [asyncActions, isLoaded, id]);

  useEffect(() => {
    if(id && entity?.invalidated) load(true);
  }, [entity?.invalidated, load])

  return {
    features: (entity?.entity as UserFeatures),
    isLoaded: !!entity,
    load
  };
}