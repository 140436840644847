import { BaseController } from './base.controller.js';
export class UserController extends BaseController {
    client;
    constructor(client) {
        super();
        this.client = client;
    }
    getById(id) {
        return this.client.get(`/user/${id}`);
    }
    search(query, skip = 0, limit = 5) {
        return this.client.get('/user', { query, skip: skip.toString(), limit: limit.toString() });
    }
    async getRoles(id) {
        const result = await this.client.get(`/user/${id}/roles`);
        return result;
    }
    async getQuota(quotaName, userId) {
        return await this.client.get(`/user/${userId}/quota/${quotaName}`);
    }
}
