import * as React from 'react';
import { BrowserHistory } from 'history';
import { Outlet, Router, RouterProvider, createBrowserRouter } from 'react-router-dom';
import { HistoryContext } from '../context/history-context';
import { AuthProvider } from '@/modules/auth/components/auth-provider';
import { BackendProvider } from '@/modules/backend/components/backend-provider';
import { MediaPlayerProvider } from '@/modules/media-player/components/provider';
import { RegisterAccountChange } from '@/modules/notification/components/register-account-change';
import { AnimatePresence } from 'framer-motion';
import { allPages } from '../pages';
import { BasePage } from '@/modules/layout/components/base-page';
import { NotificationProvider } from '@/modules/notification/components/notification-provider';
type Props = {
  basename?: string;
  children: React.ReactNode;
  history: BrowserHistory;
}

const LocationProvider = (props: React.PropsWithChildren) => <AnimatePresence>{props.children}</AnimatePresence>


const router = createBrowserRouter([
  {
    element: (
      <LocationProvider>
          <AuthProvider>
            <BackendProvider>
              <NotificationProvider>
                <MediaPlayerProvider>
                  <BasePage />
                </MediaPlayerProvider>
                    <RegisterAccountChange />
              </NotificationProvider>
            </BackendProvider>
          </AuthProvider>
        </LocationProvider>
    ),
    children: allPages.map(p => ({
      path: p.path,
      element: p.element
    }))
  },
]);

export const HistoryBrowserRouter = ({ basename, children, history }: Props) => {
  const [state, setState] = React.useState({
    action: history.action,
    location: history.location,
    hasNavigated: false,
  });
  React.useEffect(() => {
    router.subscribe((state) => {
      setState(s => ({
        action: state.historyAction,
        location: state.location,
        hasNavigated: true,
      }))
    });

  }, [])
  return (
    <HistoryContext.Provider value={state}>
      <RouterProvider router={router} />
    </HistoryContext.Provider>
  );
}
