export var QuotaName;
(function (QuotaName) {
    QuotaName["CREATE_ORGANISATION"] = "create-organization";
    QuotaName["CREATE_TICKET"] = "create-ticket";
    QuotaName["USER_PLAYLISTS"] = "user-playlists";
})(QuotaName || (QuotaName = {}));
export const QuotaDefinition = {
    [QuotaName.CREATE_ORGANISATION]: {
        defaultValue: 2,
        isAdjustable: true
    },
    [QuotaName.CREATE_TICKET]: {
        defaultValue: 10,
        isAdjustable: true
    },
    [QuotaName.USER_PLAYLISTS]: {
        defaultValue: 3,
        isAdjustable: true
    },
};
