import { withMotion } from '../hoc/with-motion';
import { Gutter } from '@/modules/layout/components/gutter';
import { ListLayout, PodcastList } from '@/modules/player/components/list/podcast';
import { PodcastListType } from '@nimey/podcast-global-entity';

const Wrapper = () => <Gutter><PodcastList listType={PodcastListType.USER_ABO} layout={ListLayout.TILES} title={'Meine Abos'} /></Gutter>

const AnimatedPage = withMotion(Wrapper);

export const roles: string[] = [];
export const path = '/player/my-abos';
export const element = <AnimatedPage />