import { useOrganizationList } from '@/modules/backend/hooks/use-organization-list';
import { Fragment, useEffect, useMemo } from 'react';
import { OrganizationTile } from './tile';

import styles from './organization.module.scss';
import { useTranslation } from 'react-i18next';
import { LoadMore } from '@/modules/layout/components/load-more';

export const OrganizationList = () => {
  const listData = useOrganizationList('default');
  useEffect(() => { listData.loadPage(1) }, [listData]);
  const { t } = useTranslation('manager');

  const orgIds = listData.allIds

  return (
    <Fragment>
      <div className={styles.list}>
        {orgIds.map(orgId => <OrganizationTile key={orgId} orgId={orgId} />)}
        <div className={styles.actions}>
          <LoadMore {...{hasMore: listData.hasMore, loadMore: listData.loadMore}} />
        </div>
      </div>
    </Fragment>
  )
}