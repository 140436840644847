import { useCallback, useContext, useEffect } from 'react';
import { BackendDispatchContext, BackendStateContext } from '../context/backend-context';
import { QuotaName, UserQuota } from '@nimey/podcast-global-entity';
import { AuthStateContext } from '@/modules/auth/context/auth-context';

export const useUserQuota = (id: string, quotaName: QuotaName) => {
  const { asyncActions } = useContext(BackendDispatchContext)
  const state = useContext(BackendStateContext);
  const authState = useContext(AuthStateContext);


  const currentAccountId = id || authState.currentAccountId!;

  const lookupId = `${currentAccountId}-${quotaName}`

  const entity = currentAccountId && state.entities['user-quota'] ? state.entities['user-quota'][lookupId] || undefined : undefined;

  const isLoaded = !!entity;

  const load = useCallback(async (force: boolean = false) => {
    if(currentAccountId && (!isLoaded || force)) asyncActions!.loadUserQuota(currentAccountId, quotaName);
  }, [asyncActions, currentAccountId, isLoaded, quotaName]);

  useEffect(() => {
    if(entity?.invalidated) load(true);
  }, [entity?.invalidated, load])

  return {
    userQuota: (entity?.entity as UserQuota),
    isLoaded: !!entity,
    load
  };
}