import { LOCAL_STORAGE_KEY_PENDING_LOGIN, LOCAL_STORAGE_KEY_PENDING_VERIFIER } from '../config/local-storage';
import { CLIENT_ID } from '../config/oidc';
import { Account } from '../types/account';
import { AuthAction, AuthActionKind } from '../types/auth-action';
import { AuthState } from '../types/auth-state';
import { getIssuerConfig } from '../util/get-issuer-config';
import { generateChallenge, generateVerifier } from '../util/pkce';
import { TokenInput, TokenSet } from '../util/token-set';

import { Duration } from '@nimey/units';

const getCurrentTokenSetPromise: {prom: Promise<TokenSet> | undefined} = {prom: undefined};

class TokenManager {
  protected loginRequestPromise : Promise<void> | undefined;
  protected resolveLoginRequest: any;

  protected processCodePromise : Promise<{account: Account, tokenSet: TokenSet}> | undefined;
  protected resolveProcessCode: any;

  constructor() {
    if(localStorage.getItem(LOCAL_STORAGE_KEY_PENDING_LOGIN)) {
      this.loginRequestPromise = new Promise((resolve) => {
        setTimeout(resolve, +Duration.seconds(3));
        this.resolveLoginRequest = resolve;
      });
    }
  }

  requestLogin(options: {forceLogin?: boolean, email?: string} = {}) {
    this.loginRequestPromise = this.loginRequestPromise || new Promise(async (resolve) => {
      this.resolveLoginRequest = resolve;
      try {
        const verifier = await generateVerifier();
        localStorage.setItem(LOCAL_STORAGE_KEY_PENDING_VERIFIER, verifier);
        const challenge = await generateChallenge(verifier);
        const issuerConfig = await getIssuerConfig();
        if(!issuerConfig) throw new Error('cant get issuer config');
  
        const targetUrl = new URL(issuerConfig.authorization_endpoint)
        const redirectUri = new URL(window.location.href);
  
        const params = targetUrl.searchParams || new URLSearchParams();
        params.set('client_id', CLIENT_ID);
        params.set('redirect_uri', redirectUri.toString());
        params.set('response_type', 'code');
        params.set('scope', 'openid offline_access');
        params.set('code_challenge_method', 'S256');
        params.set('code_challenge', challenge);
        if(options.forceLogin || options.email) params.set('prompt', 'login');
        if(options.email) params.set('login_hint', options.email);
  
        targetUrl.search = params.toString();
        window.location.replace(targetUrl.toString());
      } catch(e) {
        console.log(e);
      }
      resolve();
    })

    return this.loginRequestPromise;
  }

  processCode(code: string) {
    this.processCodePromise = this.processCodePromise || new Promise(async (resolve) => {
      this.resolveProcessCode = resolve;
      const issuerConfig = await getIssuerConfig();
      if(!issuerConfig) throw new Error('cant get issuer config');

      const verifier = localStorage.getItem(LOCAL_STORAGE_KEY_PENDING_VERIFIER)!;
      const redirectUri = new URL(window.location.href);
      redirectUri.search = '';

      const tokenUrl = new URL(issuerConfig.token_endpoint);
      const formData = new URLSearchParams();
      formData.set('grant_type', 'authorization_code');
      formData.set('redirect_uri', redirectUri.toString());
      formData.set('code', code);
      formData.set('client_id', CLIENT_ID);
      formData.set('code_verifier', verifier);


      const response = await fetch(tokenUrl, {
        method: 'POST',
        headers: {
          'content-type': 'application/x-www-form-urlencoded'
        },
        body: formData.toString(),
      })

      const data = await response.json();
      const tokenSet = new TokenSet(data as TokenInput);
      const account: Account = {
        id: tokenSet.access_token.sub,
        email: tokenSet.access_token.email,
        name: tokenSet.access_token.name,
        firstName: tokenSet.access_token.givenName,
        familyName: tokenSet.access_token.familyName,
      }

      resolve({account, tokenSet});
    })

    return this.processCodePromise;
  }
}

const tokenManager = new TokenManager();


export const getAsyncActions = (state: AuthState, dispatch: React.Dispatch<AuthAction>) => {
  const startLogin = async (options: {forceLogin?: boolean, email?: string} = {}) => {
    return tokenManager.requestLogin(options);
  };
  return {
    startLogin,
    processLoginCode: async (code: string, iss: string, sessionState: string) => {
      const { account, tokenSet } = await tokenManager.processCode(code);

      dispatch([
        {type: AuthActionKind.ADD, payload: account},
        {type: AuthActionKind.ADD_TOKEN_SET, payload: tokenSet},
      ]);
    },
    getCurrentTokenSet: async () => {
      getCurrentTokenSetPromise.prom = new Promise(async (resolve, reject) => {
        if(!state.currentAccountId) {
          reject('no account id')
          getCurrentTokenSetPromise.prom = undefined;
          return;
        }
        if(!state.tokenSets[state.currentAccountId]) {
          reject('no token set')
          getCurrentTokenSetPromise.prom = undefined;
          return;
        }

        const tokenSet = state.tokenSets[state.currentAccountId];
        const tokenLifetime = (tokenSet.access_token.exp * 1000) - Date.now() - (+Duration.seconds(30));
        if(tokenLifetime > 0) {
          getCurrentTokenSetPromise.prom = undefined;
          resolve(tokenSet);
          return;
        }

        const issuerConfig = await getIssuerConfig();
        if(!issuerConfig) {
          reject('auth service not reachable');
          getCurrentTokenSetPromise.prom = undefined;
          return;
        }
        const tokenUrl = new URL(issuerConfig.token_endpoint);
        const formData = new URLSearchParams({
          client_id: CLIENT_ID,
          grant_type: 'refresh_token',
          refresh_token: tokenSet.refresh_token.toString(),
        });

        const refreshResponse = await fetch(tokenUrl, {
          method: 'POST',
          headers: new Headers({ 'content-type': 'application/x-www-form-urlencoded'}),
          body: formData
        });

        const refreshData = await refreshResponse.json();

        if(refreshData.error) {
          startLogin({forceLogin: false, email: state.accounts[state.currentAccountId].email})
          return;
        }

        const newTokenSet = new TokenSet(refreshData);
        dispatch({type: AuthActionKind.ADD_TOKEN_SET, payload: newTokenSet});
        resolve(newTokenSet);
        getCurrentTokenSetPromise.prom = undefined;
      })

      return getCurrentTokenSetPromise.prom
    },
    logout: async () => {
      const issuerConfig = await getIssuerConfig();
      if(!issuerConfig) return;
     
      const redirectUri = new URL(window.location.href);
      const logoutUrl = new URL(issuerConfig.end_session_endpoint);
      logoutUrl.search = (new URLSearchParams({
        redirectUri: redirectUri.toString(),
      })).toString();
      if(state.currentAccountId) {
        dispatch([
          {type: AuthActionKind.REMOVE, payload: {id: state.currentAccountId}},
          {type: AuthActionKind.SET_CURRENT, payload: {id: undefined}},
        ]);
      }

      window.location.replace(logoutUrl)

      
    }
  }
}
