import { Gutter } from '@/modules/layout/components/gutter';

import { AboPodcasts } from './list/abo-podcasts';
import { withCurrentUserId } from '@/modules/backend/hoc/with-current-userid';
import { SelectAccountBanner } from '@/modules/auth/components/select-account-banner';
import { AnonymousPodcasts } from './list/anonymous-podcasts';
import { ListLayout, PodcastList } from './list/podcast';
import { PodcastCategory, PodcastCategoryMap, PodcastListType } from '@nimey/podcast-global-entity';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const PlayerAllPodcastsWithUser = () => {
  const { t } = useTranslation('category');
  const {categories, hasSub} = useMemo(() => {
    const categories = Object.keys(PodcastCategoryMap).filter(c => !c.match(/>/))

    const hasSub: Record<string, boolean> = {};
    for(const category of categories) {
      hasSub[PodcastCategoryMap[category]] = !!Object.keys(PodcastCategoryMap).find(c => c !== category && c.startsWith(category))
    }

    return {
      categories: categories.map(c => PodcastCategoryMap[c]),
      hasSub
    }
  }, [])

  return (
    <Gutter>
        {categories.map(category => (
          <div key={category}>
            {hasSub[category] ? (
              <Link to={`/player/all-podcasts/${category}`}><h2>{t(category)}</h2></Link>
            ) : (
              <h2>{t(category)}</h2>
            )}
            
            <PodcastList
              listType={PodcastListType.ALL}
              layout={ListLayout.CAROUSEL}
              title=''
              filter={{categories: [category]}}
            />
          </div>
        ))}
        
    </Gutter>
  );
}

const PlayerHomeAnonymous = () => {
  return (
    <Gutter>
      <p>Du bist nicht angemeldet. Wähle jetzt dein Konto oder erstelle ein neues, um alle Funktionen zu nutzten.</p>
      <SelectAccountBanner />
      <AnonymousPodcasts limit={16} />
    </Gutter>
  );
}

export const PlayerAllPodcasts = withCurrentUserId(PlayerAllPodcastsWithUser, PlayerHomeAnonymous)