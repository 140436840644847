import { useCurrentEpisode } from '../hooks/use-current-episode';
import { MdPause, MdPlayArrow } from 'react-icons/md';
import { useTogglePlay } from '../hooks/use-toggle-play';
import { useHashStateFlag } from '@/modules/global/hooks/use-hash-state-flag';
import { Fragment } from 'react/jsx-runtime';
import { PlayerModal } from './player-modal';
import { PlayerProgressSlim } from './player-progress';
import { useCurrentPodcast } from '../hooks/use-current-podcast';

import styles from './media-player.module.scss';
import { Artwork, ArtworkWrapper } from '@/modules/global/components/podcast/artwork';
import { ImageSize } from '@nimey/podcast-global-entity';



export const PlayerController = (props: {square?: boolean}) => {
  const episode = useCurrentEpisode();
  const podcast = useCurrentPodcast();
  const [_, setIsOpen] = useHashStateFlag('player')
  const { play, toggle } = useTogglePlay();

  if(!episode) return <></>

  if(props.square) {
    return (
      <div className={styles.square}>
        <div className={styles.button}><button onClick={() => toggle()} style={{padding: 10}}>{play ? <MdPause /> : <MdPlayArrow />}</button></div>
      </div>
    );
  }

  return (
    <Fragment>
      <div className={styles.slim}>
        <div className={styles.main}>
          <div className={styles.button}><button onClick={() => toggle()} style={{padding: 10}}>{play ? <MdPause /> : <MdPlayArrow />}</button></div>
          <div className={styles.episode}
            onClick={() => setIsOpen(true)}
          >
            <ArtworkWrapper width={30}><Artwork podcastId={podcast?.id} size={ImageSize.SMALL} fallbackImage={podcast?.imageLink} /></ArtworkWrapper>
            <p className={styles.title}>{episode.title}</p>
          </div>
        </div>
        <div className={styles.progress}>
          <PlayerProgressSlim />
        </div>
      </div>
      <PlayerModal />
    </Fragment>
  );
}