import { useContext } from 'react';
import { BackendDispatchContext, BackendStateContext } from '../context/backend-context';
import { BaseTicket, TicketMessage } from '@nimey/podcast-global-entity';

export const useTicketMessage = (id: string) => {
  const { asyncActions } = useContext(BackendDispatchContext)
  const state = useContext(BackendStateContext);
  const entity = state.entities['ticket-message'] ? state.entities['ticket-message'][id] || undefined : undefined;
  const isLoaded = !!entity;
  return {
    message: entity?.entity as TicketMessage | undefined,
    isLoaded,
  };
}