import { useHashStateFlag } from '@/modules/global/hooks/use-hash-state-flag';
import { Modal } from '@/modules/layout/components/modal';
import { useCurrentEpisode } from '../hooks/use-current-episode';
import styles from './media-player.module.scss';
import { PlayerProgress } from './player-progress';
import { MdForward10, MdForward30, MdPause, MdPlayArrow, MdReplay10, MdReplay30 } from 'react-icons/md';
import { useTogglePlay } from '../hooks/use-toggle-play';
import { useCurrentPodcast } from '../hooks/use-current-podcast';
import { Artwork, ArtworkWrapper } from '@/modules/global/components/podcast/artwork';
import { ImageSize } from '@nimey/podcast-global-entity';

import { Link } from 'react-router-dom';
import { useSeek } from '../hooks/use-seek';

export const PlayerModal = () => {
  const [isOpen, setIsOpen] = useHashStateFlag('player')
  const episode = useCurrentEpisode();
  const podcast = useCurrentPodcast();
  const { play, toggle } = useTogglePlay();

  const { seekOffset } = useSeek();

  return (
    <Modal
      isOpen={isOpen}
      close={() => setIsOpen(false)}
    >
      <div className={styles['player-modal']}>
        <Link to={`/player/pod/${episode?.podcastId}/${episode?.id}`}>
          <div className={styles.artwork}>
          <ArtworkWrapper width={250}>
            <Artwork podcastId={podcast?.id} size={ImageSize.MEDIUM} fallbackImage={podcast?.imageLink} />
          </ArtworkWrapper>
          </div>
          <div className={styles['episode-title']}>{episode?.title}</div>
          <div className={styles['podcast-title']}>{podcast?.title}</div>
        </Link>
        <PlayerProgress />
        <div className={styles.controls}>
          <div className={styles.seek}><button onClick={() => seekOffset(-10)} className={styles.button}><MdReplay10 /></button></div>
          <div className={styles.seek}><button onClick={() => seekOffset(-30)} className={styles.button}><MdReplay30 /></button></div>
          <div className={styles['play-pause']}><button className={styles.button} onClick={() => toggle()}>{play ? <MdPause /> : <MdPlayArrow />}</button></div>
          <div className={styles.seek}><button onClick={() => seekOffset(30)} className={styles.button}><MdForward30 /></button></div>
          <div className={styles.seek}><button onClick={() => seekOffset(10)} className={styles.button}><MdForward10 /></button></div>

        </div>
      </div>
    </Modal>
  );
}