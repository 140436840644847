import common_en from './en/common.json';
import common_de from './de/common.json';

import auth_en from './en/auth.json';
import auth_de from './de/auth.json';

import manager_en from './en/manager.json';
import manager_de from './de/manager.json';

import support_de from './de/support.json';
import support_en from './en/support.json';

import player_de from './de/player.json';
import player_en from './en/player.json';

import category_de from './de/category.json';
import category_en from './en/category.json';

export const resources = {
  en: {
    common: common_en,
    auth: auth_en,
    manager: manager_en,
    support: support_en,
    player: player_en,
    category: category_en,
  },
  de: {
    common:common_de,
    auth: auth_de,
    manager: manager_de,
    support: support_de,
    player: player_de,
    category: category_de,
  }
}