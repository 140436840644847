import { withValidator } from '@/modules/global/hoc/with-validator';
import { useDebounce } from '@/modules/global/hooks/use-debounce';
import { FlexRow } from '@/modules/layout/components/grid/flex-row';
import { Gutter } from '@/modules/layout/components/gutter';
import { AllValidator, Button, RegexValidator, TextInputWithValidation, useIsValid } from '@nimey/react-ui';
import { Duration } from '@nimey/units';
import { FormEventHandler, useEffect, useMemo, useState } from 'react';

type SearchFormProps = {
  searchValue: string;
  setSearchValue: (v: string) => void;
}

export const SearchFormInner = ({searchValue, setSearchValue}: SearchFormProps) => {
  const [innerSearchValue, setInnerSearchValue] = useState<string>(searchValue);
  const debounced = useDebounce(innerSearchValue, Duration.millis(500));

  useEffect(() => {
    if(debounced !== searchValue) setSearchValue(debounced as string);
  }, [debounced, searchValue, setSearchValue])
  const searchValueValidator = useMemo(() => {
    return new AllValidator().withChildren(
      new RegexValidator(/.{2,}/).withMessage('min length 2')
    )
  }, [])

  const isValid = useIsValid('searchValue')

  const onSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    if(!isValid) return;
  }
  return (
    <form onSubmit={onSubmit}>
      <FlexRow style={{gap: '1em'}}>
        <TextInputWithValidation
          name='searchValue'
          placeholder='Suchen'
          value={innerSearchValue}
          onChange={(e) => setInnerSearchValue(String(e))}
          validator={searchValueValidator}
        />
        <Button type='submit' disabled={!isValid}>Suchen</Button>
      </FlexRow>
    </form>
  );
}

export const UserSearchForm = withValidator(SearchFormInner, 'search')