import { Client as PodcastClient } from '@nimey/podcast-client';
import { Duration } from '@nimey/units';

export class Client extends PodcastClient {
  private getQueue: Record<string, Promise<any> | undefined> = {};

  async get<T extends unknown>(apiPath: string, queryParams?: Record<string, string> | undefined): Promise<T> {
    const key: string = JSON.stringify({apiPath, queryParams: queryParams ? new URLSearchParams(queryParams).toString(): ''});
    if(!this.getQueue[key]) {
      this.getQueue[key] = new Promise((resolve, reject) => {
        super.get(apiPath, queryParams).then(resolve).catch(reject);
        setTimeout(() => {
          delete this.getQueue[key];
        }, +Duration.seconds(1))
      })
    }

    return this.getQueue[key];
  }

}