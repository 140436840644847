import { withMotion } from '../hoc/with-motion';
import { Gutter } from '@/modules/layout/components/gutter';
import { EpisodeList } from '@/modules/player/components/list/episode';
import { ListLayout } from '@/modules/player/components/list/podcast';
import { PodcastEpisodeListType } from '@nimey/podcast-global-entity';

const Wrapper = () => <Gutter><EpisodeList listType={PodcastEpisodeListType.RECENT} layout={ListLayout.LIST} title={'Alle Episoden'} /></Gutter>

const AnimatedPage = withMotion(Wrapper);

export const roles: string[] = [];
export const path = '/player/all-episodes';
export const element = <AnimatedPage />