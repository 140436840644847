import { withValidator } from '@/modules/global/hoc/with-validator';
import { Modal } from '@/modules/layout/components/modal'
import { BaseTicketCreatePayload, TicketType, isQuotaRequestTicket, isQuotaRequestTicketCreatePayload } from '@nimey/podcast-global-entity';
import { Button, RegexValidator, TextInputWithValidation } from '@nimey/react-ui';
import { FormEvent, useState } from 'react';
import { TicketTypeSelector } from './type-selector';
import { TicketTypeInput } from './ticket-type-input/base';
import { useCreateTicket } from '@/modules/backend/hooks/use-create-ticket';

export type CreateTicketModalProps = {
  isOpen: boolean;
  close: () => void;
}

const transformer = (formData: FormData): BaseTicketCreatePayload => {
  const result =  Object.fromEntries(formData.entries()) as unknown as BaseTicketCreatePayload;

  if(isQuotaRequestTicketCreatePayload(result)) {
    if(typeof result.requestValue === 'string') result.requestValue = parseInt(result.requestValue);
  }

  return result;
}


const CreateTicketModalInner = (props: CreateTicketModalProps) => {
  const [ticketType, setTicketType] = useState<TicketType | undefined>();

  const { create } = useCreateTicket();

  const onSubmitForm = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const ticketData = transformer(formData);
    create(ticketData).then(() => {
      alert('done')
    });
  }
  return (
      <Modal
        {...props}
        title='create ticket...'
      >
        <form onSubmit={onSubmitForm}>
          <TextInputWithValidation placeholder='Title' name='title' validator={new RegexValidator(/^.{5,}/)} />
            <TicketTypeSelector type={ticketType} setType={(v: TicketType) => setTicketType(v)} />
            <TicketTypeInput type={ticketType} />
            <div>
              <Button primary type='submit'>Weiter</Button>
            </div>
          </form>

      </Modal>
  )
}

export const CreateTicketModal = withValidator(CreateTicketModalInner, 'create-ticket');