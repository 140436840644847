import { FlexRow } from '@/modules/layout/components/grid/flex-row';
import { Button } from '@nimey/react-ui';
import { MdArrowBack } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

export const SupportFooter = () => {
  const navigate = useNavigate();
  return (
    <FlexRow style={{alignItems: 'center', height: '100%'}}>
      <div onClick={() => navigate(-1)} style={{paddingLeft: '.5em', fontSize: '1.5em'}}><MdArrowBack /></div>
    </FlexRow>
  );
}