import { useLocalPodcast } from './podcast-provider';
import Markdown from 'react-markdown'

import styles from './description.module.scss';
import { useState } from 'react';

type PodcastDescriptionProps = {
  maxLines?: number;
  onClickMore?: () => void
}

export const PodcastDescription = (props: PodcastDescriptionProps) => {
  const defaultMaxLines = props.maxLines || 4;
  const [ maxLines, setMaxLines ] = useState<number>(defaultMaxLines)
  const { podcast } = useLocalPodcast();

  return (
    <div onClick={() => {
      if (props.onClickMore) props.onClickMore();
      else setMaxLines(m => m === 1000 ? defaultMaxLines : 1000)
    }} style={{'--number-of-lines': maxLines}} className={`${styles['max-lines']} ${styles['markdown']}`}>
      <Markdown>{podcast?.description}</Markdown>
    </div>
  );
}