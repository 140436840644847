import { Textarea } from '@/modules/layout/components/form/textarea';
import { Hr } from '@/modules/layout/components/hr';
import { TextInput } from '@nimey/react-ui';

export const PodcastRequestInput = () => {
  return (
    <div>
      <p>Podcast Request</p>

      <div style={{padding: '.5em 0 '}}><TextInput placeholder='Feed Url' name='feedUrl'/></div>
      <div style={{padding: '.5em 0 '}}><TextInput placeholder='podcastTitle' name='podcastTitle'/></div>
      <div style={{padding: '.5em 0 '}}><TextInput placeholder='podcastWebsite' name='podcastWebsite'/></div>
      <div style={{padding: '.5em 0 '}}><TextInput placeholder='podcastSocial' name='podcastSocial'/></div>
      
      <Hr />

      <Textarea style={{minHeight: '2em'}} placeholder='reason' name='info' />
    </div>
  )
}