import { SignedTokenType } from '@nimey/podcast-global-entity';
import { useMemo } from 'react'

export interface TokenPayload {
  exp: number, type: SignedTokenType
}

export interface TokenClaimPodcastOwnershipPayload extends TokenPayload {
  orgId: string;
  ticketId: string;
  userId: string;
  podcastId: string;
}

export const useTokenPayload = (token: string): TokenPayload | undefined => {
  return useMemo(() => {
    const [header, body, sig] = token.split('.');
    if(!body) return undefined;

    const jsonString = atob(body);
    if(!jsonString) return undefined;

    try {
      const data = JSON.parse(jsonString);
      if(data.exp && data.exp * 1000 < Date.now()) return undefined;

      return data;
    } catch {}

    return undefined;

  }, [token])
}