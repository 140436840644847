import { useLocalEpisode } from '@/modules/global/components/podcast/episode/episode-provider';
import { PlayEpisode } from '@/modules/media-player/components/play-episode';
import { Fragment } from 'react/jsx-runtime';
import { AddToPlaylist } from './add-to-playlist';
import { CompleteEpisode } from '@/modules/media-player/components/complete-episode';

export const EpisodeActions = () => {
  const { episode } = useLocalEpisode();
  return (
    <Fragment>
      <PlayEpisode episodeId={episode?.id || ''} />
      {episode ? <AddToPlaylist episodeId={episode.id} /> : ''}
      <CompleteEpisode />
    </Fragment>
  );
}