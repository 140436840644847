export var PodcastCategory;
(function (PodcastCategory) {
    PodcastCategory["ARTS"] = "arts";
    PodcastCategory["ARTS_BOOKS"] = "arts-books";
    PodcastCategory["ARTS_DESIGN"] = "arts-design";
    PodcastCategory["ARTS_FASHION_BEAUTY"] = "arts-fashion_beauty";
    PodcastCategory["ARTS_FOOD"] = "arts-food";
    PodcastCategory["ARTS_PERFORMING_ARTS"] = "arts-performing_arts";
    PodcastCategory["ARTS_VISUAL_ARTS"] = "arts-visual_arts";
    PodcastCategory["BUSINESS"] = "business";
    PodcastCategory["BUSINESS_CAREERS"] = "business-careers";
    PodcastCategory["BUSINESS_ENTREPRENEURSHIP"] = "business-entrepreneurship";
    PodcastCategory["BUSINESS_INVESTING"] = "business-investing";
    PodcastCategory["BUSINESS_MANAGEMENT"] = "business-management";
    PodcastCategory["BUSINESS_MARKETING"] = "business-marketing";
    PodcastCategory["BUSINESS_NON_PROFIT"] = "business-non-profit";
    PodcastCategory["COMEDY"] = "comedy";
    PodcastCategory["COMEDY_COMEDY_INTERVIEWS"] = "comedy-comedy_interviews";
    PodcastCategory["COMEDY_IMPROV"] = "comedy-improv";
    PodcastCategory["COMEDY_STAND_UP"] = "comedy-stand-up";
    PodcastCategory["EDUCATION"] = "education";
    PodcastCategory["EDUCATION_COURSES"] = "education-courses";
    PodcastCategory["EDUCATION_HOW_TO"] = "education-how_to";
    PodcastCategory["EDUCATION_LANGUAGE_LEARNING"] = "education-language_learning";
    PodcastCategory["EDUCATION_SELF_IMPROVEMENT"] = "education-self-improvement";
    PodcastCategory["FICTION"] = "fiction";
    PodcastCategory["FICTION_COMEDY_FICTION"] = "fiction-comedy_fiction";
    PodcastCategory["FICTION_DRAMA"] = "fiction-drama";
    PodcastCategory["FICTION_SCIENCE_FICTION"] = "fiction-science_fiction";
    PodcastCategory["GOVERNMENT"] = "government";
    PodcastCategory["HISTORY"] = "history";
    PodcastCategory["HEALTH_FITNESS"] = "health_fitness";
    PodcastCategory["HEALTH_FITNESS_ALTERNATIVE_HEALTH"] = "health_fitness-alternative_health";
    PodcastCategory["HEALTH_FITNESS_FITNESS"] = "health_fitness-fitness";
    PodcastCategory["HEALTH_FITNESS_MEDICINE"] = "health_fitness-medicine";
    PodcastCategory["HEALTH_FITNESS_MENTAL_HEALTH"] = "health_fitness-mental_health";
    PodcastCategory["HEALTH_FITNESS_NUTRITION"] = "health_fitness-nutrition";
    PodcastCategory["HEALTH_FITNESS_SEXUALITY"] = "health_fitness-sexuality";
    PodcastCategory["KIDS_FAMILY"] = "kids_family";
    PodcastCategory["KIDS_FAMILY_EDUCATION_FOR_KIDS"] = "kids_family-education_for_kids";
    PodcastCategory["KIDS_FAMILY_PARENTING"] = "kids_family-parenting";
    PodcastCategory["KIDS_FAMILY_PETS_ANIMALS"] = "kids_family-pets_animals";
    PodcastCategory["KIDS_FAMILY_STORIES_FOR_KIDS"] = "kids_family-stories_for_kids";
    PodcastCategory["LEISURE"] = "leisure";
    PodcastCategory["LEISURE_ANIMATION_MANGA"] = "leisure-animation_manga";
    PodcastCategory["LEISURE_AUTOMOTIVE"] = "leisure-automotive";
    PodcastCategory["LEISURE_AVIATION"] = "leisure-aviation";
    PodcastCategory["LEISURE_CRAFTS"] = "leisure-crafts";
    PodcastCategory["LEISURE_GAMES"] = "leisure-games";
    PodcastCategory["LEISURE_HOBBIES"] = "leisure-hobbies";
    PodcastCategory["LEISURE_HOME_GARDEN"] = "leisure-home_garden";
    PodcastCategory["LEISURE_VIDEO_GAMES"] = "leisure-video_games";
    PodcastCategory["MUSIC"] = "music";
    PodcastCategory["MUSIC_MUSIC_COMMENTARY"] = "music-music_commentary";
    PodcastCategory["MUSIC_MUSIC_HISTORY"] = "music-music_history";
    PodcastCategory["MUSIC_MUSIC_INTERVIEWS"] = "music-music_interviews";
    PodcastCategory["NEWS"] = "news";
    PodcastCategory["NEWS_BUSINESS_NEWS"] = "news-business_news";
    PodcastCategory["NEWS_DAILY_NEWS"] = "news-daily_news";
    PodcastCategory["NEWS_ENTERTAINMENT_NEWS"] = "news-entertainment_news";
    PodcastCategory["NEWS_NEWS_COMMENTARY"] = "news-news_commentary";
    PodcastCategory["NEWS_POLITICS"] = "news-politics";
    PodcastCategory["NEWS_SPORTS_NEWS"] = "news-sports_news";
    PodcastCategory["NEWS_TECH_NEWS"] = "news-tech_news";
    PodcastCategory["RELIGION_SPIRITUALITY"] = "religion_spirituality";
    PodcastCategory["RELIGION_SPIRITUALITY_BUDDHISM"] = "religion_spirituality-buddhism";
    PodcastCategory["RELIGION_SPIRITUALITY_CHRISTIANITY"] = "religion_spirituality-christianity";
    PodcastCategory["RELIGION_SPIRITUALITY_HINDUISM"] = "religion_spirituality-hinduism";
    PodcastCategory["RELIGION_SPIRITUALITY_ISLAM"] = "religion_spirituality-islam";
    PodcastCategory["RELIGION_SPIRITUALITY_JUDAISM"] = "religion_spirituality-judaism";
    PodcastCategory["RELIGION_SPIRITUALITY_RELIGION"] = "religion_spirituality-religion";
    PodcastCategory["RELIGION_SPIRITUALITY_SPIRITUALITY"] = "religion_spirituality-spirituality";
    PodcastCategory["SCIENCE"] = "science";
    PodcastCategory["SCIENCE_ASTRONOMY"] = "science-astronomy";
    PodcastCategory["SCIENCE_CHEMISTRY"] = "science-chemistry";
    PodcastCategory["SCIENCE_EARTH_SCIENCES"] = "science-earth_sciences";
    PodcastCategory["SCIENCE_LIFE_SCIENCES"] = "science-life_sciences";
    PodcastCategory["SCIENCE_MATHEMATICS"] = "science-mathematics";
    PodcastCategory["SCIENCE_NATURAL_SCIENCES"] = "science-natural_sciences";
    PodcastCategory["SCIENCE_NATURE"] = "science-nature";
    PodcastCategory["SCIENCE_PHYSICS"] = "science-physics";
    PodcastCategory["SCIENCE_SOCIAL_SCIENCES"] = "science-social_sciences";
    PodcastCategory["SOCIETY_CULTURE"] = "society_culture";
    PodcastCategory["SOCIETY_CULTURE_DOCUMENTARY"] = "society_culture-documentary";
    PodcastCategory["SOCIETY_CULTURE_PERSONAL_JOURNALS"] = "society_culture-personal_journals";
    PodcastCategory["SOCIETY_CULTURE_PHILOSOPHY"] = "society_culture-philosophy";
    PodcastCategory["SOCIETY_CULTURE_PLACES_TRAVEL"] = "society_culture-places_travel";
    PodcastCategory["SOCIETY_CULTURE_RELATIONSHIPS"] = "society_culture-relationships";
    PodcastCategory["SPORTS"] = "sports";
    PodcastCategory["SPORTS_BASEBALL"] = "sports-baseball";
    PodcastCategory["SPORTS_BASKETBALL"] = "sports-basketball";
    PodcastCategory["SPORTS_CRICKET"] = "sports-cricket";
    PodcastCategory["SPORTS_FANTASY_SPORTS"] = "sports-fantasy_sports";
    PodcastCategory["SPORTS_FOOTBALL"] = "sports-football";
    PodcastCategory["SPORTS_GOLF"] = "sports-golf";
    PodcastCategory["SPORTS_HOCKEY"] = "sports-hockey";
    PodcastCategory["SPORTS_RUGBY"] = "sports-rugby";
    PodcastCategory["SPORTS_RUNNING"] = "sports-running";
    PodcastCategory["SPORTS_SOCCER"] = "sports-soccer";
    PodcastCategory["SPORTS_SWIMMING"] = "sports-swimming";
    PodcastCategory["SPORTS_TENNIS"] = "sports-tennis";
    PodcastCategory["SPORTS_VOLLEYBALL"] = "sports-volleyball";
    PodcastCategory["SPORTS_WILDERNESS"] = "sports-wilderness";
    PodcastCategory["SPORTS_WRESTLING"] = "sports-wrestling";
    PodcastCategory["TECHNOLOGY"] = "technology";
    PodcastCategory["TRUE_CRIME"] = "true_crime";
    PodcastCategory["TV_FILM"] = "tv_film";
    PodcastCategory["TV_FILM_AFTER_SHOWS"] = "tv_film-after_shows";
    PodcastCategory["TV_FILM_FILM_HISTORY"] = "tv_film-film_history";
    PodcastCategory["TV_FILM_FILM_INTERVIEWS"] = "tv_film-film_interviews";
    PodcastCategory["TV_FILM_FILM_REVIEWS"] = "tv_film-film_reviews";
    PodcastCategory["TV_FILM_TV_REVIEWS"] = "tv_film-tv_reviews";
})(PodcastCategory || (PodcastCategory = {}));
export const PodcastCategoryMap = {
    'Arts': PodcastCategory.ARTS,
    'Arts > Books': PodcastCategory.ARTS_BOOKS,
    'Arts > Design': PodcastCategory.ARTS_DESIGN,
    'Arts > Fashion & Beauty': PodcastCategory.ARTS_FASHION_BEAUTY,
    'Arts > Food': PodcastCategory.ARTS_FOOD,
    'Arts > Performing Arts': PodcastCategory.ARTS_PERFORMING_ARTS,
    'Arts > Visual Arts': PodcastCategory.ARTS_VISUAL_ARTS,
    'Business': PodcastCategory.BUSINESS,
    'Business > Careers': PodcastCategory.BUSINESS_CAREERS,
    'Business > Entrepreneurship': PodcastCategory.BUSINESS_ENTREPRENEURSHIP,
    'Business > Investing': PodcastCategory.BUSINESS_INVESTING,
    'Business > Management': PodcastCategory.BUSINESS_MANAGEMENT,
    'Business > Marketing': PodcastCategory.BUSINESS_MARKETING,
    'Business > Non-Profit': PodcastCategory.BUSINESS_NON_PROFIT,
    'Comedy': PodcastCategory.COMEDY,
    'Comedy > Comedy Interviews': PodcastCategory.COMEDY_COMEDY_INTERVIEWS,
    'Comedy > Improv': PodcastCategory.COMEDY_IMPROV,
    'Comedy > Stand-Up': PodcastCategory.COMEDY_STAND_UP,
    'Education': PodcastCategory.EDUCATION,
    'Education > Courses': PodcastCategory.EDUCATION_COURSES,
    'Education > How To': PodcastCategory.EDUCATION_HOW_TO,
    'Education > Language Learning': PodcastCategory.EDUCATION_LANGUAGE_LEARNING,
    'Education > Self-Improvement': PodcastCategory.EDUCATION_SELF_IMPROVEMENT,
    'Fiction': PodcastCategory.FICTION,
    'Fiction > Comedy Fiction': PodcastCategory.FICTION_COMEDY_FICTION,
    'Fiction > Drama': PodcastCategory.FICTION_DRAMA,
    'Fiction > Science Fiction': PodcastCategory.FICTION_SCIENCE_FICTION,
    'Government': PodcastCategory.GOVERNMENT,
    'History': PodcastCategory.HISTORY,
    'Health & Fitness': PodcastCategory.HEALTH_FITNESS,
    'Health & Fitness > Alternative Health': PodcastCategory.HEALTH_FITNESS_ALTERNATIVE_HEALTH,
    'Health & Fitness > Fitness': PodcastCategory.HEALTH_FITNESS_FITNESS,
    'Health & Fitness > Medicine': PodcastCategory.HEALTH_FITNESS_MEDICINE,
    'Health & Fitness > Mental Health': PodcastCategory.HEALTH_FITNESS_MENTAL_HEALTH,
    'Health & Fitness > Nutrition': PodcastCategory.HEALTH_FITNESS_NUTRITION,
    'Health & Fitness > Sexuality': PodcastCategory.HEALTH_FITNESS_SEXUALITY,
    'Kids & Family': PodcastCategory.KIDS_FAMILY,
    'Kids & Family > Education for Kids': PodcastCategory.KIDS_FAMILY_EDUCATION_FOR_KIDS,
    'Kids & Family > Parenting': PodcastCategory.KIDS_FAMILY_PARENTING,
    'Kids & Family > Pets & Animals': PodcastCategory.KIDS_FAMILY_PETS_ANIMALS,
    'Kids & Family > Stories for Kids': PodcastCategory.KIDS_FAMILY_STORIES_FOR_KIDS,
    'Leisure': PodcastCategory.LEISURE,
    'Leisure > Animation & Manga': PodcastCategory.LEISURE_ANIMATION_MANGA,
    'Leisure > Automotive': PodcastCategory.LEISURE_AUTOMOTIVE,
    'Leisure > Aviation': PodcastCategory.LEISURE_AVIATION,
    'Leisure > Crafts': PodcastCategory.LEISURE_CRAFTS,
    'Leisure > Games': PodcastCategory.LEISURE_GAMES,
    'Leisure > Hobbies': PodcastCategory.LEISURE_HOBBIES,
    'Leisure > Home & Garden': PodcastCategory.LEISURE_HOME_GARDEN,
    'Leisure > Video Games': PodcastCategory.LEISURE_VIDEO_GAMES,
    'Music': PodcastCategory.MUSIC,
    'Music > Music Commentary': PodcastCategory.MUSIC_MUSIC_COMMENTARY,
    'Music > Music History': PodcastCategory.MUSIC_MUSIC_HISTORY,
    'Music > Music Interviews': PodcastCategory.MUSIC_MUSIC_INTERVIEWS,
    'News': PodcastCategory.NEWS,
    'News > Business News': PodcastCategory.NEWS_BUSINESS_NEWS,
    'News > Daily News': PodcastCategory.NEWS_DAILY_NEWS,
    'News > Entertainment News': PodcastCategory.NEWS_ENTERTAINMENT_NEWS,
    'News > News Commentary': PodcastCategory.NEWS_NEWS_COMMENTARY,
    'News > Politics': PodcastCategory.NEWS_POLITICS,
    'News > Sports News': PodcastCategory.NEWS_SPORTS_NEWS,
    'News > Tech News': PodcastCategory.NEWS_TECH_NEWS,
    'Religion & Spirituality': PodcastCategory.RELIGION_SPIRITUALITY,
    'Religion & Spirituality > Buddhism': PodcastCategory.RELIGION_SPIRITUALITY_BUDDHISM,
    'Religion & Spirituality > Christianity': PodcastCategory.RELIGION_SPIRITUALITY_CHRISTIANITY,
    'Religion & Spirituality > Hinduism': PodcastCategory.RELIGION_SPIRITUALITY_HINDUISM,
    'Religion & Spirituality > Islam': PodcastCategory.RELIGION_SPIRITUALITY_ISLAM,
    'Religion & Spirituality > Judaism': PodcastCategory.RELIGION_SPIRITUALITY_JUDAISM,
    'Religion & Spirituality > Religion': PodcastCategory.RELIGION_SPIRITUALITY_RELIGION,
    'Religion & Spirituality > Spirituality': PodcastCategory.RELIGION_SPIRITUALITY_SPIRITUALITY,
    'Science': PodcastCategory.SCIENCE,
    'Science > Astronomy': PodcastCategory.SCIENCE_ASTRONOMY,
    'Science > Chemistry': PodcastCategory.SCIENCE_CHEMISTRY,
    'Science > Earth Sciences': PodcastCategory.SCIENCE_EARTH_SCIENCES,
    'Science > Life Sciences': PodcastCategory.SCIENCE_LIFE_SCIENCES,
    'Science > Mathematics': PodcastCategory.SCIENCE_MATHEMATICS,
    'Science > Natural Sciences': PodcastCategory.SCIENCE_NATURAL_SCIENCES,
    'Science > Nature': PodcastCategory.SCIENCE_NATURE,
    'Science > Physics': PodcastCategory.SCIENCE_PHYSICS,
    'Science > Social Sciences': PodcastCategory.SCIENCE_SOCIAL_SCIENCES,
    'Society & Culture': PodcastCategory.SOCIETY_CULTURE,
    'Society & Culture > Documentary': PodcastCategory.SOCIETY_CULTURE_DOCUMENTARY,
    'Society & Culture > Personal Journals': PodcastCategory.SOCIETY_CULTURE_PERSONAL_JOURNALS,
    'Society & Culture > Philosophy': PodcastCategory.SOCIETY_CULTURE_PHILOSOPHY,
    'Society & Culture > Places & Travel': PodcastCategory.SOCIETY_CULTURE_PLACES_TRAVEL,
    'Society & Culture > Relationships': PodcastCategory.SOCIETY_CULTURE_RELATIONSHIPS,
    'Sports': PodcastCategory.SPORTS,
    'Sports > Baseball': PodcastCategory.SPORTS_BASEBALL,
    'Sports > Basketball': PodcastCategory.SPORTS_BASKETBALL,
    'Sports > Cricket': PodcastCategory.SPORTS_CRICKET,
    'Sports > Fantasy Sports': PodcastCategory.SPORTS_FANTASY_SPORTS,
    'Sports > Football': PodcastCategory.SPORTS_FOOTBALL,
    'Sports > Golf': PodcastCategory.SPORTS_GOLF,
    'Sports > Hockey': PodcastCategory.SPORTS_HOCKEY,
    'Sports > Rugby': PodcastCategory.SPORTS_RUGBY,
    'Sports > Running': PodcastCategory.SPORTS_RUNNING,
    'Sports > Soccer': PodcastCategory.SPORTS_SOCCER,
    'Sports > Swimming': PodcastCategory.SPORTS_SWIMMING,
    'Sports > Tennis': PodcastCategory.SPORTS_TENNIS,
    'Sports > Volleyball': PodcastCategory.SPORTS_VOLLEYBALL,
    'Sports > Wilderness': PodcastCategory.SPORTS_WILDERNESS,
    'Sports > Wrestling': PodcastCategory.SPORTS_WRESTLING,
    'Technology': PodcastCategory.TECHNOLOGY,
    'True Crime': PodcastCategory.TRUE_CRIME,
    'TV & Film': PodcastCategory.TV_FILM,
    'TV & Film > After Shows': PodcastCategory.TV_FILM_AFTER_SHOWS,
    'TV & Film > Film History': PodcastCategory.TV_FILM_FILM_HISTORY,
    'TV & Film > Film Interviews': PodcastCategory.TV_FILM_FILM_INTERVIEWS,
    'TV & Film > Film Reviews': PodcastCategory.TV_FILM_FILM_REVIEWS,
    'TV & Film > TV Reviews': PodcastCategory.TV_FILM_TV_REVIEWS,
};
