import { useContext, useEffect } from 'react';
import { MediaPlayerStateContext } from '../context/media-player-context';
import { usePodcast } from '@/modules/backend/hooks/use-podcast';

export const useCurrentPodcast = () => {
  const { episode } = useContext(MediaPlayerStateContext);
  const {podcast, load} = usePodcast(episode?.podcastId)

  useEffect(() => { 
    if(episode && episode.podcastId) load();
    else return undefined;
  }, [episode, load]);

  return podcast;
}