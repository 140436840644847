import { useContext } from 'react';
import { createPortal } from 'react-dom';
import { Fragment } from 'react/jsx-runtime';
import { NotificationContext } from '../context/notification-context';
import { NotificationChannel } from '../types/notification';
import { FullScreenNotification } from './full-screen-notification';
import { SlimNotifications } from './slim-notifications';

export const NotificationRendererSlim = () => {
  return createPortal((<Fragment>
    <SlimNotifications />
  </Fragment>), document.getElementById('modal-portal-target')!)
}

export const NotificationRendererFullScreen = () => {
  const { notifications, setNotifications } = useContext(NotificationContext);
  const firstNotification = notifications.find(n => n.channel === NotificationChannel.FULL_SCREEN);

  if(!firstNotification) return <></>;

  const removeNotification = () => {
    setNotifications(ns => ns.filter(n => n.id !== firstNotification.id));
  }

  return createPortal((<Fragment>
    <FullScreenNotification {...{notification: firstNotification, close: removeNotification}} />
  </Fragment>), document.getElementById('modal-portal-target')!)
}

export const NotificationRenderer = () => {
  return (
    <Fragment>
      <NotificationRendererSlim />
      <NotificationRendererFullScreen />
    </Fragment>
  );
}