export var FetchInterval;
(function (FetchInterval) {
    FetchInterval["HOURLY"] = "hourly";
    FetchInterval["DAILY"] = "daily";
    FetchInterval["WEEKLY"] = "weekly";
    FetchInterval["NEVER"] = "never";
})(FetchInterval || (FetchInterval = {}));
export var CacheStrategy;
(function (CacheStrategy) {
    CacheStrategy["ETAG"] = "etag";
    CacheStrategy["LAST_MOD"] = "last_mod";
    CacheStrategy["NONE"] = "none";
    CacheStrategy["UNKNOWN"] = "unknown";
})(CacheStrategy || (CacheStrategy = {}));
