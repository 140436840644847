import { useCallback, useContext, useEffect } from 'react';
import { BackendStateContext } from '../context/backend-context';

export function useEntityList<T extends unknown>(filter: T, entityKey: string, loadCallback: (f: T, pageNumber: number) => Promise<void>, externalFilterKey?: string) {
  const state = useContext(BackendStateContext);
  const filterKey = externalFilterKey || JSON.stringify(filter);
  const entityList = (state.entityLists[entityKey] || {})[filterKey];

  const isPageLoaded = (pageNumber: number) => entityList?.listMeta.totalPages === 0 || entityList?.pages.find(p => p.pageNumber === pageNumber);
  const hasMore = !entityList || !isPageLoaded(entityList.listMeta.totalPages);

  const reload = useCallback(async() => {
    await Promise.all((entityList?.pages || []).map(({pageNumber}) => loadCallback(filter, pageNumber)));
  }, [entityList?.pages, filter, loadCallback])

  useEffect(() => {
    if(entityList?.invalidated === true) reload();
  }, [reload, entityList])

  return {
    meta: entityList?.listMeta,
    pages: entityList?.pages || [],
    allIds: (entityList?.pages || []).flatMap(p => p.ids) ,
    loadPage: async (pageNumber: number = 1, force: boolean = false) => {
      if(force || !isPageLoaded(pageNumber)) return loadCallback(filter, pageNumber);
    },
    hasMore,
    reload,
    loadMore: async () => {
      if(!hasMore) return;
      let pageNumber = 0;
      if(!entityList?.pages[0]) pageNumber = 1;
      else pageNumber = entityList.pages[entityList.pages.length - 1].pageNumber + 1;
      return loadCallback(filter, pageNumber);
    }
  };
}