import { ExampleNotification } from '../components/notification/example';
import { OnAccountChangeNotification } from '../components/notification/on-account-change';

export enum NotificationChannel {
  SLIM = 'slim',
  FULL_SCREEN = 'full_screen',
}

export type NotificationComponent = typeof OnAccountChangeNotification
  | typeof ExampleNotification
;

export type Notification = {
  id: string;
  channel: NotificationChannel;
  component: NotificationComponent;
}