import { BaseEntity, BaseTicketCreatePayload, EpisodePlaybackStateCreatePayload, FeatureFlag, OrganizationCreatePayload, Playlist, PlaylistCreatePayload, PlaylistFilter, PodcastAbo, PodcastEpisodeFilter, PodcastEpisodeListType, PodcastFilter, PodcastListType, QuotaName, QuotaRequestTicketCreatePayload, TicketFilter, TicketMessageCreatePayload } from '@nimey/podcast-global-entity';
import { BackendAction, BackendActionKind } from '../types/backend-action';
import { BackendState } from '../types/backend-state';
import { WrappedClientResponse } from '@nimey/podcast-client';

export interface UserRoles extends BaseEntity {
  roles: string[];
}
export interface TicketStats extends BaseEntity {
  stats: Record<string, {count: number}>;
}

export const getAsyncActions = (state: BackendState, dispatch: React.Dispatch<BackendAction | BackendAction[]>) => {
  const dispatchFlushTags =(response: WrappedClientResponse<unknown>): Array<BackendAction> => {
    if(!response.flushCacheTags || response.flushCacheTags.size <= 0) return [];
    return [
      {type: BackendActionKind.FLUSH_TAGS, payload: {tags: response.flushCacheTags}}
    ];
  }
  const loadOrganizationList = async(filter: {}, pageNumber: number = 1, pageSize: number = 6) => {
    const filterKey = JSON.stringify(filter);
    const entityList = await state.controller!.organization.list((pageNumber - 1) * pageSize, pageSize)

    if(!entityList) return;

    const meta = {
      totalCount: entityList.response.metaData.totalCount,
      totalPages: Math.ceil(entityList.response.metaData.totalCount / pageSize),
    }

    dispatch([
      ...dispatchFlushTags(entityList),
      ...(entityList.response.entities.map((entity): {type: BackendActionKind.ADD_ENTITY, payload: {type: string, entity: BaseEntity, tags?: Set<string>}} => ({
        type: BackendActionKind.ADD_ENTITY, payload: {
          type: 'organization',
          entity,
          tags: entityList.cacheTags
        }
      }))),
      {type: BackendActionKind.ADD_ENTITY_LIST, payload: { type: 'organization', filterKey, meta, tags: entityList.cacheTags}},
      {type: BackendActionKind.ADD_ENTITY_LIST_PAGE, payload: { type: 'organization', filterKey, pageNumber, ids: entityList.response.entities.map(e => e.id), tags: entityList.cacheTags }},
    ])
  }

  const loadTicketList = async(filter: TicketFilter,pageNumber: number = 1, pageSize: number = 6) => {
    const filterKey = JSON.stringify(filter);
    const entityList = await state.controller!.ticket.list(filter, (pageNumber - 1) * pageSize, pageSize)
    if(!entityList) return;

    const meta = {
      totalCount: entityList.response.metaData.totalCount || 0,
      totalPages: Math.ceil(entityList.response.metaData.totalCount / pageSize) || 0,
    }

    dispatch([
      ...dispatchFlushTags(entityList),
      ...(entityList.response.entities.map((entity): {type: BackendActionKind.ADD_ENTITY, payload: {type: string, entity: BaseEntity, tags?: Set<string>}} => ({
        type: BackendActionKind.ADD_ENTITY, payload: {
          type: 'ticket',
          entity,
          tags: entityList.cacheTags
        }
      }))),
      {type: BackendActionKind.ADD_ENTITY_LIST, payload: { type: 'ticket', filterKey, meta, tags: entityList.cacheTags}},
      {type: BackendActionKind.ADD_ENTITY_LIST_PAGE, payload: { type: 'ticket', filterKey, pageNumber, ids: entityList.response.entities.map(e => e.id), tags: entityList.cacheTags }},
    ])
  }

  const loadTicketMessageList = async(ticketId: string, pageNumber: number = 1, pageSize: number = 6) => {
    const filterKey = JSON.stringify(ticketId);
    const entityList = await state.controller!.ticket.getMessages(ticketId, (pageNumber - 1) * pageSize, pageSize)

    if(!entityList) return;

    const meta = {
      totalCount: entityList.response.metaData.totalCount || 0,
      totalPages: Math.ceil(entityList.response.metaData.totalCount / pageSize) || 0,
    }

    dispatch([
      ...dispatchFlushTags(entityList),
      ...(entityList.response.entities.map((entity): {type: BackendActionKind.ADD_ENTITY, payload: {type: string, entity: BaseEntity, tags?: Set<string>}} => ({
        type: BackendActionKind.ADD_ENTITY, payload: {
          type: 'ticket-message',
          entity,
          tags: entityList.cacheTags
        }
      }))),
      {type: BackendActionKind.ADD_ENTITY_LIST, payload: { type: 'ticket-message', filterKey, meta, tags: entityList.cacheTags}},
      {type: BackendActionKind.ADD_ENTITY_LIST_PAGE, payload: { type: 'ticket-message', filterKey, pageNumber, ids: entityList.response.entities.map(e => e.id), tags: entityList.cacheTags }},
    ])
  }

  const loadPodcastEpisodeList = async(filter: PodcastEpisodeFilter, listType: PodcastEpisodeListType, pageNumber: number = 1, pageSize: number = 6) => {
    const filterKey = JSON.stringify({...filter, listType});
    const entityList = await state.controller!.podcast.listEpisodes(filter, listType, (pageNumber - 1) * pageSize, pageSize)

    if(!entityList) return;

    const meta = {
      totalCount: entityList.response.metaData.totalCount || 0,
      totalPages: Math.ceil(entityList.response.metaData.totalCount / pageSize) || 0,
    }
    dispatch([
      ...dispatchFlushTags(entityList),
      ...(entityList.response.entities.map((entity): {type: BackendActionKind.ADD_ENTITY, payload: {type: string, entity: BaseEntity, tags?: Set<string>}} => ({
        type: BackendActionKind.ADD_ENTITY, payload: {
          type: 'podcast-episode',
          entity,
          tags: entityList.cacheTags
        }
      }))),
      {type: BackendActionKind.ADD_ENTITY_LIST, payload: { type: 'podcast-episode', filterKey, meta, tags: entityList.cacheTags}},
      {type: BackendActionKind.ADD_ENTITY_LIST_PAGE, payload: { type: 'podcast-episode', filterKey, pageNumber, ids: entityList.response.entities.map(e => e.id), tags: entityList.cacheTags }},
    ])
  }
  const loadPodcastList = async(filter: PodcastFilter, listType: PodcastListType, pageNumber: number = 1, pageSize: number = 6) => {
    const filterKey = JSON.stringify({...filter, listType});
    const entityList = await state.controller!.podcast.list(filter, listType, (pageNumber - 1) * pageSize, pageSize)

    if(!entityList) return;

    const meta = {
      totalCount: entityList.response.metaData.totalCount || 0,
      totalPages: Math.ceil(entityList.response.metaData.totalCount / pageSize) || 0,
    }

    dispatch([
      ...dispatchFlushTags(entityList),
      ...(entityList.response.entities.map((entity): {type: BackendActionKind.ADD_ENTITY, payload: {type: string, entity: BaseEntity, tags?: Set<string>}} => ({
        type: BackendActionKind.ADD_ENTITY, payload: {
          type: 'podcast',
          entity,
          tags: entityList.cacheTags
        }
      }))),
      {type: BackendActionKind.ADD_ENTITY_LIST, payload: { type: 'podcast', filterKey, meta, tags: entityList.cacheTags}},
      {type: BackendActionKind.ADD_ENTITY_LIST_PAGE, payload: { type: 'podcast', filterKey, pageNumber, ids: entityList.response.entities.map(e => e.id), tags: entityList.cacheTags }},
    ])
  }

  const searchPodcast = async(query: string, pageNumber: number = 1, pageSize: number = 6) => {
    const filterKey = JSON.stringify({listType: 'search', query});
    const entityList = await state.controller!.podcast.search(query, (pageNumber - 1) * pageSize, pageSize)

    if(!entityList) return;

    const meta = {
      totalCount: entityList.response.metaData.totalCount || 0,
      totalPages: Math.ceil(entityList.response.metaData.totalCount / pageSize) || 0,
    }

    dispatch([
      ...dispatchFlushTags(entityList),
      ...(entityList.response.entities.map((entity): {type: BackendActionKind.ADD_ENTITY, payload: {type: string, entity: BaseEntity, tags?: Set<string>}} => ({
        type: BackendActionKind.ADD_ENTITY, payload: {
          type: 'podcast-search',
          entity,
          tags: entityList.cacheTags
        }
      }))),
      {type: BackendActionKind.ADD_ENTITY_LIST, payload: { type: 'podcast-search', filterKey, meta, tags: entityList.cacheTags}},
      {type: BackendActionKind.ADD_ENTITY_LIST_PAGE, payload: { type: 'podcast-search', filterKey, pageNumber, ids: entityList.response.entities.map(e => e.id), tags: entityList.cacheTags }},
    ])
  }

  const searchUsers = async(query: string, pageNumber: number = 1, pageSize: number = 6) => {
    const filterKey = JSON.stringify({query});
    const entityList = await state.controller!.user.search(query, (pageNumber - 1) * pageSize, pageSize)

    if(!entityList) return;

    const meta = {
      totalCount: entityList.response.metaData.totalCount,
      totalPages: Math.ceil(entityList.response.metaData.totalCount / pageSize),
    }

    dispatch([
      ...dispatchFlushTags(entityList),
      ...(entityList.response.entities.map((entity): {type: BackendActionKind.ADD_ENTITY, payload: {type: string, entity: BaseEntity, tags?: Set<string>}} => ({
        type: BackendActionKind.ADD_ENTITY, payload: {
          type: 'user',
          entity,
          tags: entityList.cacheTags
        }
      }))),
      {type: BackendActionKind.ADD_ENTITY_LIST, payload: { type: 'user', filterKey, meta, tags: entityList.cacheTags}},
      {type: BackendActionKind.ADD_ENTITY_LIST_PAGE, payload: { type: 'user', filterKey, pageNumber, ids: entityList.response.entities.map(e => e.id), tags: entityList.cacheTags }},
    ])
  }

  const reloadOrganizationList = async () => {
    const filterKey = 'default';
    if(state.entityLists?.organization && state.entityLists?.organization[filterKey]) {
      const pages = state.entityLists.organization[filterKey].pages;
      await Promise.all(pages.map(page => loadOrganizationList(page.pageNumber)));
    }
  }

  const reloadTicketMessageList = async(ticketId: string) => {
    const filterKey = JSON.stringify(ticketId);
    if(state.entityLists && state.entityLists['ticket-message'] && state.entityLists['ticket-message'][filterKey]) {
      const pages = state.entityLists['ticket-message'][filterKey].pages;
      await Promise.all(pages.map(page => loadTicketMessageList(ticketId, page.pageNumber)));
    }
  }

  const loadTicketActions = async (ticketId: string) => {
    const result = await state.controller!.ticket.getActions(ticketId);
    if(!result) return;
    dispatch({
      ...dispatchFlushTags(result),
      type: BackendActionKind.ADD_ENTITY, payload: {type: 'ticket-actions', entity: result.response, tags: result.cacheTags}
    })
  }

  const loadTicket = async(id: string) => {
    const entity = await state.controller!.ticket.getById(id)
    if(!entity || !entity.response) {
      console.error('ticket not found', id);
      return;
    }
    dispatch([
      ...dispatchFlushTags(entity),
      {
        type: BackendActionKind.ADD_ENTITY, payload: {
        type: 'ticket',
        entity: entity.response,
        tags: entity.cacheTags,
      }}
    ])
  };

  const loadPodcast = async(id: string) => {
    const entity = await state.controller!.podcast.getById(id)
    if(!entity || !entity.response) {
      console.error('podcast not found', id);
      return;
    }
    dispatch([
      ...dispatchFlushTags(entity),
      {
        type: BackendActionKind.ADD_ENTITY, payload: {
        type: 'podcast',
        entity: entity.response,
        tags: entity.cacheTags,
      }}
    ])
  };

  const loadPodcastEpisode = async(id: string) => {
    const entity = await state.controller!.podcast.getEpisodeById(id)
    if(!entity || !entity.response) {
      console.error('episode not found', id);
      return;
    }
    dispatch([
      ...dispatchFlushTags(entity),
      {
        type: BackendActionKind.ADD_ENTITY, payload: {
        type: 'podcast-episode',
        entity: entity.response,
        tags: entity.cacheTags,
      }}
    ])
  };

  const deletePodcastEpisode = async(id: string) => {
    const entity = await state.controller!.podcast.deleteEpisodeById(id)
    if(!entity || !entity.response) {
      console.error('episode not found', id);
      return;
    }

    dispatch([
      ...dispatchFlushTags(entity),
    ])
  };

  const loadPodcastFeed = async(podcastId: string) => {
    const entity = await state.controller!.podcast.getFeedByPodcastId(podcastId)
    if(!entity || !entity.response) {
      console.error('feed not found', podcastId);
      return;
    }

    dispatch([
      ...dispatchFlushTags(entity),
      {
        type: BackendActionKind.ADD_ENTITY, payload: {
        type: 'podcast-feed',
        entity: {...entity.response, id: entity.response.podcastId},
        tags: entity.cacheTags,
      }}
    ])
  };

  const loadPodcastUserHisotry = async(userId: string, pageNumber: number = 1, pageSize: number = 6) => {
    const filterKey = JSON.stringify(userId);
    const entityList = await state.controller!.podcast.getUserHistory(userId, (pageNumber - 1) * pageSize, pageSize)

    if(!entityList) return;

    const meta = {
      totalCount: entityList.response.metaData.totalCount || 0,
      totalPages: Math.ceil(entityList.response.metaData.totalCount / pageSize) || 0,
    }

    dispatch([
      ...dispatchFlushTags(entityList),
      ...(entityList.response.entities.map((entity): {type: BackendActionKind.ADD_ENTITY, payload: {type: string, entity: BaseEntity, tags?: Set<string>}} => ({
        type: BackendActionKind.ADD_ENTITY, payload: {
          type: 'podcast-episode',
          entity,
          tags: entityList.cacheTags
        }
      }))),
      {type: BackendActionKind.ADD_ENTITY_LIST, payload: { type: 'podcast-episode', filterKey, meta, tags: entityList.cacheTags}},
      {type: BackendActionKind.ADD_ENTITY_LIST_PAGE, payload: { type: 'podcast-episode', filterKey, pageNumber, ids: entityList.response.entities.map(e => e.id), tags: entityList.cacheTags }},
    ])
  }

  const loadPlaylistList = async(filter: PlaylistFilter & { userId: string }, pageNumber: number = 1, pageSize: number = 6) => {
    const filterKey = JSON.stringify(filter);
    const entityList = await state.controller!.playlist.list(filter, (pageNumber - 1) * pageSize, pageSize)

    if(!entityList) return;

    const meta = {
      totalCount: entityList.response.metaData.totalCount || 0,
      totalPages: Math.ceil(entityList.response.metaData.totalCount / pageSize) || 0,
    }

    dispatch([
      ...dispatchFlushTags(entityList),
      ...(entityList.response.entities.map((entity): {type: BackendActionKind.ADD_ENTITY, payload: {type: string, entity: BaseEntity, tags?: Set<string>}} => ({
        type: BackendActionKind.ADD_ENTITY, payload: {
          type: 'playlist',
          entity,
          tags: entityList.cacheTags
        }
      }))),
      {type: BackendActionKind.ADD_ENTITY_LIST, payload: { type: 'playlist', filterKey, meta, tags: entityList.cacheTags}},
      {type: BackendActionKind.ADD_ENTITY_LIST_PAGE, payload: { type: 'playlist', filterKey, pageNumber, ids: entityList.response.entities.map(e => e.id), tags: entityList.cacheTags }},
    ])
  }

  const loadPlaylist = async(userId: string, listId: string) => {
    const entity = await state.controller!.playlist.getByUserAndId(userId, listId)
    if(!entity || !entity.response) {
      console.error('podcast not found', userId, listId);
      return;
    }
    dispatch([
      ...dispatchFlushTags(entity),
      {
        type: BackendActionKind.ADD_ENTITY, payload: {
        type: 'playlist',
        entity: entity.response ? ({
          ...entity.response,
          id: entity.response.userId,
          // @ts-ignore
          listId: entity.response.id,
        }) : entity.response,
        tags: entity.cacheTags,
      }}
    ])
  };

  const getPodcastAbo = async (podcastId: string, userId: string): Promise<PodcastAbo | undefined> => {
    const abo = await state.controller?.podcast.getAbo(podcastId, userId);

    dispatch([
      ...dispatchFlushTags(abo!),
      {
        type: BackendActionKind.ADD_ENTITY, payload: {
          type: 'abo',
          entity: {
            id: `${podcastId}-${userId}`,
            // @ts-ignore
            hasAbo: !!abo?.response,
          },
          tags: [],
        }
      }
    ])

    return abo!.response;
  }

  const addPodcastAbo = async (podcastId: string, userId: string): Promise<PodcastAbo> => {
    const abo = await state.controller?.podcast.addAbo(podcastId, userId);

    dispatch([
      ...dispatchFlushTags(abo!),
      {
        type: BackendActionKind.ADD_ENTITY, payload: {
          type: 'abo',
          entity: {
            id: `${podcastId}-${userId}`,
            // @ts-ignore
            hasAbo: true,
          },
          tags: [],
        }
      }
    ])

    return abo!.response;
  }
  
  const deletePodcastAbo = async (podcastId: string, userId: string): Promise<PodcastAbo> => {
    const abo = await state.controller?.podcast.deleteAbo(podcastId, userId);

    dispatch([
      ...dispatchFlushTags(abo!),
      {
        type: BackendActionKind.ADD_ENTITY, payload: {
          type: 'abo',
          entity: {
            id: `${podcastId}-${userId}`,
            // @ts-ignore
            hasAbo: false,
          },
          tags: [],
        }
      }
    ])

    return abo!.response;
  }

  const createPlaylist = async (userId: string, playlist: PlaylistCreatePayload) => {
    const newPlaylist = await state.controller!.playlist.create(userId, playlist);
    if(newPlaylist && newPlaylist.response) {
      dispatch([
        ...dispatchFlushTags(newPlaylist),
        {type: BackendActionKind.ADD_ENTITY, payload: { type: 'playlist', entity: newPlaylist.response, tags: newPlaylist.cacheTags }}
      ]);
    }
    
    return newPlaylist.response;
  };

  const updatePlaylist = async (userId: string, playlist: Playlist) => {
    const newPlaylist = await state.controller!.playlist.update(userId, playlist.id, playlist);
    if(newPlaylist && newPlaylist.response) {
      dispatch([
        ...dispatchFlushTags(newPlaylist),
        {type: BackendActionKind.ADD_ENTITY, payload: { type: 'playlist', entity: newPlaylist.response, tags: newPlaylist.cacheTags }}
      ]);
    }
    
    return newPlaylist.response;
  };

  const loadGlobalFeatureFlags = async() => {
    const entity = await state.controller!.feature.getGlobal()
    if(!entity || !entity.response) {
      console.error('global features not found');
      return;
    }
    dispatch([
      ...dispatchFlushTags(entity),
      {
        type: BackendActionKind.ADD_ENTITY, payload: {
        type: 'feature',
        entity: entity.response,
        tags: entity.cacheTags,
      }}
    ])
  };

  const loadUserFeatureFlags = async(userId: string) => {
    const entity = await state.controller!.feature.getByUserId(userId)
    if(!entity || !entity.response) {
      console.error('global features not found');
      return;
    }
    dispatch([
      ...dispatchFlushTags(entity),
      {
        type: BackendActionKind.ADD_ENTITY, payload: {
        type: 'feature',
        entity: entity.response,
        tags: entity.cacheTags,
      }}
    ])
  };

  const addGlobalFeature = async (flag: FeatureFlag) => {
    const response = await state.controller?.feature.addGlobal(flag);

    if(response) {
      dispatch([
        ...dispatchFlushTags(response),
      ])
    }    
  }

  const addUserFeature = async (flag: FeatureFlag, userId: string) => {
    const response = await state.controller?.feature.addUser(userId, flag);

    if(response) {
      dispatch([
        ...dispatchFlushTags(response),
      ])
    }    
  }

  const removeUserFeature = async (flag: FeatureFlag, userId: string) => {
    const response = await state.controller?.feature.removeUser(userId, flag);

    if(response) {
      dispatch([
        ...dispatchFlushTags(response),
      ])
    }    
  }

  const removeGlobalFeature = async (flag: FeatureFlag) => {
    const response = await state.controller?.feature.removeGlobal(flag);

    if(response) {
      dispatch([
        ...dispatchFlushTags(response),
      ])
    }    
  }

  const loadFeatureUserList = async(flag: FeatureFlag, pageNumber: number = 1, pageSize: number = 6) => {
    const filterKey = JSON.stringify(flag);
    const entityList = await state.controller!.feature.getUserByFlag(flag, (pageNumber - 1) * pageSize, pageSize)

    if(!entityList) return;

    const meta = {
      totalCount: entityList.response.metaData.totalCount,
      totalPages: Math.ceil(entityList.response.metaData.totalCount / pageSize),
    }

    dispatch([
      ...dispatchFlushTags(entityList),
      ...(entityList.response.entities.map((entity): {type: BackendActionKind.ADD_ENTITY, payload: {type: string, entity: BaseEntity, tags?: Set<string>}} => ({
        type: BackendActionKind.ADD_ENTITY, payload: {
          type: 'user',
          entity,
          tags: entityList.cacheTags
        }
      }))),
      {type: BackendActionKind.ADD_ENTITY_LIST, payload: { type: 'user', filterKey, meta, tags: entityList.cacheTags}},
      {type: BackendActionKind.ADD_ENTITY_LIST_PAGE, payload: { type: 'user', filterKey, pageNumber, ids: entityList.response.entities.map(e => e.id), tags: entityList.cacheTags }},
    ])
  }

  const updatePlaybackState = async (episodeId: string, playbackState: EpisodePlaybackStateCreatePayload) => {
    const result = await state.controller!.podcast.setPlaybackState(episodeId, playbackState);
    if(result.flushCacheTags && result.flushCacheTags.size > 0) {
      dispatch(dispatchFlushTags(result));
    }

    return result;
  }
  
  const processToken = async (token: string) => {
    const result = await state.controller!.token.processToken(token);
    if(result.flushCacheTags && result.flushCacheTags.size > 0) {
      dispatch(dispatchFlushTags(result));
    }
    return result;
  }

  return {
    initClient: async (options: {forceLogin?: boolean, email?: string} = {}) => {
    },
    loadGlobalFeatureFlags,
    loadFeatureUserList,
    loadPodcast,
    searchPodcast,
    loadPodcastEpisode,
    deletePodcastEpisode,
    loadPodcastUserHisotry,
    addGlobalFeature,
    removeGlobalFeature,
    addUserFeature,
    removeUserFeature,
    loadUserFeatureFlags,
    updatePlaybackState,
    loadPlaylistList,
    createPlaylist,
    updatePlaylist,
    loadPlaylist,
    getPodcastAbo,
    addPodcastAbo,
    deletePodcastAbo,
    loadUser: async(id: string) => {
      const entity = await state.controller!.user.getById(id)
      if(!entity || !entity.response) {
        console.error('user not found', id);
        return;
      }
      dispatch([
        ...dispatchFlushTags(entity),
        {type: BackendActionKind.ADD_ENTITY, payload: {
          type: 'user',
          entity: entity.response,
          tags: entity.cacheTags,
        }}
      ])
    },

    loadUserRoles: async(id: string) => {
      const entity = await state.controller!.user.getRoles(id)
      if(!entity || !entity.response) {
        console.error('user not found', id);
        return;
      }
      dispatch([
        ...dispatchFlushTags(entity),
        {type: BackendActionKind.ADD_ENTITY, payload: {
          type: 'user-roles',
          entity: {id, roles: entity.response} as UserRoles,
          tags: entity.cacheTags,
        }}
      ])
      
    },
    loadUserQuota: async(id: string, quotaName: QuotaName) => {
      try {
        const entity = await state.controller!.user.getQuota(quotaName, id)
        if(!entity || !entity.response) {
          console.error('user-quota not found', id);
          return;
        }
        dispatch([
          ...dispatchFlushTags(entity),
          {type: BackendActionKind.ADD_ENTITY, payload: {
            type: 'user-quota',
            entity: {...entity.response, id: `${id}-${quotaName}`},
            tags: entity.cacheTags,
          }}
        ])
      } catch(e) {
        console.log(e);
      }
    },
    loadOrganization: async(id: string) => {
      const entity = await state.controller!.organization.getById(id)
      if(!entity || !entity.response) {
        console.error('org not found', id);
        return;
      }
      dispatch([
        ...dispatchFlushTags(entity),
        {type: BackendActionKind.ADD_ENTITY, payload: {
          type: 'organization',
          entity: entity.response,
          tags: entity.cacheTags,
        }}
      ])
    },
    loadTicket,
    reloadOrganizationList,
    loadOrganizationList,
    createOrganization: async (org: OrganizationCreatePayload) => {
      const newOrg = await state.controller!.organization.create(org);
      dispatch([
        ...dispatchFlushTags(newOrg),
        {type: BackendActionKind.ADD_ENTITY, payload: { type: 'organization', entity: newOrg.response, tags: newOrg.cacheTags }}
      ]);

      await reloadOrganizationList();
  
      return newOrg.response;
    },

    createTicketMessage: async (message: TicketMessageCreatePayload) => {
      const newMessage = await state.controller!.ticket.addMessage(message);
      dispatch([
        ...dispatchFlushTags(newMessage),
        {type: BackendActionKind.ADD_ENTITY, payload: { type: 'ticket-message', entity: newMessage.response, tags: newMessage.cacheTags }}
      ]);
  
      return newMessage.response;
    },

    createTicket: async (ticket: BaseTicketCreatePayload) => {
      const newTicket = await state.controller!.ticket.create(ticket);
      dispatch([
        ...dispatchFlushTags(newTicket),
        {type: BackendActionKind.ADD_ENTITY, payload: { type: 'ticket', entity: newTicket.response, tags: newTicket.cacheTags }}
      ]);
  
      return newTicket.response;
    },

    loadTicketStats: async () => {
      const stats = await state.controller!.ticket.stats();
      if(!stats) return;
      dispatch([
        ...dispatchFlushTags(stats),
        {
          type: BackendActionKind.ADD_ENTITY, payload: {type: 'ticket-stats', entity: ({id: 'global', stats: stats.response} as TicketStats), tags: stats.cacheTags}
        }
      ])
    },
    loadTicketActions,
    executeTicketAction: async (ticketId: string, actionType: string, input: Record<string, string>) => {
      const response = await state.controller!.ticket.execAction(ticketId, actionType, input);
      dispatch([
        ...dispatchFlushTags(response)
      ]);
    },
    loadTicketList,
    loadTicketMessageList,
    reloadTicketMessageList,
    searchUsers,
    loadPodcastEpisodeList,
    loadPodcastList,
    loadPodcastFeed,
    processToken,
  }
}
