import { useHasGlobalFeature } from '@/modules/backend/hooks/use-has-global-feature';
import { Gutter } from '@/modules/layout/components/gutter';
import { FeatureFlag } from '@nimey/podcast-global-entity';
import { useTranslation } from 'react-i18next';
import Switch from 'react-switch';
import { Fragment, useEffect, useState } from 'react';
import { useAddGlobalFeature } from '@/modules/backend/hooks/use-add-global-feature';
import { Spinner } from '@/modules/layout/components/spinner';
import { useRemoveGlobalFeature } from '@/modules/backend/hooks/use-remove-global-feature';
import { Button} from '@nimey/react-ui';
import { MdAccountCircle } from 'react-icons/md';
import { FeatureDetailModal } from './feature-detail-modal';

import styles from './feature.module.scss';



const FlagInfo = (props: { flag: FeatureFlag }) => {
  const hasGlobalFlag = useHasGlobalFeature(props.flag)
  const {add, loading: addLoading} = useAddGlobalFeature(props.flag);
  const {remove, loading: removeLoading} = useRemoveGlobalFeature(props.flag);

  const [ showModal, setShowModal ] = useState<boolean>(false);
  const [ localState, setLocalState ] = useState<boolean>(hasGlobalFlag || false);
  useEffect(() => {setLocalState(hasGlobalFlag)}, [hasGlobalFlag]);

  const loading = hasGlobalFlag === undefined || addLoading || removeLoading;

  const onChange = (c: boolean) => {
    setLocalState(c);
    if(c === true) add().then(_ => setLocalState(c));
    else if(c === false) remove().then(_ => setLocalState(c));
  }

  return (
    <Fragment>
      <li className={`${styles['feature-list-entry']} ${styles.card}`}>
        <div className={styles['headline']}>
          <div>{props.flag}</div>
          <div>{loading ? <Spinner size='2em' /> : <Switch checked={localState} onChange={onChange} />}</div>
        </div>
        <div>
          <Button onClick={() => setShowModal(m => !m)}><MdAccountCircle /></Button>
        </div>
      </li>
      <FeatureDetailModal flag={props.flag} isOpen={showModal} close={() => setShowModal(false)} />
    </Fragment>
  );
}

export const FeaturePage = () => {
  const { t } = useTranslation('support')
  return (
    <Gutter>
      <h1>{t('feature.manager.title')}</h1>
      <ul className={styles['feature-list']}>
        {Object.values(FeatureFlag).map(flag => (
          <FlagInfo key={flag} {...{flag}} />
        ))}
      </ul>
    </Gutter>
  );  
}