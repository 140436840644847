import { withCurrentUserId } from '@/modules/backend/hoc/with-current-userid';
import { OrganizationList } from '@/modules/global/components/organization/list';
import { Gutter } from '@/modules/layout/components/gutter';
import { ManagerDescription } from '@/translations/content';
import { LoginRequired } from './login-required';

export const ManagerHomeWithUser = () => {
  return (
    <div>
      <Gutter><ManagerDescription /></Gutter>
      <OrganizationList />
    </div>
  );
}

export const ManagerHome = withCurrentUserId(ManagerHomeWithUser, LoginRequired)