import { useContext } from 'react';
import { BackendDispatchContext, BackendStateContext } from '../context/backend-context';
import { BaseTicket } from '@nimey/podcast-global-entity';

export const useTicket = (id?: string) => {
  const { asyncActions } = useContext(BackendDispatchContext)
  const state = useContext(BackendStateContext);
  const entity = id && state.entities['ticket'] ? state.entities['ticket'][id] || undefined : undefined;
  const isLoaded = !!entity;
  return {
    ticket: entity?.entity as BaseTicket | undefined,
    isLoaded: !!entity,
    load: async (force: boolean = false) => id && (!isLoaded || force) && asyncActions!.loadTicket(id)
  };
}