import { useCreateTicket } from '@/modules/backend/hooks/use-create-ticket';
import { usePodcastFeed } from '@/modules/backend/hooks/use-podcast-feed';
import { usePodcast } from '@/modules/backend/hooks/use-podcast';
import { useLocalPodcast } from '@/modules/global/components/podcast/podcast-provider';
import { Spinner } from '@/modules/layout/components/spinner';
import { PodcastRefetchTicketCreatePayload, TicketType } from '@nimey/podcast-global-entity';
import { Button } from '@nimey/react-ui';

export const RequestPodcastRefetch = () => {
  const { podcast } = useLocalPodcast();
  const podcastObject = usePodcast(podcast?.id);
  const feedObject = usePodcastFeed(podcast?.id);
  const createTicketObj = useCreateTicket();
  if(!podcast) return <></>;

  if(createTicketObj.loading) return (
    <div><Spinner size='1em' /></div>
  );

  return (
    <div>
      <p>{createTicketObj.lastError ? createTicketObj.lastError.toString() : ''}</p>
      <Button
        onClick={() => {
          const ticketData: PodcastRefetchTicketCreatePayload = {
            podcastId: podcast?.id,
            title: 'Podcast aktualisieren',
            type: TicketType.PODCAST_REFETCH,
          }
          createTicketObj.create(ticketData).then(() => {
            podcastObject.load(true);
            feedObject.load(true);
          });
        }}
      >refetch now</Button>
    </div>
  );
}