import { DateDuration } from '../../date-duration';
import { useLocalEpisode } from './episode-provider';

type EpisodePubDateProps = {
}

export const EpisodePubDate = (props: EpisodePubDateProps) => {
  const { episode } = useLocalEpisode();

  return (
    <DateDuration fromTimestamp={episode?.pubDate} />
  );
}