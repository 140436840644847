import React from 'react';
import { BackendState } from '../types/backend-state';
import { BackendAction } from '../types/backend-action';
import { getAsyncActions } from '../reducer/backend-async-actions';


export const initialState: BackendState = {
  currentPlaylistId: undefined,
  entities: {},
  entityLists: {}
}

type BackendDispatchContextType = {dispatch: React.Dispatch<BackendAction>, asyncActions?: ReturnType<typeof getAsyncActions>};

export const BackendStateContext = React.createContext<BackendState>(initialState);
export const BackendDispatchContext = React.createContext<BackendDispatchContextType>({dispatch: () => {}});