import { useFeatureUserList } from '@/modules/backend/hooks/use-feature-user-list';
import { LoadMore } from '@/modules/layout/components/load-more';
import { Modal } from '@/modules/layout/components/modal';
import { FeatureFlag } from '@nimey/podcast-global-entity';
import { UserSearchForm } from '../user-board/user-search-form';
import { useEffect, useState } from 'react';
import { UserSearchResult } from '../user-board/user-search-result';
import { useUser } from '@/modules/backend/hooks/use-user';

import styles from './feature.module.scss';
import { FlexRow } from '@/modules/layout/components/grid/flex-row';
import { Button } from '@nimey/react-ui';
import { MdAdd, MdRemove } from 'react-icons/md';
import { useAddUserFeature } from '@/modules/backend/hooks/use-add-user-feature';
import { Spinner } from '@/modules/layout/components/spinner';
import { Hr } from '@/modules/layout/components/hr';
import { useRemoveUserFeature } from '@/modules/backend/hooks/use-remove-user-feature';

type FeatureDetailModalProps = {
  isOpen: boolean;
  close: () => void;
  flag: FeatureFlag
}

type FeatureUserProps = {
  flag: FeatureFlag;
  userId: string;
}
const FeatureUser = (props: FeatureUserProps) => {
  const {user, load} = useUser(props.userId)
  const { remove, loading } = useRemoveUserFeature(props.flag, props.userId);
  useEffect(() => {load()}, [props.userId]);

  if(!user || loading) return <li className={`${styles.card} ${styles['remove-user-card']}`}><Spinner size='1em' /></li>

  return (
    <li className={`${styles.card} ${styles['add-user-card']}`}>
      <FlexRow style={{alignItems: 'center', justifyContent: 'space-between'}}>
        <div>{user.email} ({user.name})</div>
        <div><Button onClick={() => remove()}><MdRemove /></Button></div>
      </FlexRow>
    </li>
  );
}

type AddUserProps = {
  flag: FeatureFlag
}

const SearchUser = (props: {userId: string, flag: FeatureFlag}) => {
  const {user, load} = useUser(props.userId)

  const { add, loading } = useAddUserFeature(props.flag, props.userId);

  useEffect(() => {load()}, [props.userId]);

  if(!user || loading) return <li className={`${styles.card} ${styles['add-user-card']}`}><Spinner size='1em' /></li>

  return (
    <li className={`${styles.card} ${styles['add-user-card']}`}>
      <FlexRow style={{alignItems: 'center', justifyContent: 'space-between'}}>
        <div>{user.email} ({user.name})</div>
        <div><Button onClick={() => add()} primary><MdAdd /></Button></div>
      </FlexRow>
    </li>
  );
}

const withFlag = (flag: FeatureFlag, comp: typeof SearchUser) => {
  return (props: {userId: string}) => {
    return (<SearchUser {...props} flag={flag} /> )
  }
}

const AddUser = (props: AddUserProps) => {
  const [ searchValue, setSearchValue ] = useState<string>('');
  return (
    <div>
      <UserSearchForm {...{searchValue, setSearchValue}} />
      {searchValue ? <UserSearchResult query={searchValue} userRenderer={withFlag(props.flag, SearchUser)} /> : ''}
    </div>
  )
}


export const FeatureDetailModal = (props: FeatureDetailModalProps) => {
  const {allIds, hasMore, loadMore} = useFeatureUserList(props.flag);
  return (
    <Modal
      isOpen={props.isOpen}
      close={props.close}
      title={props.flag}
    >
      <ul className={styles['user-list']}>
        {allIds.map(userId => <FeatureUser key={userId} flag={props.flag} userId={userId} />)}
        <LoadMore {...{hasMore, loadMore}} />
      </ul>
      <Hr />
      <p><strong>Add User</strong></p>
      <AddUser flag={props.flag} />

    </Modal>
  );
}