import { Dispatch, useCallback, useEffect } from 'react'
import { useCurrentPlaylist } from '@/modules/backend/hooks/use-current-playlist';
import { MediaPlayerAction, MediaPlayerActionKind } from '../types/media-player-action';
import { useRemoveFromPlaylist } from '@/modules/backend/hooks/use-remove-from-playlist';
import { useCurrentAccount } from '@/modules/auth/hooks/use-current-account';

export const useAutoplay = (audioRef?: HTMLAudioElement | null, episodeId?: string, autoplay?: boolean, dispatch?: Dispatch<MediaPlayerAction | MediaPlayerAction[]>) => {
  const { playlist } = useCurrentPlaylist();
  const acc = useCurrentAccount();
  const removeFromPlaylist = useRemoveFromPlaylist(acc?.id!, playlist?.id!);

  const onEnd = useCallback(() => {
    const getNextEpisodeId = () => {
      if(!autoplay) return;
      if(!playlist) return;
      if(!episodeId) return;
      if(playlist.episodeIds.length <= 0) return;
      if(playlist.pauseAfter === episodeId) return;
      
      if(playlist.episodeIds[0] === episodeId && playlist.episodeIds[1]) return playlist.episodeIds[1];
      if(playlist.episodeIds[0] === episodeId && !playlist.episodeIds[1]) return;

      return playlist.episodeIds[0];
    } 
    const nextEpisodeId = getNextEpisodeId();

    if(dispatch && nextEpisodeId && nextEpisodeId !== episodeId) {
      if(episodeId && playlist?.episodeIds.includes(episodeId)) {
        removeFromPlaylist(episodeId)
      }
      dispatch({type: MediaPlayerActionKind.SET_EPISODE_ID, payload: nextEpisodeId});
    }
  }, [dispatch, episodeId, playlist, autoplay,removeFromPlaylist])


  useEffect(() => {
    if(audioRef) {
      audioRef.addEventListener('ended', onEnd);
    }

    return () => {
      if(audioRef) {
        audioRef.removeEventListener('ended', onEnd);
      }
    }
  }, [audioRef, onEnd])
}