import { Gutter } from '@/modules/layout/components/gutter';
import { MyTickets } from './my-tickets/list';
import { Hr } from '@/modules/layout/components/hr';
import { CreateTicket } from '@/modules/global/components/ticket/create-ticket/trigger';

export const HelpCenterHome = () => {
  return (
    <Gutter>
      <h1>Help Center</h1>
      <Hr />
      <CreateTicket />
      <Hr />
      <MyTickets />
    </Gutter>
  );
}