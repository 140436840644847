import { useCallback, useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export const useHashStateFlag = (key: string): [boolean, (v: boolean) => void] => {
  const location = useLocation()
  const navigate = useNavigate()

  const isSet = location.hash === `#${key}`;

  const setFlag = (v: boolean) => {
    if(isSet === v) return;

    if(v) navigate(`#${key}`);
    else navigate('#');
  }

  return [isSet, setFlag]
}

export const useHashStateFlagRegex = (key: RegExp): {state: boolean, matches: RegExpMatchArray | null, unset: () => void} => {
  const location = useLocation()
  const navigate = useNavigate()

  const result =  useMemo(() => {
    const matches = location.hash.match(key);
    return {
      matches,
      state: !!matches
    }
  }, [location.hash])

  const unset = useCallback(() => {
    navigate('#')
  }, [navigate])

  return {
    ...result,
    unset
  }
}