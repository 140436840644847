import { getAsyncActions } from '@/modules/auth/reducer/auth-async-actions';
import { IAuthenticated } from '@nimey/podcast-client';

export class Authenticated implements IAuthenticated {


  constructor(protected authAsyncActions: ReturnType<typeof getAsyncActions>) {}

  setAsyncActions(asyncActions: ReturnType<typeof getAsyncActions>) {
    this.authAsyncActions = asyncActions;
  }

  async getAccessToken(): Promise<{ access_token: string; token_type: string; }> {
    try {
      const tokenSet = await this.authAsyncActions.getCurrentTokenSet();
      return {
        access_token: tokenSet.access_token.toString(),
        token_type: tokenSet.token_type,
      }
    } catch(e) {
      console.log(e);
    }

    return Promise.resolve({access_token: '', token_type: ''});
  }

}