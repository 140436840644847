import { useState, useCallback, useEffect, useContext, useMemo } from 'react';
import { BackendDispatchContext, BackendStateContext } from '../context/backend-context';

export const usePodcastAbo = (podcastId: string, userId: string) => {
  const { asyncActions } = useContext(BackendDispatchContext)
  const state = useContext(BackendStateContext)
  const [loadingState, setLoading] = useState<boolean>(false);

  const { hasAbo, loading } = useMemo(() => {
    let entity;
    if(state.entities.abo && state.entities.abo[`${podcastId}-${userId}`]) entity = state.entities.abo[`${podcastId}-${userId}`].entity as {id: string, hasAbo: boolean};
    const loading = entity === undefined || loadingState;
    const hasAbo = entity && entity.hasAbo

    return { loading, hasAbo };
  }, [state, loadingState])

  const reload = useCallback(() => {
    if(podcastId && userId) asyncActions!.getPodcastAbo(podcastId, userId)
  }, [podcastId, userId])

  const add = useCallback(() => {
    setLoading(true);
    if(podcastId && userId) asyncActions!.addPodcastAbo(podcastId, userId).then(() => setLoading(false))
  }, [podcastId, userId]);

  const remove = useCallback(() => {
    setLoading(true);
    if(podcastId && userId) asyncActions!.deletePodcastAbo(podcastId, userId).then(() => setLoading(false))
  }, [podcastId, userId]);

  useEffect(() => reload(), [ reload ])

  return {
    hasAbo,
    loading,
    add,
    remove,
    reload,
  }
}