import { RefObject } from 'react';
import { MediaPlayerAction, MediaPlayerActionKind } from '../types/media-player-action';
import { MediaPlayerState } from '../types/media-player-state';

export const getAsyncActions = (state: MediaPlayerState, dispatch: React.Dispatch<MediaPlayerAction | Array<MediaPlayerAction>>, audioRef: RefObject<HTMLAudioElement>) => {
  return {
    play: () => {
      if(audioRef.current!.readyState === 0) {
        audioRef.current!.load();
      }
      audioRef.current!.play();
    },
    pause: () => audioRef.current!.pause(),
    setPos: (pos: number) => audioRef.current!.currentTime = pos,
  };
}