import { useDeletePodcastEpisode } from '@/modules/backend/hooks/use-delete-podcast-episode';
import { usePodcast } from '@/modules/backend/hooks/use-podcast';
import { usePodcastEpisodeList } from '@/modules/backend/hooks/use-podcast-episode-list';
import { usePodcastFeed } from '@/modules/backend/hooks/use-podcast-feed';
import { EpisodeDescription } from '@/modules/global/components/podcast/episode/description';
import { EpisodeProvider, useLocalEpisode } from '@/modules/global/components/podcast/episode/episode-provider';
import { EpisodeTitle } from '@/modules/global/components/podcast/episode/title';
import { PodcastProvider } from '@/modules/global/components/podcast/podcast-provider';
import { FlexRow } from '@/modules/layout/components/grid/flex-row';
import { Gutter } from '@/modules/layout/components/gutter';
import { Hr } from '@/modules/layout/components/hr';
import { LoadMore } from '@/modules/layout/components/load-more';
import { Spinner } from '@/modules/layout/components/spinner';
import { RequestPodcastRefetch } from '@/modules/manager/components/org/request-podcast-refetch';
import { Podcast, PodcastEpisodeListType } from '@nimey/podcast-global-entity';
import { Fragment, useEffect, useState } from 'react';
import { MdDelete, MdRefresh } from 'react-icons/md';
import { useParams } from 'react-router-dom';
import styles from './page.module.scss'
import { useTranslation } from 'react-i18next';
import Markdown from 'react-markdown';
import { EpisodePubDate } from '@/modules/global/components/podcast/episode/pub-date';
import { Button } from '@nimey/react-ui';

const DeleteAllEpisodes = (props: {podcastId: string}) => {
  const [ isDeleting, setIsDeleting ] = useState<boolean>(false);
  const { allIds } = usePodcastEpisodeList({podcastId: props.podcastId}, PodcastEpisodeListType.RECENT);
  const deleteEpisode = useDeletePodcastEpisode();
  const deleteAll = async () => {
    setIsDeleting(true);
    const ids = new Set(allIds);
    for(const id of Array.from(ids)) {
      await deleteEpisode(id);
    }
    setIsDeleting(false)
  }
  return <button disabled={isDeleting} onClick={deleteAll}>{isDeleting ? <Spinner size='1em' /> : 'DELETE ALL'}</button>
}

const DeleteEpisode = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const { episode } = useLocalEpisode();
  const deleteEpisode = useDeletePodcastEpisode()

  return (
    <Button disabled={!episode && loading} onClick={() => {
        setLoading(true);
        deleteEpisode(episode!.id).then(() => setLoading(false))
      }}>{loading ? <Spinner size='1em' /> : <MdDelete />}</Button>
  )
}

const EpisodeList = (props: {podcast: Podcast}) => {
  const { allIds, hasMore, loadMore, meta } = usePodcastEpisodeList({podcastId: props.podcast.id}, PodcastEpisodeListType.RECENT);

  return (
    <div>
      <h2>{meta?.totalCount} Episoden</h2>
      <DeleteAllEpisodes podcastId={props.podcast?.id} />
      <ul>
        {(allIds || []).map(id => (
          <li key={id}>
            <EpisodeProvider episodeId={id}>
              <FlexRow>
                <EpisodeTitle />
                <small style={{whiteSpace: 'nowrap'}}><EpisodePubDate /></small>
              </FlexRow>
              <div><DeleteEpisode /></div>
              <div><EpisodeDescription maxLines={20} /></div>
            </EpisodeProvider>
          </li>
        ))}
      </ul>
      <LoadMore {...{hasMore, loadMore}} />
    </div>
  );
}

const DateFormat = ({timestamp}: {timestamp: number}) => {
  const date = new Date(timestamp);
  return <>{date.toLocaleString()}</>
}


export const PodcastDetailPage = () => {
  const { podcastId } = useParams();
  const { podcast, load } = usePodcast(podcastId);
  const { feed, load: loadFeed } = usePodcastFeed(podcastId);
  useEffect(() => { load() }, [ load ])
  useEffect(() => { loadFeed() }, [ loadFeed ])
  const transCategory = useTranslation('category');

  if(!podcastId || !podcast) return <Gutter><center><Spinner size='3em' /></center></Gutter>

  return (
    <Gutter>
      <h1>{podcast?.title}</h1>
      <Hr />
      <pre>{JSON.stringify(feed, null, 2)}</pre>
      <button onClick={() => loadFeed(true)}><MdRefresh /></button>
      <Hr />
      <table className={styles['pod-table']}>
        <tr>
          <th>Author</th>
          <td>{podcast.author}</td>
        </tr>
        <tr>
          <th>Besitzer</th>
          <td>{podcast.owner?.name} / {podcast.owner?.email}</td>
        </tr>
        <tr>
          <th>Kategorien</th>
          <td>{podcast.categories.map(c => transCategory.t(c)).join(', ')}</td>
        </tr>
        <tr>
          <th>Link</th>
          <td className={styles.url}>{podcast.link}</td>
        </tr>
        <tr>
          <th>Sprachen</th>
          <td>{Array.isArray(podcast.languages) ? podcast.languages.join(', ') : '---'}</td>
        </tr>
        <tr>
          <th>Feed-URL</th>
          <td className={styles.url}>{podcast.feedUrl}</td>
        </tr>
        <tr>
          <td colSpan={2}>
            <Markdown>{podcast.description}</Markdown>
          </td>
        </tr>

        {feed ? (
          <Fragment>
            <tr>
              <th colSpan={2}>Fetch Info</th>
            </tr>
            <tr>
              <th>Cache Strategie</th>
              <td>{feed.cacheStrategy}{feed.cacheValue ? ` / ${feed.cacheValue}` : ''}</td>
            </tr>
            <tr>
              <th>Fetch Interval</th>
              <td>{feed.fetchInterval}{feed.lastFetch ? (
                <>{' / '}<DateFormat timestamp={feed.lastFetch} /></>
              ) : '' }</td>
            </tr>
          </Fragment>
        ) : ''}

      </table>
      {podcast ? (
        <PodcastProvider podcastId={podcast.id}><RequestPodcastRefetch /></PodcastProvider>
      ) : ''}
      {podcast ? <EpisodeList {...{podcast}} /> : ''}
    </Gutter>
  )
}