import { ARTWORK_URL } from '@/modules/backend/config/artwork';
import { ImageSize, Podcast, PodcastEpisode } from '@nimey/podcast-global-entity';
import { Duration } from '@nimey/units';

const getArtwork = (episode?: PodcastEpisode) => {
  if(!episode) return [];

  return [
    { src: `${ARTWORK_URL}/e/${episode.id}/${ImageSize.IOS_96}/---/artwork.jpg`, sizes: '96x96', type: 'image/jpg'},
    { src: `${ARTWORK_URL}/e/${episode.id}/${ImageSize.IOS_128}/---/artwork.jpg`, sizes: '128x128', type: 'image/jpg'},
    { src: `${ARTWORK_URL}/e/${episode.id}/${ImageSize.IOS_192}/---/artwork.jpg`, sizes: '192x192', type: 'image/jpg'},
    { src: `${ARTWORK_URL}/e/${episode.id}/${ImageSize.IOS_256}/---/artwork.jpg`, sizes: '256x256', type: 'image/jpg'},
    { src: `${ARTWORK_URL}/e/${episode.id}/${ImageSize.IOS_384}/---/artwork.jpg`, sizes: '384x384', type: 'image/jpg'},
    { src: `${ARTWORK_URL}/e/${episode.id}/${ImageSize.IOS_512}/---/artwork.jpg`, sizes: '512x512', type: 'image/jpg'},
    { src: `${ARTWORK_URL}/e/${episode.id}/${ImageSize.LARGE}/---/artwork.jpg`, sizes: '1920x1920', type: 'image/jpg' },
  ]
}

export const setMediaSession = async (audioElement: HTMLAudioElement, episode?: PodcastEpisode, podcast?: Podcast) => {
  if ("mediaSession" in navigator) {
    navigator.mediaSession.metadata = new MediaMetadata({
      title: episode?.title,
      artist: podcast?.author,
      album: podcast?.title,
      artwork: getArtwork(episode)
    });

    const actions: [MediaSessionAction, MediaSessionActionHandler][] = [
      ['play', () => audioElement.play()],
      ['pause', () => audioElement.pause()],
      ['seekbackward', (event) => {
        const skipTime = event.seekOffset || Duration.seconds(15).toSeconds();
        audioElement.currentTime = Math.max(audioElement.currentTime - skipTime, 0);
      }],
      ['seekforward', (event) => {
        const skipTime = event.seekOffset || Duration.seconds(15).toSeconds();
        audioElement.currentTime = Math.min(audioElement.currentTime + skipTime, audioElement.duration);
      }],
      ['seekto', (event) => {
        if(typeof event.seekTime === 'undefined') return;
        if (event.fastSeek && ('fastSeek' in audioElement)) {
          audioElement.fastSeek(event.seekTime);
          return;
        }
        audioElement.currentTime = event.seekTime;
      }],
    ]

    audioElement.addEventListener('play', () => {
      navigator.mediaSession.playbackState = 'playing';
    });
    
    audioElement.addEventListener('pause', () => {
      navigator.mediaSession.playbackState = 'paused';
    });

    audioElement.addEventListener('timeupdate', () => {
      if ('setPositionState' in navigator.mediaSession) {
        if(!audioElement.duration) return;
        navigator.mediaSession.setPositionState({
          duration: audioElement.duration,
          playbackRate: audioElement.playbackRate,
          position: audioElement.currentTime
        });
      }
    })

    for(const [actionType, actionHandler] of actions) {
      try {
        navigator.mediaSession.setActionHandler(actionType, actionHandler);
      } catch (error) {
        console.log(`The media session action "${actionType}" is not supported yet.`);
      }
    }
  }
}