import { BaseController } from './base.controller.js';
export class OrganizationController extends BaseController {
    client;
    constructor(client) {
        super();
        this.client = client;
    }
    list(skip = 0, limit = 5) {
        return this.client.get('/org', { skip: skip.toString(), limit: limit.toString() });
    }
    getById(id) {
        return this.client.get(`/org/${id}`);
    }
    create(org) {
        return this.client.post('/org', org);
    }
    deleteById(id) {
        return this.client.delete(`/org/${id}`);
    }
}
