import { useCallback, useContext } from 'react';
import { BackendDispatchContext, BackendStateContext } from '../context/backend-context';
import { Playlist } from '@nimey/podcast-global-entity';

export const usePlaylist = (userId?: string) => {
  let listId = 'my';
  const { asyncActions } = useContext(BackendDispatchContext)
  const state = useContext(BackendStateContext);

  const loadCallback = useCallback((userId: string, listId: string) => asyncActions!.loadPlaylist(userId, listId), [asyncActions])

  const entity = (userId && state.entities.playlist) ? (state.entities.playlist[userId] || undefined) : undefined;
  const isLoaded = !!entity;

  const load = useCallback(async (force: boolean = false) => (!isLoaded || force) && userId && loadCallback(userId, listId as string), [userId, loadCallback, isLoaded])

  return {
    playlist: entity?.entity as Playlist | undefined,
    isLoaded: !!entity,
    load,
  };
}