import { assertBaseTicketValid } from './base.js';
import { TicketType } from './ticket-type.js';
export function isPodcastClaimOwnershipTicket(ticket) {
    return ticket.type === TicketType.PODCAST_CLAIM_OWNERSHIP;
}
export function isPodcastClaimOwnershipTicketCreatePayload(ticket) {
    return ticket.type === TicketType.PODCAST_CLAIM_OWNERSHIP;
}
export function assertPodcastClaimOwnershipTicketCreatePayloadValid(ticket) {
    assertPodcastClaimOwnershipTicketCreatePayloadValid(ticket);
}
export function assertPodcastClaimOwnershipTicketValid(ticket) {
    assertPodcastClaimOwnershipTicketCreatePayloadValid(ticket);
    assertBaseTicketValid(ticket);
}
