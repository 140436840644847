import { useDebounce } from '@/modules/global/hooks/use-debounce';
import { CSSProperties, PropsWithChildren, useEffect, useRef, useState } from 'react';
import styles from './slide-out-action.module.scss';
type ActionCallback = (reset: () => void) => void

type SlideOutActionProps = {
  left?: boolean;
  right?: boolean;
  onLeft?: ActionCallback;
  onRight?: ActionCallback;
}

export const SlideOutAction = (props: PropsWithChildren<SlideOutActionProps>) => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const [scrollOffset, setScrollOffset] = useState<number>(0);
  const debouncedScrollOffset = useDebounce(scrollOffset);
  const once = useRef<number>(0);

  useEffect(() => {
    const elem = scrollRef.current!;
    const centerScroll = props.left ? elem.getBoundingClientRect().width : 0;
    if(debouncedScrollOffset === once.current) return;
    once.current = debouncedScrollOffset as number;

    if(debouncedScrollOffset === 1) {
      if(props.onRight) props.onRight(() => {elem.scrollTo({left: centerScroll})});

    };
    if(debouncedScrollOffset === -1) {
      if(props.onLeft) props.onLeft(() => {elem.scrollTo({left: centerScroll})});
    };
  }, [debouncedScrollOffset, props])

  useEffect(() => {
    const elem = scrollRef.current;
    const onScroll = () => {
      const centerScroll = props.left ? elem!.getBoundingClientRect().width : 0;

      setScrollOffset((elem!.scrollLeft - centerScroll) / elem!.getBoundingClientRect().width);
    }

    if(elem) {
    
      const centerScroll = props.left ? elem.getBoundingClientRect().width : 0;
      elem.scrollTo({
        left: Math.round(centerScroll)
      });

      elem.addEventListener('scroll', onScroll)
    }

    return () => {
      if(elem) return elem.removeEventListener('scroll', onScroll);
    }
  }, [])

  const stylesLeft = {
    backgroundColor: `rgba(53, 0, 0, ${scrollOffset / 2 * -1})`,
  } as CSSProperties

  const stylesRight = {
    backgroundColor: `rgba(53, 0, 0, ${scrollOffset / 2})`,
  } as CSSProperties

  return (
    <div className={styles['scroll-container']} ref={scrollRef} style={{display: 'grid', gridTemplateColumns: `${props.left ? '100%' : ''} 100% ${props.right ? '100%' : ''}`, overflowX: 'scroll', scrollSnapType: 'x mandatory'}}>
      {props.left ? <div style={{...stylesLeft, scrollSnapAlign: 'center'}}></div> : ''}
      <div style={{scrollSnapAlign: 'center'}}>{props.children || ''}</div>
      {props.right ? <div style={{...stylesRight, scrollSnapAlign: 'center'}}></div> : ''}
    </div>
  )
}