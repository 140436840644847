import { Button } from '@nimey/react-ui';
import { useCurrentAccount } from '../../../auth/hooks/use-current-account';
import styles from './on-account-change.module.scss';
import { useTranslation } from 'react-i18next';

export const OnAccountChangeNotification = (props: {close?: () => void}) => {
  const acc = useCurrentAccount();
  const { t } = useTranslation('common');
  if(!acc) return <></>

  return (
    <div>
      <h1>{t('notification.on-account-change.title', {name: acc.firstName})}</h1>
      <p>{t('notification.on-account-change.text')}</p>
      <table className={styles.table}>
        <tbody>
          <tr>
            <th>{t('notification.on-account-change.email')}</th>
            <td>{acc.email}</td>
          </tr>
          <tr>
          <th>{t('notification.on-account-change.name')}</th>
            <td>{acc.name}</td>
          </tr>
        </tbody>
      </table>

      <div className={styles['button-wrapper']}>
        <Button onClick={() => props && props.close && props?.close()}>Jetzt loslegen</Button>
      </div>
    </div>
  );
}