import { Textarea } from '@/modules/layout/components/form/textarea';
import styles from './tickets.module.scss';
import { useState } from 'react';
import { FlexRow } from '@/modules/layout/components/grid/flex-row';
import { Button } from '@nimey/react-ui';
import { useCreateTicketMessage } from '@/modules/backend/hooks/use-create-ticket-message';

type AddTicketMessageProps = {
  ticketId: string;
}



export const AddTicketMessage = (props: AddTicketMessageProps) => {
  const [text, setText] = useState<string>('');

  const {create, lastError, loading} = useCreateTicketMessage(props.ticketId);
  
  return (
    <div className={styles['add-message']}>
      <form onSubmit={(e) => {
        e.preventDefault()
        create({
          text,
          ticketId: props.ticketId,
        }).then(() => {
          setText('');
        })
      }}>
        <div><Textarea value={text} onChange={(e) => setText(e.target.value)} placeholder='Your message...' /></div>
        {text ? (
          <FlexRow style={{justifyContent: 'space-between'}}>
            <Button disabled={loading} onClick={() => setText('')}>Reset</Button>
            <Button disabled={loading} primary type='submit'>Submit</Button>
          </FlexRow>
        ) : ''}
      </form>
    </div>
  );
}