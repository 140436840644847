import { useParams } from 'react-router-dom';
import { UserTickets } from './tickets';
import { Gutter } from '@/modules/layout/components/gutter';
import { Hr } from '@/modules/layout/components/hr';
import { UserFeatures } from './features';

export const UserDetailPage = () => {
  const { userId } = useParams();
  if(!userId) return <></>
  return (
    <Gutter>
      <h1>USER {userId}</h1>
      <UserTickets userId={userId} />
      <Hr />
      <UserFeatures userId={userId} />
    </Gutter>
  );
}