import { useCallback, useContext } from 'react';
import { MediaPlayerDispatchContext, MediaPlayerStateContext } from '../context/media-player-context';

export const useTogglePlay = () => {
  const { play } =  useContext(MediaPlayerStateContext);
  const { asyncActions } =  useContext(MediaPlayerDispatchContext);

  const toggle = useCallback(() => {
    if(play) asyncActions?.pause();
    else asyncActions?.play();
  }, [play, asyncActions])

  return { toggle, play }
}