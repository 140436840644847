import { CSSProperties, PropsWithChildren } from 'react';

export type FlexRowProps = {
  style?: CSSProperties;
  className?: string;
  spaceBetween?: boolean
}

export const FlexRow = (props: PropsWithChildren<FlexRowProps>) => (
  <div
    className={props.className}
    style={{
      ...(props.style || {}),
      display: 'flex',
      ...(props.spaceBetween ? {justifyContent: 'space-between'} : {})
    }}
  >
    {props.children}
  </div>
)