import { usePodcast } from '@/modules/backend/hooks/use-podcast';
import { ArtworkWrapper, PodcastArtwork } from '@/modules/global/components/podcast/artwork';
import { PodcastAuthor } from '@/modules/global/components/podcast/author';
import { PodcastDescription } from '@/modules/global/components/podcast/description';
import { EpisodeDescription } from '@/modules/global/components/podcast/episode/description';
import { PodcastEpisodeListForPodcast } from '@/modules/global/components/podcast/episode/episode-list';
import { EpisodePubDate } from '@/modules/global/components/podcast/episode/pub-date';
import { EpisodeTitle } from '@/modules/global/components/podcast/episode/title';
import { PodcastProvider } from '@/modules/global/components/podcast/podcast-provider';
import { PodcastTitle } from '@/modules/global/components/podcast/title';
import { Gutter } from '@/modules/layout/components/gutter';
import { Hr } from '@/modules/layout/components/hr';
import { Spinner } from '@/modules/layout/components/spinner';
import { Fragment, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import styles from './podcast-detail.module.scss';
import { ListMeta } from '@/modules/backend/types/backend-state';
import { useTranslation } from 'react-i18next';
import { PlayEpisode } from '@/modules/media-player/components/play-episode';
import { useLocalEpisode } from '@/modules/global/components/podcast/episode/episode-provider';
import { Link } from 'react-router-dom';
import { AddToPlaylist } from './add-to-playlist';
import { MdSupportAgent } from 'react-icons/md';
import { RoleGuard } from '@/modules/backend/components/role-guard';
import { PodcastAbo } from '@/modules/global/components/podcast/podcast-abo';
import { EpisodeActions } from './epispode-actions';

const EpisodeRenderer = () => {
  const { episode } = useLocalEpisode();
  return (
    <Fragment>
      <Hr />
      <Link to={`/player/pod/${episode?.podcastId}/${episode?.id}`}>
        <p className={styles['episode-pub-date']}><EpisodePubDate /></p>
        <p className={styles['episode-title']}><EpisodeTitle /></p>
        <p className={styles['episode-description']}><EpisodeDescription /></p>
      </Link>
      <div className={styles['episode-action-bar']}>
        <EpisodeActions />
      </div>
    </Fragment>
  );
}

const HeaderRenderer = (props: {meta: ListMeta}) => {
  const { meta } = props;

  const { t } = useTranslation('player')
  return (
    <div className={styles['epsiode-header']}>{meta?.totalCount} {t('episodes')}</div>
  );
}

const WithId = (props: { podcastId: string }) => {
  const { load, isLoaded } = usePodcast(props.podcastId);
  
  useEffect(() => {
    load();
  }, [])

  if(!isLoaded) {
    return <div><Spinner size='2em' /></div>
  }

  return (
    <PodcastProvider podcastId={props.podcastId}>
      <Gutter>
        <div className={styles.header}>
          <div className={styles.artwork}><ArtworkWrapper width={110}><PodcastArtwork /></ArtworkWrapper></div>
          <div className={styles.info}>
          <p className={styles.title}><PodcastTitle /></p>
            <p className={styles.author}><PodcastAuthor /></p>
          </div>
        </div>
        <div className={styles['action-bar']}>
          <RoleGuard roles={['super-admin']}>
            <Link to={`/support/podcast/${props.podcastId}`} className={styles.button}><MdSupportAgent /></Link>
          </RoleGuard>
          <PodcastAbo />

        </div>
        <div className={styles.description}><PodcastDescription /></div>

        <PodcastEpisodeListForPodcast
          episodeRenderer={EpisodeRenderer}
          headerRenderer={HeaderRenderer}
          className={styles['list']}
          innerClassName={styles['list-entry']}
        />
      </Gutter>
    </PodcastProvider>
  );
}

export const PodcastDetail = () => {
  const { id } = useParams();

  if(!id) return <></>
  return <WithId podcastId={id} />
}