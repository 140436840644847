import { useCalculatedColor } from '@/modules/global/hooks/use-calculated-color';
import { User } from '@nimey/podcast-global-entity';

import styles from './user.module.scss';

export interface UserIconProps extends React.HTMLAttributes<HTMLDivElement> {
  user: User,
  size?: string | number
}

export const UserIcon = (props: UserIconProps) => {
  const {
    size, user,
    ...passProps
  } = props;

  const color = useCalculatedColor(user.email!)

  return (
    <div {...passProps} style={{
      backgroundColor: color,
      ...(size ? {
        fontSize: size,
      } : {})
    }} className={styles.icon}>{(user.name || '').substring(0, 1)}</div>
  )
}