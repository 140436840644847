import { assertBaseTicketCreatePayloadValid, assertBaseTicketValid } from './base.js';
import { TicketType } from './ticket-type.js';
export function isPodcastRequestTicket(ticket) {
    return ticket.type === TicketType.PODCAST_REQUEST;
}
export function isPodcastRequestTicketCreatePayload(ticket) {
    return ticket.type === TicketType.PODCAST_REQUEST;
}
export function assertPodcastRequestTicketCreatePayloadValid(ticket) {
    assertBaseTicketCreatePayloadValid(ticket);
}
export function assertPodcastRequestTicketValid(ticket) {
    assertPodcastRequestTicketCreatePayloadValid(ticket);
    assertBaseTicketValid(ticket);
}
