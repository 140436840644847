import { Token } from './token';

export type TokenInput = {
  access_token: string;
  refresh_token: string;
  id_token: string;

  expires_in: number;
  refresh_expires_in: number;


  token_type: string;
  'not-before-policy': number
  session_state: string;
  scope: string;
}

export class TokenSet {
  public readonly access_token: Token;
  public readonly refresh_token: Token;
  public readonly id_token: Token;
  public readonly expires_in: number;
  public readonly refresh_expires_in: number;
  public readonly token_type: string;
  public readonly not_before_policy: number;
  public readonly session_state: string;
  public readonly scope: string;

  constructor(input: TokenInput) {
    this.access_token = new Token(input.access_token);
    this.refresh_token = new Token(input.refresh_token);
    this.id_token = new Token(input.id_token);
    this.expires_in = input.expires_in;
    this.refresh_expires_in = input.refresh_expires_in;
    this.token_type = input.token_type;
    this.not_before_policy = input['not-before-policy'];
    this.session_state = input.session_state;
    this.scope = input.scope;
  }

  toJSON() {
    return {
      access_token: this.access_token.toString(),
      refresh_token: this.refresh_token.toString(),
      id_token: this.id_token.toString(),
      expires_in: this.expires_in,
      refresh_expires_in: this.refresh_expires_in,
      token_type: this.token_type,
      not_before_policy: this.not_before_policy,
      session_state: this.session_state,
      scope: this.scope,
    }
  }
}