import { Gutter } from '@/modules/layout/components/gutter';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { TokenClaimPodcastOwnershipPayload, useTokenPayload } from '../hooks/use-token-payload';
import { Fragment, useRef, useState } from 'react';
import { Button } from '@nimey/react-ui';
import { SignedTokenType } from '@nimey/podcast-global-entity';
import { PodcastProvider, useLocalPodcast } from '@/modules/global/components/podcast/podcast-provider';
import { OrganizationProvider, OrganizationName } from '@/modules/global/components/organization/organization-provider';
import { PodcastName } from '@/modules/global/components/podcast/name';
import { useProcessToken } from '@/modules/backend/hooks/use-process-token';
import { Spinner } from '@/modules/layout/components/spinner';


const ClaimPodcastOwnership = (props: {token: string, payload: TokenClaimPodcastOwnershipPayload}) => {
  const processToken = useProcessToken();
  const [ mode, setMode ] = useState<'PENDING' | 'LOADING' | 'SUCCESS'>('PENDING');
  const [ error, setError ] = useState<string>('');
  const CurrentOrg = () => {
    const { podcast } = useLocalPodcast();
    if(!podcast) return <></>;
    if(!podcast.orgId) return <></>;

    return <OrganizationProvider orgId={podcast.orgId}><p>Die Organisation "<OrganizationName />" verliert dadurch den Zugriff auf den Podcast!</p></OrganizationProvider>
  }

  return (
    <Gutter>
      <h1>Podcast Inhaberschaft übernehmen</h1>
      <PodcastProvider podcastId={props.payload.podcastId}>
        <OrganizationProvider orgId={props.payload.orgId}>
          { mode === 'PENDING' ? (
            <Fragment>
              <p>Du möchtest den Podcast "<PodcastName />" an die Organisation "<OrganizationName />" übertragen.</p>
              <CurrentOrg />

              <p>{ error ? (<span>Fehler aufgetreten: {error}</span>) : ''}</p>
              <Button primary onClick={() => {
                setMode('LOADING');
                setError('');
                processToken(props.token).then((res) => {
                  const response = res?.response
                  if(typeof response === 'string') {
                    setError(response);
                    setMode('PENDING');
                  } else if(response === false) {
                    setError('Unbekannter Fehler');
                    setMode('PENDING');
                  } else if(response === true) {
                    setMode('SUCCESS');
                  }
                }).catch((e) => {
                  setError((new String(e)).toString());
                    setMode('PENDING');
                })
              }}>Jetzt übertragen</Button>
              <Link to={`/manager/${props.payload.orgId}`}><Button>Abbrechen</Button></Link>
            </Fragment>
          ) : '' }
          
          { mode === 'LOADING' ? (
            <Fragment>
              <div><Spinner size='3em' /></div>
              <p>Deine Anfrage wird bearbeitet</p>
            </Fragment>
          ) : '' }

          { mode === 'SUCCESS' ? (
            <Fragment>
              <p>Es hat geklappt! Der Podcast wurde an die Organisation "<OrganizationName />" übertragen</p>
              <Link to={`/manager/${props.payload.orgId}/`}><Button primary>Zum Podcast</Button></Link>
            </Fragment>
          ) : '' }

        </OrganizationProvider>
      </PodcastProvider>
    </Gutter>
  );
}

const CheckToken = (props: {token: string}) => {
  const payload = useTokenPayload(props.token)

  if(!payload) {
    return (
      <Gutter>
        <h1>Dein Token ist nicht gültig.</h1>
        <p>Versuch bitte einen anderen Token</p>
        <Link to='/email-verify'><Button>Neuen Token eingeben</Button></Link>
      </Gutter>
    )
  }

  switch(payload.type) {
    case SignedTokenType.CLAIM_PODCAST_OWNERSHIP: return <ClaimPodcastOwnership token={props.token} payload={payload as TokenClaimPodcastOwnershipPayload} />
  }

  return <></>;
}

export const EmailVerifyPage = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const nav = useNavigate()
  const inputRef = useRef<HTMLInputElement>(null)

  if(token) return <CheckToken {...{token}} />
  return (
    <Gutter>
      <h1>Token eingeben</h1>
      <form onSubmit={(e) => {
        e.preventDefault()
        nav(`/email-verify?token=${inputRef.current?.value}`)
      }}>
        <input type='text' name='token' ref={inputRef} />
        <Button type='submit' primary>Token prüfen</Button>
      </form>
    </Gutter>
  );
}