import { useCallback, useContext } from 'react';
import { BackendDispatchContext } from '../context/backend-context';
import { BackendActionKind } from '../types/backend-action';

export const useSetPlaybackState = (episodeId: string) => {
  const { asyncActions, dispatch } = useContext(BackendDispatchContext);

  return useCallback(({playedSeconds, completed}: {playedSeconds?: number, completed: boolean}) => {
    if(!asyncActions) return;
    asyncActions.updatePlaybackState(episodeId, { completed, episodePlayedSeconds: Math.round(playedSeconds || 0) }).then(result => {
      dispatch({type: BackendActionKind.UPDATE_LOCAL_PLAYBACK_STATE, payload: {episodeId: episodeId, playbackState: result.response}})
    });
  }, [asyncActions, dispatch, episodeId])
}