import { Gutter } from '@/modules/layout/components/gutter';

import { AboPodcasts } from './list/abo-podcasts';
import { withCurrentUserId } from '@/modules/backend/hoc/with-current-userid';
import { SelectAccountBanner } from '@/modules/auth/components/select-account-banner';
import { AnonymousPodcasts } from './list/anonymous-podcasts';
import { ListLayout, PodcastList } from './list/podcast';
import { PodcastCategory, PodcastCategoryMap, PodcastListType } from '@nimey/podcast-global-entity';
import { useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const PlayerPodcastCategoryWithUser = () => {
  const { category } = useParams() as {category: PodcastCategory};
  const { t } = useTranslation('category')
  const { categories } = useMemo(() => {
    const categories = Object.values(PodcastCategory).filter(c => c.startsWith(category) && c !== category)


    return {
      categories,
    }
  }, [])

  return (
    <Gutter>
        <h1>{t(category)}</h1>
        {categories.map(category => (
          <div key={category}>
            <h2>{t(category)}</h2>
            
            <PodcastList
              listType={PodcastListType.ALL}
              layout={ListLayout.CAROUSEL}
              title=''
              filter={{categories: [category]}}
            />
          </div>
        ))}
        
    </Gutter>
  );
}

const PlayerHomeAnonymous = () => {
  return (
    <Gutter>
      <p>Du bist nicht angemeldet. Wähle jetzt dein Konto oder erstelle ein neues, um alle Funktionen zu nutzten.</p>
      <SelectAccountBanner />
      <AnonymousPodcasts limit={16} />
    </Gutter>
  );
}

export const PlayerPodcastCategory = withCurrentUserId(PlayerPodcastCategoryWithUser, PlayerHomeAnonymous)