import { Gutter } from '@/modules/layout/components/gutter';
import { TicketBoard } from './ticket-board/board';

export const SupportHome = () => {
  return (
    <Gutter>
      <h1>SUPPORT</h1>
      <TicketBoard />
    </Gutter>
  );
}