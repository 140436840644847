import { useCurrentOrg } from '@/modules/manager/components/hooks/use-current-org';
import { Organization } from '@nimey/podcast-global-entity';

export function withCurrentOrg<P extends {}>(
  WrappedComponent: React.ComponentType<P & {org: Organization}>,
  FallbackComponent?: React.ComponentType<P & {org: Organization}>,
) {
  const WithUserId: React.FC<P> = (props: P) => {
    const org = useCurrentOrg();

    const Fallback = FallbackComponent || (() => <></>);

    // @ts-ignore
    if(!org) return <Fallback {...props} org={org} />;

    return <WrappedComponent {...props} org={org} />
  }

  return WithUserId;
}