import React from 'react';
import { AuthStateContext } from '../context/auth-context';

export const useCurrentAccount = () => {
  const state = React.useContext(AuthStateContext);

  if(!state.currentAccountId) return undefined;
  if(!state.accounts[state.currentAccountId]) return undefined;

  return state.accounts[state.currentAccountId];
}