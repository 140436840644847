import { useCurrentAccount } from '@/modules/auth/hooks/use-current-account'
import { useTicketList } from '@/modules/backend/hooks/use-ticket-list';
import { LoadMore } from '@/modules/layout/components/load-more';

import styles from './tickets.module.scss';
import { useHashStateFlagRegex } from '@/modules/global/hooks/use-hash-state-flag';
import { TicketCard } from '@/modules/global/components/ticket/card';
import { TicketModal } from '@/modules/global/components/ticket/ticket-modal';

type TicketListProps = {
  accountId: string;
}

const TicketModalWrapper = () => {
  const { state, matches, unset } = useHashStateFlagRegex(/^#ticket-(?<ticketId>.*)/);
  const ticketId = state && matches?.groups?.ticketId ? matches.groups.ticketId : undefined;
  return (
    <TicketModal ticketId={ticketId} isOpen={state} close={() => unset()} />
  );
}


const TicketList = (props: TicketListProps) => {
  const {allIds, loadMore, hasMore} = useTicketList({creatorId: props.accountId});
  return (
    <div>
      <ul className={styles['ticket-list']}>
        {allIds.map(id => <li className={styles['ticket-list-entry']} key={id}><TicketCard ticketId={id} /></li>)}
      </ul>
      <LoadMore {...{loadMore, hasMore}} />
      <TicketModalWrapper />
    </div>
  );
}

export const MyTickets = () => {
  const account = useCurrentAccount();

  if(!account) return <></>

  return <TicketList accountId={account.id} />
}