import { usePodcastSearch } from '@/modules/backend/hooks/use-podcast-search'
import { usePodcastSearchResultEntity } from '@/modules/backend/hooks/use-podcast-search-result-entity';
import { PodcastProvider } from '@/modules/global/components/podcast/podcast-provider';
import { PodcastTitle } from '@/modules/global/components/podcast/title';
import { LoadMore } from '@/modules/layout/components/load-more';
import { Podcast, PodcastEpisode } from '@nimey/podcast-global-entity';
import { Link, useParams } from 'react-router-dom'
import styles from './search-result.module.scss';
import { ArtworkWrapper, PodcastArtwork } from '@/modules/global/components/podcast/artwork';
import { Gutter } from '@/modules/layout/components/gutter';
import { Hr } from '@/modules/layout/components/hr';
import { PodcastAbo } from '@/modules/global/components/podcast/podcast-abo';
import { EpisodeRendererList } from './list/episode';

const ResultPodcast = (props: {podcast: Podcast}) => {
  return (
    <div>
      <PodcastProvider podcastId={props.podcast.id}>
        <Link to={`/player/pod/${props.podcast.id}`}>
          <div className={styles['podcast-info']}>
            <ArtworkWrapper width={60}><PodcastArtwork /></ArtworkWrapper>
            <div>
            <p className={styles['main-title']}><PodcastTitle maxLines={2} /></p>
            </div>
          </div>
        </Link>
        <div className={styles['actions']}>
          <PodcastAbo />
        </div>
      </PodcastProvider>
      <Hr />
    </div>
  );
}

const ResultEpisode = (props: {episode: PodcastEpisode}) => {
  return (
    <EpisodeRendererList id={props.episode.id} />
  );
}

const ResultEntry = (props: {id: string}) => {
  const { entity, type } = usePodcastSearchResultEntity(props.id);

  return (
    <li className={styles['list-entry']}>
      {type === 'podcast' ? <ResultPodcast {...{podcast: entity as Podcast}} /> : ''}
      {type === 'episode' ? <ResultEpisode {...{episode: entity as PodcastEpisode}} /> : ''}
    </li>
  );
}

const SearchResultPageWithQuery = (props: {query: string}) => {
  const { allIds, loadMore, hasMore} = usePodcastSearch(props.query);
  return (
    <Gutter>
      <h1>Suchergebnis für "{ props.query }"</h1>
      <ul className={styles['list']}>
        {allIds.map(id => <ResultEntry key={id} id={id} />)}
        <LoadMore {...{loadMore, hasMore}} />
      </ul>
    </Gutter>
  )
}

export const SearchResultPage = () => {
  const { query } = useParams();

  if(!query) return <></>;

  return <SearchResultPageWithQuery {...{query}} />
}


