import { useEffect } from 'react';
import { useGlobalFeatureFlags } from './use-global-feature-flags'
import { FeatureFlag } from '@nimey/podcast-global-entity';

export const useHasGlobalFeature = (flag: FeatureFlag) => {
  const { features, load } = useGlobalFeatureFlags();

  useEffect(() => { load(); }, [])

  return features?.flags.includes(flag);
}