import { LoadMore } from '@/modules/layout/components/load-more';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Fragment } from 'react/jsx-runtime';

export type ListProps = {
  title?: string;
  allIds: string[];
  hasMore: boolean;
  loadMore: () => Promise<void>;
  limit?: number;
  entryRenderer: React.FC<{id: string}>;
  moreRenderer?: React.FC<{}>;
}


export const List = (props: ListProps) => {
  const EntryComponent = props.entryRenderer;
  const MoreComponent = props.moreRenderer;
  const { t } = useTranslation('player')
  const renderIds = useMemo(() => {
    if(props.limit) return (props.allIds.slice(0, props.limit));
    return props.allIds
  }, [props.allIds, props.limit])
  const limitReached = props.limit && props.allIds && props.allIds.length >= props.limit;
  return (
    <Fragment>
      {props.title? <h2>{t(props.title)}</h2> : <></>}
      <ul style={{padding: 0}}>
        {renderIds.map(id => (
          <li style={{listStyle: 'none'}} key={id}><EntryComponent id={id} /></li>
        ))}
        {!limitReached ? (
          <LoadMore {...{
            loadMore: props.loadMore,
            hasMore: props.hasMore,
          }} />
        ) : ''}
        {MoreComponent && props.allIds.length >= (props.limit || 0) ? (
          <li style={{listStyle: 'none'}}><MoreComponent /></li>
        ) : ''}
      </ul>
    </Fragment>
  );
}

export const Carousel = (props: ListProps) => {
  const { t } = useTranslation('player');
  const EntryComponent = props.entryRenderer;
  const MoreComponent = props.moreRenderer;
  const renderIds = useMemo(() => {
    if(props.limit) return (props.allIds.slice(0, props.limit));
    return props.allIds
  }, [props.allIds, props.limit])
  const limitReached = props.limit && props.allIds && props.allIds.length >= props.limit;
  return (
    <div style={{width: '100%', overflowX: 'hidden'}}>
      {props.title? <h2>{t(props.title)}</h2> : <></>}
      <ul style={{
        padding: 0,
        display: 'flex',
        flexDirection: 'row',
        scrollSnapAlign: 'start',
        gap: '1em',
        overflowX: 'auto',
        scrollSnapType: 'x-mandatory'
      }}>
        {renderIds.map(id => (
          <li style={{listStyle: 'none'}} key={id}><EntryComponent id={id} /></li>
        ))}
        {!limitReached ? (
          <LoadMore {...{
            loadMore: props.loadMore,
            hasMore: props.hasMore,
          }} />
        ) : ''}
        {MoreComponent && props.allIds.length >= (props.limit || 0) ? (
          <li style={{listStyle: 'none'}}><MoreComponent /></li>
        ) : ''}
      </ul>
    </div>
  );
}

export const Tiles = (props: ListProps) => {
  const { t } = useTranslation('player');
  const EntryComponent = props.entryRenderer;
  const MoreComponent = props.moreRenderer;
  const renderIds = useMemo(() => {
    if(props.limit) return (props.allIds.slice(0, props.limit));
    return props.allIds
  }, [props.allIds, props.limit])
  const limitReached = props.limit && props.allIds && props.allIds.length >= props.limit;
  return (
    <div>
      {props.title? <h2>{t(props.title)}</h2> : <></>}
      <ul style={{
        padding: 0,
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        gap: '.75em',
      }}>
        {renderIds.map(id => (
          <li style={{listStyle: 'none'}} key={id}><EntryComponent id={id} /></li>
        ))}
        {!limitReached ? (
          <LoadMore {...{
            loadMore: props.loadMore,
            hasMore: props.hasMore,
          }} />
        ) : ''}
        {MoreComponent && props.allIds.length >= (props.limit || 0) ? (
          <li style={{listStyle: 'none'}}><MoreComponent /></li>
        ) : ''}
      </ul>
    </div>
  );
}