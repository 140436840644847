import { useUserSearch } from '@/modules/backend/hooks/use-user-search';
import { Fragment, useEffect, useMemo } from 'react';
import { UserCard } from './user-card';

import styles from './user.module.scss';

type UserSearchResultProps = {
  query: string;
  userRenderer?: React.FC<{userId: string}>;
}

export const UserSearchResult = (props: UserSearchResultProps) => {
  const { query } = props;

  const { loadPage, pages, meta, hasMore } = useUserSearch(query);

  useEffect(() => {
    loadPage(1)
  }, [query]);

  const allUsers = useMemo(() => {
    const result: string[] = [];
    pages.forEach(p => result.push(...p.ids));
    return result;
  }, [pages])

  const UserRenderer = props.userRenderer || UserCard;

  return (
    <Fragment>
      <div>
        Showing {allUsers.length}/{meta?.totalCount || 0}
      </div>
      <ul className={styles['user-list']}>
        {allUsers.map((id) =>  <UserRenderer key={id} userId={id} />)}
      </ul>
    </Fragment>
  );
}