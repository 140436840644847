import { SelectAccountBanner } from '@/modules/auth/components/select-account-banner';
import { Gutter } from '@/modules/layout/components/gutter';
import { Link } from 'react-router-dom';

export const LoginRequired = () => {
  return (
    <Gutter>
      <p>Du musst angemeldet sein, um den Podcast Manager zu verwenden.</p>
      <Link to='/player'>Jetzt zum Player</Link>
      <SelectAccountBanner />
    </Gutter>
  );
}