import { useUserQuota } from '@/modules/backend/hooks/use-user-quota';
import { Table, Td, Th, Tr } from '@/modules/layout/components/table';
import { QuotaDefinition, QuotaName, QuotaRequestTicket } from '@nimey/podcast-global-entity';
import { useEffect } from 'react';
import { Fragment } from 'react/jsx-runtime';

type TicketModalProps = {
  ticket: QuotaRequestTicket;
  isOpen: boolean;
  close: () => void;
}

const CurrentUserQuotavalue = ({userId, quotaName}: {userId: string, quotaName: QuotaName}) => {
  const {userQuota, isLoaded, load} = useUserQuota(userId, quotaName);

  useEffect(() => {
    load()
  }, [])

  if(!isLoaded) return <></>

  return <>{userQuota.value}</>
}

export const TicketTypeQuotaRequest = (props: TicketModalProps) => {
  const { ticket } = props;
  const defaultValue = QuotaDefinition[ticket.quotaName];

  return (
    <Fragment>
      <Table>
        <tbody>
          <Tr>
            <Th>Name</Th>
            <Td>{ticket.quotaName}</Td>
          </Tr>
          <Tr>
            <Th>Requested Value</Th>
            <Td>{ticket.requestValue}</Td>
          </Tr>
          <Tr>
            <Th>Default Value</Th>
            <Td>{defaultValue.defaultValue}</Td>
          </Tr>
          <Tr>
            <Th>User Value</Th>
            <Td><CurrentUserQuotavalue userId={ticket.creatorId} quotaName={ticket.quotaName} /></Td>
          </Tr>
          <Tr>
            <Td colSpan={2} style={{whiteSpace: 'preserve'}}>
              {ticket.requestReason}
            </Td>
          </Tr>
        </tbody>
      </Table>
    </Fragment>
  );
}