import { Duration } from '@nimey/units';
import { useRef, TextareaHTMLAttributes, useEffect, ChangeEvent, FormEvent } from 'react';

type TextareaProps = TextareaHTMLAttributes<HTMLTextAreaElement>;

export const Textarea = (props: TextareaProps) => {
  const ref = useRef<HTMLTextAreaElement>(null);
  const intervalRef = useRef<ReturnType<typeof setInterval>>();

  useEffect(() => {
    const elem = ref.current
    
    const listener = () => {
      if(elem) {
        if(elem.value === '') elem.setAttribute('style', `min-height: 2em; height: 0px; overflow-y: hidden`);
        else elem.setAttribute('style', `min-height: 2em; height: ${elem.scrollHeight}px; overflow-y: hidden`);
      }
    }

    intervalRef.current = setInterval(listener, +Duration.seconds(1))
    if (elem) elem.addEventListener('input', listener);
    if (elem) elem.addEventListener('blur', listener);
    if (elem) elem.addEventListener('focus', listener);

    return () => {
      if (elem) elem.removeEventListener('input', listener);
      if (elem) elem.removeEventListener('blur', listener);
      if (elem) elem.removeEventListener('focus', listener);

      if(intervalRef.current) clearInterval(intervalRef.current)
    }
  }, [])


  return <textarea ref={ref} {...props} />
}