import { useTranslation } from 'react-i18next';
import { Fragment } from 'react/jsx-runtime';

import ManagerDescriptionDe from './de/content/manager-description.mdx';
import ManagerDescriptionEn from './en/content/manager-description.mdx';

import NotFoundDe from './de/content/not-found.mdx';
import NotFoundEn from './en/content/not-found.mdx';

// @ts-ignore
const withTranslation = (components: Record<string, React.FC>) => (props) => {
  const { i18n } = useTranslation();
  const defaultComponent = Fragment;
  const Component = components[i18n.language] || components['de'] || defaultComponent;

  return <Component {...props} />;
}

export const ManagerDescription = withTranslation({
  de: ManagerDescriptionDe,
  en: ManagerDescriptionEn
})

export const NotFoundContent = withTranslation({
  de: NotFoundDe,
  en: NotFoundEn,
})