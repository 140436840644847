import { FeatureGuard } from '@/modules/backend/components/feature-guard';
import { FeatureFlag } from '@nimey/podcast-global-entity';
import { useTranslation } from 'react-i18next';

export const MenuSelectLanguage = () => {
  const {t, i18n} = useTranslation('common');
  const allLangs = ['de', 'en']
  
  return (
    <FeatureGuard flag={FeatureFlag.LANGUAGE_SELECTION}>
      <div>
        <select value={i18n.language} onChange={(e) => {
          i18n.changeLanguage(e.currentTarget.value)
          window.localStorage.setItem('podcast/common/lang', e.currentTarget.value)
        }}>
          {allLangs.map(lang => (
            <option key={lang} value={lang}>{t(`language-selector.lang.${lang}`)}</option>
          ))}
        </select>
      </div>
    </FeatureGuard>
  );
}