import { MdClose } from 'react-icons/md';
import { Notification } from '../types/notification';

import styles from './full-screen-notification.module.scss';

export const FullScreenNotification = (props: {notification: Notification, close: () => void}) => {
  const Content = props.notification.component;
  return (
    <div className={styles.outer}>
      <div onClick={e => props.close()} className={styles.background} />
      <div className={styles.notification}>
        <div className={styles.close} onClick={e => props.close()}><MdClose /></div>
        <Content close={props.close} />
      </div>
    </div>
  );
}