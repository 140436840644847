import { BaseEntity, EpisodePlaybackState } from '@nimey/podcast-global-entity';
import { ListMeta } from './backend-state.js';

export enum BackendActionKind {
  ADD_ENTITY = 'add_entity',
  ADD_ENTITY_LIST = 'add_entity_list',
  ADD_ENTITY_LIST_PAGE = 'add_entity_list_page',
  REMOVE_ENTITY = 'remove_entity',
  RESET = 'reset',
  FLUSH_TAGS = 'flush_tags',
  UPDATE_LOCAL_PLAYBACK_STATE = 'update_local_playback_state',
  SET_CURRENT_PLAYLIST_ID = 'set_current_playlist_id',
};

type PayloadType = {
  [BackendActionKind.RESET]: undefined;
  [BackendActionKind.ADD_ENTITY]: {type: string, entity: BaseEntity, tags?: Set<string>};
  [BackendActionKind.REMOVE_ENTITY]: {type: string, id: string};
  [BackendActionKind.ADD_ENTITY_LIST]: {type: string, filterKey: string, meta: ListMeta, tags?: Set<string>}
  [BackendActionKind.ADD_ENTITY_LIST_PAGE]: {type: string, filterKey: string, pageNumber: number, ids: string[], tags?: Set<string>}
  [BackendActionKind.FLUSH_TAGS]: {tags?: Set<string>}
  [BackendActionKind.UPDATE_LOCAL_PLAYBACK_STATE]: {episodeId?: string, playbackState?: EpisodePlaybackState}
  [BackendActionKind.SET_CURRENT_PLAYLIST_ID]: string | undefined,
}


type PayloadTypeGenerator<T extends BackendActionKind> = {type: T, payload: PayloadType[T]};

export type BackendAction = PayloadTypeGenerator<BackendActionKind.RESET>
  | PayloadTypeGenerator<BackendActionKind.ADD_ENTITY>
  | PayloadTypeGenerator<BackendActionKind.REMOVE_ENTITY>
  | PayloadTypeGenerator<BackendActionKind.ADD_ENTITY_LIST>
  | PayloadTypeGenerator<BackendActionKind.ADD_ENTITY_LIST_PAGE>
  | PayloadTypeGenerator<BackendActionKind.FLUSH_TAGS>
  | PayloadTypeGenerator<BackendActionKind.UPDATE_LOCAL_PLAYBACK_STATE>
  | PayloadTypeGenerator<BackendActionKind.SET_CURRENT_PLAYLIST_ID>