import { TokenSet } from '../util/token-set';
import { Account } from './account.js';

export enum AuthActionKind {
  ADD = 'add',
  REMOVE = 'remove',
  SET_CURRENT = 'set_current',
  SET_LOADING = 'loading',
  ADD_TOKEN_SET = 'token_set,'
};

type PayloadType = {
  [AuthActionKind.ADD]: Account
  [AuthActionKind.REMOVE]: {id: string}
  [AuthActionKind.SET_CURRENT]: {id?: string}
  [AuthActionKind.SET_LOADING]: boolean,
  [AuthActionKind.ADD_TOKEN_SET]: TokenSet,
}


type PayloadTypeGenerator<T extends AuthActionKind> = {type: T, payload: PayloadType[T]};

export type AuthActionTypes = PayloadTypeGenerator<AuthActionKind.ADD>
  | PayloadTypeGenerator<AuthActionKind.REMOVE>
  | PayloadTypeGenerator<AuthActionKind.SET_CURRENT>
  | PayloadTypeGenerator<AuthActionKind.SET_LOADING>
  | PayloadTypeGenerator<AuthActionKind.ADD_TOKEN_SET>

  export type AuthAction = AuthActionTypes | AuthActionTypes[];
