import { useContext } from 'react'
import { BackendStateContext } from '../context/backend-context'

export const usePodcastSearchResultEntity = (id: string) => {
  const { entities: {'podcast-search': {[id]: { entity }}}} =  useContext(BackendStateContext);

  return {
    entity,
    type: 'podcastId' in entity ? 'episode' : 'podcast',
  };
}