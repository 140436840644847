import { MdSearch } from 'react-icons/md';
import styles from './search.module.scss';
import { FeatureGuard } from '@/modules/backend/components/feature-guard';
import { FeatureFlag } from '@nimey/podcast-global-entity';
import { useNavigate } from 'react-router-dom';

export const SearchInput = () => {
  const navigate = useNavigate();
  return (
    <FeatureGuard flag={FeatureFlag.PODCAST_SEARCH}>
      <form onSubmit={(e) => {
        e.preventDefault();
        const formData = new FormData(e.currentTarget);
        const searchQuery = formData.get('query');
        if(typeof searchQuery === 'string' && searchQuery.length > 2) {
          navigate(`/player/search/${searchQuery}`);
        }
      }}>
        <div className={styles.search}>
          <label htmlFor='header-search-input' className={styles.icon}><MdSearch /></label>
          <input id="header-search-input" name='query' type="text" placeholder='Nach Podcasts suchen' />
        </div>
      </form>
    </FeatureGuard>
  )
}