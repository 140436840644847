import { useContext } from 'react';
import { BackendDispatchContext, BackendStateContext } from '../context/backend-context';
import { GetActionsResponse } from '@nimey/podcast-global-entity';

export const useTicketActions = (ticketId: string) => {
  const { asyncActions } = useContext(BackendDispatchContext)
  const state = useContext(BackendStateContext);
  const id = ticketId;

  const entity = id && state.entities['ticket-actions'] ? state.entities['ticket-actions'][id] || undefined : undefined;

  const isLoaded = !!entity;
  return {
    actions: (entity?.entity as GetActionsResponse)?.actions || [],
    isLoaded: !!entity,
    load: async (force: boolean = false) => {
      if(id && (!isLoaded || force)) asyncActions!.loadTicketActions(ticketId);
    }
  };
}