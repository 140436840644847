import { useHashStateFlag } from '@/modules/global/hooks/use-hash-state-flag';
import { Button } from '@nimey/react-ui';
import { Fragment } from 'react/jsx-runtime';
import { CreateTicketModal } from './modal';

export const CreateTicket = () => {
  const [ show, setShow ] = useHashStateFlag('create-ticket');
  return (
    <Fragment>
      <Button primary onClick={() => setShow(true)}>Create Ticket</Button>
      <CreateTicketModal {...{isOpen: show, close: () => setShow(false)}} />
    </Fragment>
  );
}