import { BaseController } from './base.controller.js';
export class PlaylistController extends BaseController {
    client;
    constructor(client) {
        super();
        this.client = client;
    }
    getByUserAndId(userId, id) {
        return this.client.get(`/playlist/${userId}/${id}`);
    }
    list(filter, skip = 0, limit = 50) {
        const params = new URLSearchParams({
            skip: skip.toString(),
            limit: limit.toString(),
        });
        Object.entries(filter).forEach(e => params.set(`filter[${e[0]}]`, String(e[1])));
        return this.client.get(`/playlist/${filter.userId}`, params);
    }
    create(userId, playlist) {
        return this.client.post(`/playlist/${userId}`, playlist);
    }
    update(userId, id, playlist) {
        return this.client.put(`/playlist/${userId}/${id}`, playlist);
    }
}
