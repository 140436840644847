import { useContext } from 'react';
import { BackendDispatchContext } from '../context/backend-context';
import { useEntityList } from './use-entity-list';

export const usePodcastSearch = (query: string) => {
  const { asyncActions } = useContext(BackendDispatchContext)
  const filterKey = JSON.stringify({listType: 'search', query});


  return useEntityList(query, 'podcast-search', async (query, pageNumber?: number, pageSize?: number) => {
    return asyncActions!.searchPodcast(query, pageNumber, pageSize)
  }, filterKey)
}