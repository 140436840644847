import { useLocalPodcast } from './podcast-provider';
import { MdAddCircle, MdRemoveCircle } from 'react-icons/md'
import styles from './podcast-abo.module.scss';
import { usePodcastAbo } from '@/modules/backend/hooks/use-podcast-abo';
import { useCurrentAccount } from '@/modules/auth/hooks/use-current-account';
import { useNotify } from '@/modules/notification/hooks/use-notify';
import { NotificationChannel } from '@/modules/notification/types/notification';

const WithUserAndPodcast = (props: {podcastId: string, userId: string}) => {
  const { hasAbo, add, remove, loading } = usePodcastAbo(props.podcastId, props.userId)
  const notify = useNotify();
  return (
    <button
      disabled={loading}
      className={styles['button']}
      title={hasAbo ? 'Abo entfernen' : 'Abonnieren'}
      onClick={() => {
        if (hasAbo) {
          remove();
          notify(NotificationChannel.SLIM, () => <p>Podcast Abo entfernt</p>)

        } else {
          add();
          notify(NotificationChannel.SLIM, () => <p>Podcast Abo hinzugefügt</p>)
        }
      }}
    >
      {hasAbo ? (<MdRemoveCircle />) : (<MdAddCircle />)}
    </button>
  );
}

export const PodcastAbo = () => {
  const { podcast } = useLocalPodcast();
  const acc = useCurrentAccount();

  if(podcast && acc)  return <WithUserAndPodcast {...{
    podcastId: podcast.id,
    userId: acc.id,
  }} />

  

  return <></>;
}