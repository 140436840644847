import { useAudioRef } from './use-audio-ref';

export const useSeek = () => {
  const audioRef = useAudioRef();
  const seekOffset = (offset: number) => {
    if(!audioRef || audioRef.readyState < 1) return;

    const duration = audioRef.duration
    if(offset > 0) {
      if(duration - audioRef.currentTime > offset) {
        audioRef.currentTime = audioRef.currentTime + offset
      } else {
        audioRef.currentTime = audioRef.duration;
      }
      
    }
    
    if (offset < 0) {
      if(audioRef.currentTime < offset * -1) audioRef.currentTime = 0;
      else audioRef.currentTime = audioRef.currentTime + offset;
    }
    
  }

  return {
    seekOffset,
  }
}