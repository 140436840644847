import { useContext, useEffect } from 'react';
import { MediaPlayerStateContext } from '../context/media-player-context';
import { usePodcastEpisode } from '@/modules/backend/hooks/use-podcast-episode';

export const useCurrentEpisode = () => {
  const { episodeId } = useContext(MediaPlayerStateContext);
  const {episode, load} = usePodcastEpisode(episodeId)

  useEffect(() => { 
    if(episodeId) load();
  }, [episodeId, load]);

  return episode;
}