import { useContext, useEffect, useState } from 'react';
import { NotificationContext } from '../context/notification-context';
import { Notification, NotificationChannel } from '../types/notification';
import styles from './slim-notifications.module.scss'

const SlimNotification = (props: {notification: Notification, close?: () => void}) => {
  const [visible, setVisible] = useState<boolean>(false);

  useEffect(() => {
    setVisible(true)
  }, []);

  const Content = props.notification.component;

  return (
    <div onClick={props.close} className={`${styles.notification} ${visible ? styles.visible : ''}`}>
      <Content />
    </div>
  )
}

export const SlimNotifications = () => {
  const { notifications, setNotifications } = useContext(NotificationContext);

  const filteredNotifications = notifications.filter(n => n.channel === NotificationChannel.SLIM);
  filteredNotifications.reverse()
  return (
    <div className={styles.wrapper}>
      <div className={styles.inner}>
      {filteredNotifications.map(n => (
        <SlimNotification key={n.id} notification={n} close={() => setNotifications(ns => ns.filter(nsi => nsi.id !== n.id))} />
      ))}
     </div>
    </div>
  );
}