import { useValueByPath } from '..';
import { QuestionProps } from '../types'


export const QuestionYesNo = (props: QuestionProps) => {
  const [ value, setValue ] = useValueByPath(props.path);

  return (
    <div>
      <div><strong>{props.question.label}</strong></div>
      <div>
        <div>
          <label>
            <input type='radio' checked={value === true} onChange={(e) => {
              setValue(true)
            }} name={props.path} />
            <span>Ja</span>
          </label>
        </div>
        <div>
          <label>
            <input type='radio' checked={value === false} onChange={(e) => {
              setValue(false)
            }} name={props.path} />
            <span>Nein</span>
          </label>
        </div>
      </div>
    </div>
  );
}


