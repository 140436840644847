import { MdPlaylistAdd, MdOutlinePlaylistRemove } from 'react-icons/md';
import styles from './add-to-playlist.module.scss';
import { useCurrentPlaylist } from '@/modules/backend/hooks/use-current-playlist';
import { MouseEventHandler, useEffect } from 'react';
import { useNotify } from '@/modules/notification/hooks/use-notify';
import { NotificationChannel } from '@/modules/notification/types/notification';
import { useTranslation } from 'react-i18next';
import { usePlaylistList } from '@/modules/backend/hooks/use-playlist-list';
import { useCurrentAccount } from '@/modules/auth/hooks/use-current-account';
import { LoadMore } from '@/modules/layout/components/load-more';
import { usePlaylist } from '@/modules/backend/hooks/use-playlist';
import { useAddToPlaylist } from '@/modules/backend/hooks/use-add-to-playlist';
import { Playlist } from '@nimey/podcast-global-entity';
import { useRemoveFromPlaylist } from '@/modules/backend/hooks/use-remove-from-playlist';
import { SelectAccountBanner } from '@/modules/auth/components/select-account-banner';
import { withCurrentUserId } from '@/modules/backend/hoc/with-current-userid';
type AddToPlaylistProps = {
  episodeId: string;
}


const AddedNotification = (props: {playlist: Playlist}) => {
  const { t } = useTranslation();
  return (
    <p>Zur playlist hinzugefügt</p>
  );
}

const RemovedNotification = (props: {playlist: Playlist}) => {
  const { t } = useTranslation();
  return (
    <p>Aus der playlist entfernt</p>
  );
}

const LoginNotification = () => {
  return (
    <div>
      <h1>Du brauchst ein Konto um die Playlist zu verwenden</h1>
      <p>Mit einem Konto kannst du viele weitere Funktion verwenden</p>
      <SelectAccountBanner />
    </div>
  );
}

const AddToPlaylistWithUserId = (props: AddToPlaylistProps) => {
  const { playlist } = useCurrentPlaylist();
  const acc = useCurrentAccount();

  const addToPlaylist = useAddToPlaylist(acc?.id || '', playlist?.id || '')
  const removeFromList = useRemoveFromPlaylist(acc?.id || '', playlist?.id || '')

  const notify = useNotify();

  const onClickAdd: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    if(!playlist) {
      alert('create playlist')
    } else {
      addToPlaylist(props.episodeId).then(() => {
        notify(NotificationChannel.SLIM, () => <AddedNotification playlist={playlist!} />)
      })
    }
  }

  const onClickRemove: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    if(!playlist) {
      alert('create playlist')
    } else {
      removeFromList(props.episodeId).then(() => {
        notify(NotificationChannel.SLIM, () => <RemovedNotification playlist={playlist!} />)
      })
    }
  }

  

  if(playlist?.episodeIds?.includes(props.episodeId)) return (
    <button onClick={onClickRemove} className={styles['add-to-playlist']}><MdOutlinePlaylistRemove /></button>
  );

  return (
    <button onClick={onClickAdd} className={styles['add-to-playlist']}><MdPlaylistAdd /></button>
  );
}

const AddToPlaylistAnonymous = () => {
  const notify = useNotify()
  return <button onClick={() => notify(NotificationChannel.FULL_SCREEN, LoginNotification)} className={styles['add-to-playlist']}><MdPlaylistAdd /></button>
}

export const AddToPlaylist = withCurrentUserId(AddToPlaylistWithUserId, AddToPlaylistAnonymous)