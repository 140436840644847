import { Fragment } from 'react/jsx-runtime';
import { MenuItem } from './menu-item';
import { MenuDefault } from './menu-default';
import { MenuDivider } from './menu-divider';
import { useCurrentAccount } from '@/modules/auth/hooks/use-current-account';

export const MenuPlayer = () => {
  const acc = useCurrentAccount();
  return (
    <Fragment>
      <MenuDefault />
      <MenuDivider />
      {acc ? (
        <MenuItem title='Meine Abos' to='/player/my-abos' />
      ) : ''}
      <MenuItem title='Alle Podcasts' to='/player/all-podcasts' />
      <MenuItem title='Alle Episoden' to='/player/all-episodes' />
      <MenuDivider />
    </Fragment>
  );
}