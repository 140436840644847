import { useTicketList } from '@/modules/backend/hooks/use-ticket-list';
import { TicketCard } from '@/modules/global/components/ticket/card';
import { LoadMore } from '@/modules/layout/components/load-more';

export type UserTicketsProps = {
  userId: string;
}
export const UserTickets = (props: UserTicketsProps) => {
  const {allIds, loadMore, hasMore} = useTicketList({creatorId: props.userId});
  return (
    <div>
      <h2>User Tickets</h2>
      <ul>
        {allIds.map(id => <TicketCard ticketId={id} />)}
        <li><LoadMore {...{loadMore, hasMore}} /></li>
      </ul>
    </div>
  );
}