import { TicketStatus } from '@nimey/podcast-global-entity';
import { PropsWithChildren, useEffect, useState } from 'react';
import { Fragment } from 'react/jsx-runtime';

import styles from './board.module.scss';
import { useTicketList } from '@/modules/backend/hooks/use-ticket-list';
import { LoadMore } from '@/modules/layout/components/load-more';
import { MdRefresh } from 'react-icons/md';
import { Button } from '@nimey/react-ui';
import { TicketCard } from '@/modules/global/components/ticket/card';

export type TicketBoardColumnProps = PropsWithChildren<{
  status: TicketStatus;
  count: number;
}>

const Tickets = (props: TicketBoardColumnProps) => {
  const {loadPage, hasMore, loadMore, reload, allIds} = useTicketList({status: props.status});
  useEffect(() => {
    loadPage(1);
  }, [])

  const allTicketIds = allIds;
  return (
    <>
      <Button onClick={() => reload()}><MdRefresh /></Button>
      {allTicketIds.map(ticketId => <TicketCard key={ticketId} ticketId={ticketId} />)}
      <LoadMore {...{loadMore, hasMore}} />
    </>
  );
}

export const TicketBoardColumn = (props: TicketBoardColumnProps) => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <Fragment>
      <div className={styles.column}>
        <div className={styles.headline} onClick={() => setOpen(o => !o)}>
          <div className={styles.title}>{props.status}</div>
          <div className={styles.count}>{props.count}</div>
        </div>
        <div className={`${styles.tickets} ${open ? styles.open : styles.closed}`}>
          <Tickets {...props} />
        </div>
      </div>
    </Fragment>
  );
}