import { useCallback, useContext, useEffect, useState } from 'react';
import { BackendDispatchContext, BackendStateContext } from '../context/backend-context';
import { BaseError, FeatureFlag, UserFeatures } from '@nimey/podcast-global-entity';

export const useAddUserFeature = (flag: FeatureFlag, userId: string) => {
  const [ loading, setLoading ] = useState<boolean>(false);
  const [ lastError, setError ] = useState<BaseError>();
  const { asyncActions } = useContext(BackendDispatchContext)

  const add = useCallback(async () => {
    setLoading(true);
    try {
      await asyncActions!.addUserFeature(flag, userId)
      setLoading(false);
    } catch(e) {
      setError(e as BaseError);
    }
    setLoading(false);
    return undefined;
  }, [flag, userId, asyncActions])


  return {
    loading,
    lastError,
    add
  };
}