import { Fragment } from 'react/jsx-runtime';
import { MenuDefault } from './menu-default';
import { MenuDivider } from './menu-divider';
import { MenuItem } from './menu-item';

export const MenuSupport = () => {
  return (
    <Fragment>
      <MenuDefault />
      <MenuDivider />
      <MenuItem to='/support/user' title='Benutzer' />
      <MenuItem to='/support/feature' title='Funktionen' />
    </Fragment>
  );
}