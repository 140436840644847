import { MediaPlayerAction, MediaPlayerActionKind } from '../types/media-player-action';
import { MediaPlayerState } from '../types/media-player-state';

export const mediaPlayerReducer = (oldState: MediaPlayerState, actions: MediaPlayerAction | Array<MediaPlayerAction>): MediaPlayerState => {
  let state = {...oldState}; 
  for(const action of Array.isArray(actions) ? actions : [actions]) {
    switch(action.type) {
      case MediaPlayerActionKind.SET_EPISODE_ID:
        state.episodeId = action.payload;
        state.episode = undefined;
        state.episodeLoading = true;
        state.isRestoredEpisode = false;
        if(state.episodeId)
          localStorage.setItem('podcast/media-player/episode-id', state.episodeId)
        else localStorage.removeItem('podcast/media-player/episode-id');
        break;
      case MediaPlayerActionKind.SET_EPISODE:
        state.episode = action.payload;
        state.episodeId = action.payload?.id;
        state.episodeLoading = !state.episode;
        if(state.episode)
          localStorage.setItem('podcast/media-player/episode-id', state.episode.id)
        else localStorage.removeItem('podcast/media-player/episode-id');
        break;
      case MediaPlayerActionKind.SET_EPISODE_LOADING:
        state.episodeLoading = action.payload;
        break;
      case MediaPlayerActionKind.SET_PLAY:
        state.play = action.payload;
        break;
      case MediaPlayerActionKind.SET_AUDIO_REF:
        state.audioRef = action.payload;
        break;
      case MediaPlayerActionKind.SET_SETTINGS:
        localStorage.setItem('podcast/media-player/settings', JSON.stringify(action.payload))
        state.settings = action.payload;
        break;
    }
  }

  return state;
}