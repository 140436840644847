import { useAccountList } from '../hooks/use-account-list';
import { useSetCurrentAccount } from '../hooks/use-set-current-account';
import { useStartLogin } from '../hooks/use-start-login';

import { Button } from '@nimey/react-ui';

import styles from './select-account-banner.module.scss';

export const SelectAccountBanner = () => {
  const accountList = useAccountList();
  const startLogin = useStartLogin();
  const setCurrentAccount = useSetCurrentAccount();
  return (
    <div>
      {accountList.length > 0 ? (<p>Konto wählen</p>) : ''}
      <ul>
        {accountList.map(acc => (
          <li key={acc.id} onClick={() => setCurrentAccount(acc)}>{acc.id} {acc.email}</li>
        ))}
      </ul>

      <Button primary onClick={() => startLogin()}>Jetzt anmelden / registrieren</Button>
    </div>
  );
}