import { useCallback, useContext, useState } from 'react';
import { BackendDispatchContext } from '../context/backend-context';
import { BaseError } from '@nimey/podcast-global-entity';
import { WrappedClientResponse } from '@nimey/podcast-client';

export function useCreateEntity<T extends unknown, K extends unknown>(createCallback: (_: T) => Promise<K>) {
  const [ loading, setLoading ] = useState<boolean>(false);
  const [ lastError, setError ] = useState<BaseError>();

  const create = useCallback(async (createPayload: T) => {
    setLoading(true);
    try {
      const entity = await createCallback(createPayload);
      setLoading(false);
      return entity;
    } catch(e) {
      setError(e as BaseError);
    }
    setLoading(false);
    return undefined;
  }, [createCallback])

  return {
    loading,
    lastError,
    create,
  }
}