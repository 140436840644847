import { usePodcast } from '@/modules/backend/hooks/use-podcast';
import { Podcast } from '@nimey/podcast-global-entity';
import { PropsWithChildren, createContext, useContext, useEffect } from 'react';

export const ProdcastProviderContext = createContext<{podcast: Podcast | undefined}>({podcast: undefined});

export const useLocalPodcast = () => {
  return useContext(ProdcastProviderContext);
}

type ProdcastProviderProps = PropsWithChildren<{
  podcastId: string;
}>;

export const PodcastProvider = (props: ProdcastProviderProps) => {
  const { podcast, load } = usePodcast(props.podcastId);
  useEffect(() => { load() }, [load, props.podcastId])

  return (
    <ProdcastProviderContext.Provider value={{podcast}}>
      {props.children}
    </ProdcastProviderContext.Provider>
  );
}