import { Fragment } from 'react/jsx-runtime'
import { AssistantProps, AssistantState } from './types'
import { Question } from './question/base';
import { createContext, Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';

const AssistantContext = createContext<{value: AssistantState, setValue: Dispatch<SetStateAction<AssistantState>>}>({
  value: {},
  setValue: () => {}
});


export const useValueByPath = (path: string) => {
  const context = useContext(AssistantContext);

  return [ context.value[path], (value: SetStateAction<any>) => context.setValue((v => ({...v, [path]: value}))) ]
}

const generatePath = (...pathElements: Array<string | undefined>): string => {
  return pathElements.filter(p => !!p).join('.')
}

const AssistantStage = (props: AssistantProps) => {
  const { value } = useContext(AssistantContext);
  const { tree, path } = props;
  return (
    <Fragment>
      {tree.questions.map(question => (
        <Question key={question.name} {...{question, path: generatePath(path, tree.name, question.name)}} />
      ))}
      <hr />
      {tree.children.filter(child => child.condition(value)).map(child => (
        <AssistantStage key={child.tree.name} {...{tree: child.tree, path: generatePath(path, tree.name)}} />
      ))}
    </Fragment>
  )
}

export const Assistant = (props: AssistantProps) => {
  const [ value, setValue ] = useState<AssistantState>({});

  useEffect(() => {
    if(props.setResult) {
      props.setResult(value);
    }
  }, [ value ])

  return (
    <AssistantContext.Provider value={{value, setValue}}>
      <AssistantStage {...props} />
    </AssistantContext.Provider>
  )
}