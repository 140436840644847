import { ValidationError } from '../../error/validation-error.js';
export var PodcastEpisodeType;
(function (PodcastEpisodeType) {
    PodcastEpisodeType["EPISODIC"] = "episodic";
    PodcastEpisodeType["SERIAL"] = "serial";
    PodcastEpisodeType["UNKNOWN"] = "unknown";
})(PodcastEpisodeType || (PodcastEpisodeType = {}));
export var PodcastListType;
(function (PodcastListType) {
    PodcastListType["ALL"] = "all";
    PodcastListType["USER_ABO"] = "user_abo";
})(PodcastListType || (PodcastListType = {}));
export var PodcastEpisodeListType;
(function (PodcastEpisodeListType) {
    PodcastEpisodeListType["RECENT"] = "recent";
    PodcastEpisodeListType["USER_ABO_RECENT"] = "user_abo_recent";
})(PodcastEpisodeListType || (PodcastEpisodeListType = {}));
export const assertPodcastCreatePayloadValid = (org) => {
    // if(!org.name) throw new ValidationError(1, 'org name is required');
    // if(typeof org.name !== 'string') throw new ValidationError(1714474306, 'org name must be string');
};
export const assertPodcastValid = (org) => {
    if (!org.id)
        throw new ValidationError(1, 'org id is required');
    assertPodcastCreatePayloadValid(org);
};
