import { useTicketMessage } from '@/modules/backend/hooks/use-ticket-message';
import { DateDuration } from '@/modules/global/components/date-duration';
import { UserNameInfo } from '@/modules/global/components/user/user-name-info';

import styles from './tickets.module.scss';
import { useUser } from '@/modules/backend/hooks/use-user';
import { TicketStatusLabel } from './status-label';
import { UserIcon } from '../user/user-icon';

type TicketMessageProps = {
  ticketMessageId: string;
}
export const TicketMessage = (props: TicketMessageProps) => {
  const { message } = useTicketMessage(props.ticketMessageId);
  const { user } = useUser(message?.creatorId);

  if (!message) return <></>
  return (
    <li className={styles.message}>
      <div className={styles.creator}>{message.creatorId ? (<UserIcon user={user!} />) : <div />}</div>
      <div className={styles.content}>
        <div className={styles.header}>
          <div className={styles.author}>
            {message.creatorId ? <span className={styles.name}><UserNameInfo userId={message.creatorId} /></span> : ''}
            <span className={styles.creation}><DateDuration fromTimestamp={message.createdAt} /></span>
          </div>
          <div className={styles.status}><TicketStatusLabel status={message.ticketStatus} /></div>
        </div>
        {message.text ? (
          <div className={styles.text}>{message.text}</div>
        ) : ''}
      </div>
    </li>
  );
}