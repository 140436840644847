import { useDebounce } from '@/modules/global/hooks/use-debounce';
import { useIsVisible } from '@/modules/global/hooks/use-is-visible';
import { useEffect, useRef, useState } from 'react';
import { Spinner } from './spinner';

import styles from './load-more.module.scss';
import { MdExpandMore } from 'react-icons/md';

export const LoadMore = (props: {hasMore: boolean, loadMore: () => Promise<void>}) => {
  const { hasMore, loadMore} = props;
  const [ loading, setLoading ] = useState<boolean>(false);
  const elementRef = useRef<HTMLDivElement>(null);
  const isVisible = useIsVisible(elementRef);
  const localVisible = useDebounce(isVisible);
  const localLoading = useDebounce(loading)

  const localLoadMore = () => {
    setLoading(true)
    loadMore().then(() => setLoading(false)).catch(() => setLoading(false))
  }
  useEffect(() => {
    if(!localLoading && localVisible && hasMore) {
      localLoadMore();
    };
  }, [localLoading, localVisible, hasMore])


  return (
    <div ref={elementRef}>
      {loading ? (<div className={styles.loading}><Spinner size={'2em'} /></div>) : ''}
      {(!loading && hasMore) ? (<div className={styles.more} onClick={() => localLoadMore()}><MdExpandMore /></div>) : ''}
    </div>
  )
}