import { useLocalPodcast } from './podcast-provider';

import styles from './title.module.scss';

type PodcastTitleProps = {
  maxLines?: number;
}

export const PodcastTitle = (props: PodcastTitleProps) => {
  const { podcast } = useLocalPodcast();
  const maxLines = props.maxLines || 2;

  return (
    <span style={{'--number-of-lines': maxLines}} className={styles['max-lines']}>{podcast?.title}</span>
  );
}