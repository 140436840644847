import { LOCAL_STORAGE_KEY_CURRENT_ACCOUNT, LOCAL_STORAGE_KEY_LIST, LOCAL_STORAGE_KEY_TOKEN_SETS } from '../config/local-storage';
import { AuthAction, AuthActionKind } from '../types/auth-action';
import { AuthState } from '../types/auth-state';

export const authReducer = (state: AuthState, actions: AuthAction): AuthState => {
  let newState = {...state};

  for(const action of Array.isArray(actions) ? actions : [actions]) {
    switch(action.type) {
      case AuthActionKind.ADD:
        newState =  {
          ...newState,
          accounts: {
            ...newState.accounts,
            [action.payload.id]: action.payload,
          },
          currentAccountId: action.payload.id,
        };
        break;
  
      case AuthActionKind.REMOVE:
        if(newState.accounts[action.payload.id]) {
          delete newState.accounts[action.payload.id];
        }
        if(newState.currentAccountId === action.payload.id) newState.currentAccountId = undefined;
        const { [action.payload.id]: oldAccount, ...remainingAccounts } = newState.accounts;
        newState = {
          ...newState,
          accounts: {
            ...remainingAccounts,
          },
          currentAccountId: newState.currentAccountId && remainingAccounts[newState.currentAccountId] ? newState.currentAccountId : undefined,
        };
        break;
  
      case AuthActionKind.SET_CURRENT:
        if(!action.payload.id) {
          newState = {
            ...newState,
            currentAccountId: undefined,
          }
        } else if(state.accounts[action.payload.id]) {
          newState = {
            ...newState,
            currentAccountId: action.payload.id,
          };
        }
        break;

  
      case AuthActionKind.SET_LOADING:
        newState = {
          ...newState,
          isLoading: action.payload,
        };
        break;
  
      case AuthActionKind.ADD_TOKEN_SET:
        newState = {
          ...newState,
          tokenSets: {
            ...newState.tokenSets,
            [action.payload.access_token.sub]: action.payload
          }
        };
        break;
  
      default:
        // @ts-ignore
        throw new Error('unsupported action ' + action.type);
    }
  }

  if(newState.currentAccountId) {
    localStorage.setItem(LOCAL_STORAGE_KEY_CURRENT_ACCOUNT, JSON.stringify(newState.currentAccountId));
  } else localStorage.removeItem(LOCAL_STORAGE_KEY_CURRENT_ACCOUNT);

  localStorage.setItem(LOCAL_STORAGE_KEY_LIST, (JSON.stringify(newState.accounts)));
  localStorage.setItem(LOCAL_STORAGE_KEY_TOKEN_SETS, (JSON.stringify(newState.tokenSets)));

  return newState;
}