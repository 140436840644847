import { MdMenu } from 'react-icons/md';
import { Fragment } from 'react/jsx-runtime';
import { MenuManager } from './menu-manager';
import { Route, Routes } from 'react-router-dom';
import { MenuPlayer } from './menu-player';
import { createPortal } from 'react-dom';

import styles from './menu.module.scss';
import { useEffect, useRef, useState } from 'react';
import { MenuSelectLanguage } from './menu-select-language';
import { MenuSupport } from './menu-support';
import { MenuDivider } from './menu-divider';
import { MenuItem } from './menu-item';

const MenuContent = (props: {open: boolean, setOpen: (o: React.SetStateAction<boolean>) => void}) => {
  const menuRef = useRef<HTMLUListElement>(null);

  useEffect(() => {
    const onCloseMenu = () => {
      props.setOpen(false);
    }
    const menuElement = menuRef.current;
    if(menuElement) {
      menuElement!.addEventListener('menu-close', onCloseMenu)

    }
    return () => {
      if(menuElement) {
        menuElement.removeEventListener('menu-close', onCloseMenu)
      }
    }
  }, [menuRef, props, props.setOpen])

  return createPortal((
    <Fragment>
      <div
        className={`${styles.background} ${props.open ? styles.visible : ''}`}
        onClick={() => props.setOpen(false)}
      />
      <div className={`${styles.menu} ${props.open ? styles.visible : ''}`}>
        <ul ref={menuRef}>
          <Routes>
            <Route path='/manager' element={<MenuManager />} />
            <Route path='/manager/*' element={<MenuManager />} />

            <Route path='/support' element={<MenuSupport />} />
            <Route path='/support/*' element={<MenuSupport />} />

            <Route path='/*' element={<MenuPlayer />} />
            <Route path='' element={<MenuPlayer />} />
          </Routes>
        </ul>
        <MenuSelectLanguage />
        <MenuItem light title='Datenschutz' to='/privacy' />
        <MenuItem light title='Impressum' to='/imprint' />
        <MenuDivider />
        <center><small>&copy; {(new Date()).getFullYear()} by @nimey</small></center>
      </div>
    </Fragment>
  ), document.getElementById('modal-portal-target')!)
}

export const Menu = () => {
  const [open, setOpen] = useState<boolean>(false);
  return (
    <Fragment>
      <MdMenu onClick={() => setOpen(o => !o)} />
      <MenuContent {...{open, setOpen}} />
    </Fragment>
  )
}