import { TicketType } from '@nimey/podcast-global-entity'

export type TicketTypeSelectorProps = {
  type?: TicketType;
  setType: (v: TicketType) => void;
}

export const TicketTypeSelector = (props: TicketTypeSelectorProps) => {
  return (
    <select name='type' value={props.type} onChange={(e) => props.setType(e.target.value as TicketType)}>
      <option value={''}>select</option>
      {Object.values(TicketType).filter(ty => ty !== TicketType.BASE).map(ty => <option key={ty} value={ty}>{ty}</option>)}
    </select>
  );
}