import { ISSUER_URL } from '../config/oidc';
import { IssuerConfig } from '../types/issuer-config';

let issuerConfig: IssuerConfig | undefined;

export const getIssuerConfig = async () => {
  if(issuerConfig) return issuerConfig;
  try {
    const response = await fetch(ISSUER_URL);
    const body = await response.json();
    issuerConfig = body as IssuerConfig;

    return issuerConfig;
  } catch(e) {
    console.log(e);
    return false;
  }
}