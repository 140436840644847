import { useContext, useEffect, useState } from 'react';
import { useAudioRef } from '../hooks/use-audio-ref';
import { MediaPlayerDispatchContext } from '../context/media-player-context';

import styles from './media-player.module.scss'


const MinutesAndSeconds = (props: {seconds: number, negative?: boolean}) => {
  const minutes = Math.floor(props.seconds / 60);
  const seconds = Math.round((props.seconds - (minutes * 60)))

  return <span>{props.negative ? '-' : ''}{minutes}:{seconds.toString().padStart(2, '0')}</span>
}

export const PlayerProgressSlim = () => {
  const audioRef = useAudioRef();
  const [duration, setDuration] = useState(audioRef?.duration || 0);
  const [pos, setPos] = useState(audioRef?.currentTime || 0);
  const [buffered, setBuffered] = useState(0);

  const style = {
    '--buffered-width': `${(buffered / duration) * 100}%`,
    '--playback-width': `${(pos / duration) * 100}%`,
  } as React.CSSProperties;


  const onTimeUpdate = () => {
    if(duration !== audioRef?.duration) setDuration(audioRef!.duration)
    setPos(audioRef!.currentTime)

    if(audioRef?.buffered.length) {
      const buff = audioRef?.buffered.end(audioRef.buffered.length - 1)
      setBuffered(buff || 0);
    }
  }

  useEffect(() => {
    if(audioRef) {
      audioRef.addEventListener('timeupdate', onTimeUpdate);
    }

    return () => {
      if(!audioRef) return;
      audioRef.removeEventListener('timeupdate', onTimeUpdate);
    }
  }, [audioRef])

  return (
    <div className={`${styles.progress} ${styles['slim-progress']}`}>
      <input
        className={styles.bar}
        type='range'
        max={duration || 0}
        value={pos || 0}
        style={style}
      />
    </div>
  )
}


export const PlayerProgress = () => {
  const audioRef = useAudioRef();
  const { asyncActions } = useContext(MediaPlayerDispatchContext);

  const [duration, setDuration] = useState(audioRef?.duration || 0);
  const [pos, setPos] = useState(audioRef?.currentTime || 0);
  const [buffered, setBuffered] = useState(0);

  const style = {
    '--buffered-width': `${(buffered / duration) * 100}%`,
    '--playback-width': `${(pos / duration) * 100}%`,
  } as React.CSSProperties;


  const onTimeUpdate = () => {
    if(duration !== audioRef?.duration) setDuration(audioRef!.duration)
    setPos(audioRef!.currentTime)

    if(audioRef?.buffered.length) {
      const buff = audioRef?.buffered.end(audioRef.buffered.length - 1)
      setBuffered(buff || 0);
    }
  }

  useEffect(() => {
    if(audioRef) {
      audioRef.addEventListener('timeupdate', onTimeUpdate);
    }

    return () => {
      if(!audioRef) return;
      audioRef.removeEventListener('timeupdate', onTimeUpdate);
    }
  }, [audioRef])

  return (
    <div className={styles.progress}>
      <input
        onChange={(e) => {
          asyncActions!.setPos(parseFloat(e.target.value))
        }}
        className={styles.bar}
        type='range'
        max={duration || 0}
        value={pos || 0}
        style={style}
      />
      <div className={styles.times}>
        <MinutesAndSeconds seconds={pos} />
        <MinutesAndSeconds negative seconds={duration - pos} />
      </div>
    </div>
  )
}