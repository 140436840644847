import { FeatureFlag } from '@nimey/podcast-global-entity';
import { BackendDispatchContext, BackendStateContext } from '../context/backend-context';
import { useContext, useEffect, useMemo } from 'react';
import { AuthStateContext } from '@/modules/auth/context/auth-context';
import { useFeatureFlags } from './use-feature-flags';

export const useHasFeature = (flag: FeatureFlag, id?: string) => {
  const { asyncActions } = useContext(BackendDispatchContext)
  const state = useContext(BackendStateContext);
  const authState = useContext(AuthStateContext);


  const currentAccountId = id || authState.currentAccountId!;

  const { features, load } = useFeatureFlags(currentAccountId);
  useEffect(() => { load() }, [currentAccountId])

  return useMemo(() => features?.flags.includes(flag), [features, flag])
}