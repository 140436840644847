import { CSSProperties, Fragment, PropsWithChildren, useEffect, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

import styles from './modal.module.scss';
import { MdClose } from 'react-icons/md';
import { Duration } from '@nimey/units';

const useIsTouch = () => {
  return useMemo(() => {
    return (('ontouchstart' in window) ||
      (navigator.maxTouchPoints > 0) ||
      ('msMaxTouchPoints' in navigator && (navigator as {msMaxTouchPoints: number}).msMaxTouchPoints > 0));
  }, []);
}

export type ModalProps = PropsWithChildren<{
  isOpen: boolean;
  close: () => void;
  title?: string;
  footer?: React.JSX.Element;
  fitContent?: boolean;
}>

export const Modal = (props: ModalProps) => {
  const { close, isOpen } = props;
  const [firstOpened, setFirstOpened] = useState<boolean>(props.isOpen);
  const [delayedOpen, setDelayedOpen] = useState<boolean>(props.isOpen);
  const scrollableDiv = useRef<HTMLDivElement>(null);

  const isTouch = useIsTouch();

  useEffect(() => {
    if(!firstOpened && isOpen) setFirstOpened(true)
  }, [isOpen, firstOpened])

  useEffect(() => {
    const onKeyUp = (e: KeyboardEvent) => {
      if(e.key === 'Escape') close();
    }
    window.addEventListener('keyup', onKeyUp)

    return () => {
      window.removeEventListener('keyup', onKeyUp)
    }
  }, [close])

  const resizeIntervalRef = useRef<number>();
  const [innerHeight, setInnerHeight] = useState<string>('75vh');
  useEffect(() => {
    let currentInterval = resizeIntervalRef.current
    if(resizeIntervalRef.current) window.clearInterval(resizeIntervalRef.current);

    if(props.fitContent) {
      currentInterval = window.setInterval(() => {

        const elem = scrollableDiv.current?.querySelector(`.${styles.content}`);
        if (!elem) return;
        const totalHeight = Array.from(elem?.childNodes || []).reduce((acc, cur) => acc + (cur as HTMLElement).getBoundingClientRect().height, 0)
        setInnerHeight(`${totalHeight + 32}px`)

      }, +Duration.millis(500));
    }
    


    return () => {
      if(currentInterval) window.clearInterval(currentInterval)
    }
  }, [props.fitContent, setInnerHeight])

  useEffect(() => {
    setTimeout(() => {
      setDelayedOpen(props.isOpen)
      if (isTouch && scrollableDiv.current) scrollableDiv.current.scrollBy({top: scrollableDiv.current.getBoundingClientRect().height, behavior: 'smooth'})
    }, 300)
  }, [props.isOpen, setDelayedOpen, isTouch])

  useEffect(() => {
    const eventListener = (e: any) => {
      const elem = scrollableDiv.current;
      if(!elem) return;
      const rect = elem.getBoundingClientRect();
      const relativeScroll = elem.scrollTop / rect.height;
      if(relativeScroll > 0.5) {
        scrollableDiv.current.scrollBy({top: rect.height, behavior: 'smooth'})
      } else close();
    }
    if(isTouch && scrollableDiv.current) {
      scrollableDiv.current.addEventListener('scrollend', eventListener)
      scrollableDiv.current.addEventListener('ontouchend', eventListener)

    }
  }, [isTouch, close])

  if(!firstOpened) return <></>;

  const style = {
    '--modal-inner-height': innerHeight,
  } as CSSProperties

  return createPortal((
    <Fragment>
      <div style={style} className={`${styles.outer} ${isTouch ? styles.touch : styles.notouch} ${delayedOpen ? styles.visible : styles.hidden} ${props.isOpen ? styles['pre-open'] : styles['pre-close']}`}>
        <div className={styles.background} onClick={() => close()} />
        <div ref={scrollableDiv} className={styles['inner-scrollable']}>
          <div className={styles['inner-scrollcontainer']}>
            <div className={styles.inner}>
              {props.title ? (
                <div className={styles.header}>
                  <span>{props.title || ''}</span>
                  <button onClick={() => close()}><MdClose /></button>
                </div>
              ) : ''}
              <div id={styles.content} className={styles.content}>
              {props.children}
              </div>
              {props.footer ? props.footer : ''}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  ), document.getElementById('modal-portal-target')!)
}