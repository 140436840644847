import { useCallback, useContext, useEffect } from 'react';
import { BackendDispatchContext, BackendStateContext } from '../context/backend-context';
import { UserFeatures } from '@nimey/podcast-global-entity';

export const useGlobalFeatureFlags = () => {
  const { asyncActions } = useContext(BackendDispatchContext)
  const state = useContext(BackendStateContext);
  const id = 'global';


  const entity = state.entities['feature'] ? state.entities['feature'][id] || undefined : undefined;

  const isLoaded = !!entity;

  const load = useCallback(async (force: boolean = false) => {
    if((!isLoaded || force)) asyncActions!.loadGlobalFeatureFlags();
  }, [asyncActions, isLoaded]);

  useEffect(() => {
    if(entity?.invalidated) load(true);
  }, [entity?.invalidated, load])

  return {
    features: (entity?.entity as UserFeatures),
    isLoaded: !!entity,
    load
  };
}