import { Fragment } from 'react/jsx-runtime';
import { TicketActionInputProps } from './base';
import { Button } from '@nimey/react-ui';

export const TicketActionInputCta = (props: TicketActionInputProps) => {
  const { input } = props

  return (
    <Fragment>
      <Button type='submit'>{input.label}</Button>
    </Fragment>
  )
}