import React from 'react';
import { MediaPlayerState } from '../types/media-player-state';
import { getAsyncActions } from '../reducer/media-player-async-actions';
import { MediaPlayerAction } from '../types/media-player-action';

const getSavedEpisode = () => {
  const item = localStorage.getItem('podcast/media-player/episode-id');
  if(typeof item === 'string') return item;

  return undefined;
}

const getSettings = () => {
  const item = localStorage.getItem('podcast/media-player/settings');
  try {
    if(typeof item === 'string') return JSON.parse(item);  
  } catch {}
  

  return {
    autoplay: false
  };
}

export const initialState: MediaPlayerState = {
  episodeId: getSavedEpisode(),
  isRestoredEpisode: true,
  episodeLoading: false,
  episode: undefined,
  play: false,
  audioRef: null,
  settings: getSettings()
}

type MediaPlayerDispatchContextType = {dispatch: React.Dispatch<MediaPlayerAction>, asyncActions?: ReturnType<typeof getAsyncActions>};

export const MediaPlayerStateContext = React.createContext<MediaPlayerState>(initialState);
export const MediaPlayerDispatchContext = React.createContext<MediaPlayerDispatchContextType>({dispatch: () => {}});