import React from 'react';
import { Notification } from '../types/notification';

type NotificationContextType = {
  notifications: Notification[];
  setNotifications: (v: React.SetStateAction<Notification[]>) => void
  addNotification: (n: Notification) => void
}

export const initialState: NotificationContextType = {
  notifications: [],
  setNotifications: () => {},
  addNotification: () => {},
}

export const NotificationContext = React.createContext<NotificationContextType>(initialState)