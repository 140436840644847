import { Table, Td, Th, Tr } from '@/modules/layout/components/table';
import { PodcastRequestTicket } from '@nimey/podcast-global-entity';
import { Fragment } from 'react/jsx-runtime';

type TicketModalProps = {
  ticket: PodcastRequestTicket;
  isOpen: boolean;
  close: () => void;
}


export const TicketTypePodcastRequest = (props: TicketModalProps) => {
  const { ticket } = props;

  return (
    <Fragment>
      <Table>
        <tbody>
          <Tr>
            <Th>Feed URL</Th>
            <Td>{ticket.feedUrl || '---'}</Td>
          </Tr>
          <Tr>
            <Th>Title</Th>
            <Td>{ticket.podcastTitle}</Td>
          </Tr>
          <Tr>
            <Th>Website</Th>
            <Td>{ticket.podcastWebsite}</Td>
          </Tr>
          <Tr>
            <Th>Social</Th>
            <Td>{ticket.podcastSocial}</Td>
          </Tr>
          <Tr>
            <Td colSpan={2} style={{whiteSpace: 'preserve'}}>
              {ticket.info}
            </Td>
          </Tr>
        </tbody>
      </Table>
    </Fragment>
  );
}