import React from 'react';

import styles from './table.module.scss';

interface TableProps extends React.TableHTMLAttributes<HTMLTableElement> {}
export const Table = (props: TableProps) => {
  return (
    <table className={styles.table} {...props}>{props.children}</table>
  )
}

interface TrProps extends React.TdHTMLAttributes<HTMLTableRowElement> {}
export const Tr = (props: TrProps) => {
  return (
    <tr className={styles.tr} {...props}>{props.children}</tr>
  )
}

interface ThProps extends React.ThHTMLAttributes<HTMLTableCellElement> {}
export const Th = (props: ThProps) => {
  return (
    <th className={styles.th} {...props}>{props.children}</th>
  )
}

interface TdProps extends React.TdHTMLAttributes<HTMLTableCellElement> {}
export const Td = (props: TdProps) => {
  return (
    <td className={styles.td} {...props}>{props.children}</td>
  )
}