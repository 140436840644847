import { BaseTicket, isPodcastClaimOwnershipTicket, isPodcastRequestTicket, isQuotaRequestTicket } from '@nimey/podcast-global-entity';
import { TicketTypeQuotaRequest } from './quota-request';
import { TicketTypePodcastRequest } from './podcast-request';
import { TicketTypePodcastClaimOwnership } from './podcast-claim-ownership';

type TicketTypeProps = {
  ticket?: BaseTicket;
  isOpen: boolean;
  close: () => void;
}

export const TicketType = (props: TicketTypeProps) => {
  const { ticket } = props;
  if(!ticket) return <></>
  if(isQuotaRequestTicket(ticket)) {
    return <TicketTypeQuotaRequest {...props} ticket={ticket} />
  }

  if(isPodcastRequestTicket(ticket)) {
    return <TicketTypePodcastRequest {...props} ticket={ticket} />
  }

  if(isPodcastClaimOwnershipTicket(ticket)) {
    return <TicketTypePodcastClaimOwnership {...props} ticket={ticket} />
  }


  return <></>
}