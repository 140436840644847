import { BaseController } from './base.controller.js';
export class FeatureController extends BaseController {
    client;
    constructor(client) {
        super();
        this.client = client;
    }
    getByUserId(id) {
        return this.client.get(`/feature/user/${id}`);
    }
    addUser(id, flag) {
        return this.client.post(`/feature/user/${id}/${flag}`, {});
    }
    removeUser(id, flag) {
        return this.client.delete(`/feature/user/${id}/${flag}`);
    }
    getGlobal() {
        return this.client.get(`/feature/global`);
    }
    addGlobal(flag) {
        return this.client.post(`/feature/global/${flag}`, {});
    }
    removeGlobal(flag) {
        return this.client.delete(`/feature/global/${flag}`);
    }
    getUserByFlag(flag, skip = 0, limit = 10) {
        const params = new URLSearchParams({
            skip: skip.toString(),
            limit: limit.toString(),
        });
        return this.client.get(`/feature/global/user/${flag}`, params);
    }
}
