import { useContext } from 'react';
import { BackendDispatchContext } from '../context/backend-context';
import { useEntityList } from './use-entity-list';
import { PodcastEpisodeFilter, PodcastEpisodeListType } from '@nimey/podcast-global-entity';

export const usePodcastEpisodeList = (filter: PodcastEpisodeFilter, listType: PodcastEpisodeListType) => {
  const { asyncActions } = useContext(BackendDispatchContext)
  const filterKey = JSON.stringify({...filter, listType});


  return useEntityList(filter, 'podcast-episode', async (filter: PodcastEpisodeFilter, pageNumber?: number, pageSize?: number) => {
    return asyncActions!.loadPodcastEpisodeList(filter, listType, pageNumber, pageSize)
  }, filterKey)
}