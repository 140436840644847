import { useContext } from 'react';
import { BackendDispatchContext, BackendStateContext } from '../context/backend-context';

export const useUserSearch = (query: string) => {
  const { asyncActions } = useContext(BackendDispatchContext)
  const state = useContext(BackendStateContext);

  const filterKey = JSON.stringify({ query });

  const entityList = (state.entityLists['user'] || {})[filterKey];

  const isPageLoaded = (pageNumber: number) => entityList?.pages.find(p => p.pageNumber === pageNumber);
  const hasMore = !entityList || !isPageLoaded(entityList.listMeta.totalPages);

  return {
    meta: entityList?.listMeta,
    pages: entityList?.pages || [],
    loadPage: async (pageNumber: number = 1, force: boolean = false) => {
      if(force || !isPageLoaded(pageNumber)) return asyncActions!.searchUsers(query, pageNumber);
    },
    hasMore,
    loadMore: async () => {
      if(!hasMore) return;
      let pageNumber = 0;
      if(!entityList?.pages[0]) pageNumber = 1;
      else pageNumber = entityList.pages[entityList.pages.length - 1].pageNumber + 1;
      return asyncActions!.searchUsers(query, pageNumber);
    }
  };
}