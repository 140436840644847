import { useMemo } from 'react';
import styles from './organization.module.scss';
import { Organization } from '@nimey/podcast-global-entity';
export const OrganizationIcon = (props: {org: Organization, onClick?: React.MouseEventHandler}) => {
  const color = useMemo(() => {
    const str = props.org.name;
    let hash = 0;
    str.split('').forEach(char => {
      hash = char.charCodeAt(0) + ((hash << 5) - hash)
    })

    let color = '#'
    for (let i = 0; i < 3; i++) {
      const value = (hash >> (i * 8)) & 0xff
      color += value.toString(16).padStart(2, '0')
    }
    return color
  }, [props.org.name])

  return (
    <div onClick={props.onClick} style={{backgroundColor: color}} className={styles.icon}>{(props.org.name || '').substring(0, 1).toUpperCase()}</div>
  )
}