import { useLocalPodcast } from './podcast-provider';
import styles from './artwork.module.scss';
import { FeatureFlag, ImageSize } from '@nimey/podcast-global-entity';
import { useHasFeature } from '@/modules/backend/hooks/use-has-feature';
import { useMemo } from 'react';
import { ARTWORK_URL } from '@/modules/backend/config/artwork';

export type ArtworkProps = {
  episodeId?: string;
  podcastId?: string;
  fallbackImage?: string;
  size?: ImageSize;
  className?: string;
}

export const Artwork = (props: ArtworkProps) => {
  const artworkProxyEnabled = useHasFeature(FeatureFlag.ARTWORK_PROXY);

  const src = useMemo(() => {
    if(typeof artworkProxyEnabled === 'undefined') return '';

    if(artworkProxyEnabled) {
      if(!props.podcastId && ! props.episodeId) return '';
      const fileName = 'artwork.jpg'; //fallback.pathname.substring(fallback.pathname.lastIndexOf('/')+1).replace(/\.[a-z]+?$/, '').concat('.jpg');
      const url = new URL(ARTWORK_URL);
      if(props.podcastId) url.pathname =`${url.pathname.replace(/^\//, '')}/p/${props.podcastId}/${props.size || ImageSize.MEDIUM}/---/${fileName}`;
      else if(props.episodeId) url.pathname =`${url.pathname.replace(/^\//, '')}/e/${props.episodeId}/${props.size || ImageSize.MEDIUM}/---/${fileName}`;

      return url.toString();
    }

    return props.fallbackImage;
  }, [props.episodeId, props.podcastId, props.size, props.fallbackImage, artworkProxyEnabled]);

  return (
    <img className={props.className} src={src} />
  );
}

type ArtworkWrapperProps = React.PropsWithChildren<{
  width: number
}>

export const ArtworkWrapper = (props: ArtworkWrapperProps) => {
  return (
    <div className={styles['artwork-wrapper']} style={{width: props.width, height: props.width}}>{props.children}</div>
  )
}

type PodcastArtworkProps = {
  podcastId?: string;
}

export const PodcastArtwork = (props: PodcastArtworkProps) => {
  const { podcast } = useLocalPodcast();

  return (
    <Artwork className={styles.artwork} podcastId={podcast?.id} size={ImageSize.MEDIUM} fallbackImage={podcast?.imageLink} />
  );
}