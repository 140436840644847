import { TicketType } from '@nimey/podcast-global-entity'
import { QuotaRequestInput } from './quota-request'
import { PodcastRequestInput } from './podcast-request'

type TicketTypeProps = {
  type?: TicketType
}
export const TicketTypeInput = (props: TicketTypeProps) => {
  if(props.type === TicketType.QUOTA_REQUEST) return <QuotaRequestInput />
  if(props.type === TicketType.PODCAST_REQUEST) return <PodcastRequestInput />
  return (<></>)
}