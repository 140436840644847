import { useOrganizationList } from '@/modules/backend/hooks/use-organization-list';
import { useOrganization } from '@/modules/backend/hooks/use-organization';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { OrganizationIcon } from '@/modules/global/components/organization/organization-icon';
import { useEffect, useMemo } from 'react';
import { OrganizationName } from '@/modules/global/components/organization/organization-name';

import styles from './org.module.scss';
import { useTranslation } from 'react-i18next';

export const ManagerFooterOrg = () => {
  const { orgId } = useParams()
  const { organization, load } = useOrganization(orgId!);
  const { pages, loadPage, hasMore, loadMore } = useOrganizationList('default');
  const { t } = useTranslation('manager');
  const navigate = useNavigate();
  const location = useLocation();

  const allOrgIds = useMemo(() => {
    const data: string[] = []
    pages.forEach(p => data.push(...p.ids));
    return data;
  }, [pages])

  useEffect(() => {
    load();
    loadPage(1);
  })
  return (
      <div className={styles.footer}>
        {organization ? <OrganizationIcon org={organization} /> : ''}
        <select value={orgId} onChange={(e) => {
          if(e.currentTarget.value === 'more') {
            loadMore();
            return;
          }
          const newRoute = location.pathname.replace(/^\/manager\/[a-z0-9-]+(.*)/, `/manager/${e.currentTarget.value}$1`)
          navigate(newRoute);
        }}>
          <option value={organization?.id}>{organization?.name}</option>
          {allOrgIds.filter(i => organization?.id !== i).map(orgId => <option key={orgId} value={orgId}><OrganizationName orgId={orgId} /></option>)}
          {hasMore ? (<option value='more'>{t('general.load-more')}</option>) : ''}
        </select>
    </div>
  );
}