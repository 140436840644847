import { Gutter } from '@/modules/layout/components/gutter';
import { useParams } from 'react-router-dom';

import styles from './episode-detail.module.scss';
import { PodcastProvider } from '@/modules/global/components/podcast/podcast-provider';
import { ArtworkWrapper, PodcastArtwork } from '@/modules/global/components/podcast/artwork';
import { PodcastTitle } from '@/modules/global/components/podcast/title';
import { PodcastAuthor } from '@/modules/global/components/podcast/author';
import { EpisodeProvider } from '@/modules/global/components/podcast/episode/episode-provider';
import { EpisodePubDate } from '@/modules/global/components/podcast/episode/pub-date';
import { EpisodeDescription } from '@/modules/global/components/podcast/episode/description';

import { Link } from 'react-router-dom';
import { EpisodeActions } from './epispode-actions';
import { EpisodeTitle } from '@/modules/global/components/podcast/episode/title';

const WithId = (props: { podcastId: string, episodeId: string }) => {
  return (
    <PodcastProvider podcastId={props.podcastId}>
      <EpisodeProvider episodeId={props.episodeId}>
        <Gutter>
          <Link to={`/player/pod/${props.podcastId}`} className={styles.podcast}>
            <div className={styles.artwork}><ArtworkWrapper width={75}><PodcastArtwork /></ArtworkWrapper></div>
            <div className={styles.info}>
              <p className={styles.title}><PodcastTitle maxLines={1} /></p>
              <p className={styles.author}><PodcastAuthor /></p>
            </div>
          </Link>
          <div className={styles['episode-title']}><h1><EpisodeTitle maxLines={5} /></h1></div>
          <div className={styles['actions']}>
            <EpisodeActions />
          </div>
          <div className={styles.episode}>
            <p className={styles['pub-date']}><EpisodePubDate /></p>
            <div className={styles['description']}><EpisodeDescription maxLines={'inherit'} /></div>
          </div>
        </Gutter>
      </EpisodeProvider>
    </PodcastProvider>
  );
}

export const EpisodeDetail = () => {
  const { podcastId, episodeId } = useParams();

  if(!podcastId || !episodeId) return <></>
  return <WithId podcastId={podcastId} episodeId={episodeId} />
}