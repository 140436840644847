import { useCallback, useContext, useEffect } from 'react';
import { BackendDispatchContext } from '../context/backend-context';
import { Playlist } from '@nimey/podcast-global-entity';

export const useUpdatePlaylist = (userId: string) => {
  const { asyncActions } = useContext(BackendDispatchContext)

  const updateCallback = useCallback(async (playlist: Playlist) => {
    return asyncActions?.updatePlaylist(userId, playlist);
  }, [asyncActions, userId])


  return updateCallback;
}