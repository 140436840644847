import { AuthenticationError } from './authentication-error.js';
import { BaseError } from './base.js';
import { DuplicateError } from './duplicate-error.js';
import { InternalError } from './internal-error.js';
import { NotFoundError } from './not-found-error.js';
import { QuotaError } from './quota-error.js';
import { ValidationError } from './validation-error.js';
const errors = [
    BaseError,
    ValidationError,
    InternalError,
    AuthenticationError,
    NotFoundError,
    DuplicateError,
    QuotaError,
];
export const errorFactory = (errInput) => {
    const errClass = errors.find(e => e.name === errInput.type);
    if (!errClass)
        return new BaseError(errInput.code, errInput.message, errInput.options);
    return new errClass(errInput.code, errInput.message, errInput.options);
};
