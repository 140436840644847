import { useLocalEpisode } from './episode-provider';

import { Artwork, ArtworkProps } from '../artwork';

export const EpisodeArtwork = (props: Omit<ArtworkProps, 'podcastId' | 'episodeId'>) => {
  const { episode } = useLocalEpisode();
  if(!episode) return <></>;

  return (
    <Artwork {...props} episodeId={episode.id} />
  );
}