import { BaseController } from './base.controller.js';
export class PodcastController extends BaseController {
    client;
    constructor(client) {
        super();
        this.client = client;
    }
    list(filter = {}, listType, skip = 0, limit = 5) {
        const params = new URLSearchParams({
            skip: skip.toString(),
            limit: limit.toString(),
        });
        Object.entries(filter).forEach(e => params.set(`filter[${e[0]}]`, String(e[1])));
        return this.client.get(`/podcast/list/${listType}`, params);
    }
    listEpisodes(filter = {}, listType, skip = 0, limit = 5) {
        const params = new URLSearchParams({
            skip: skip.toString(),
            limit: limit.toString(),
        });
        Object.entries(filter).forEach(e => params.set(`filter[${e[0]}]`, String(e[1])));
        return this.client.get(`/podcast/episode/list/${listType}`, params);
    }
    search(query, skip = 0, limit = 5) {
        const params = new URLSearchParams({
            skip: skip.toString(),
            limit: limit.toString(),
        });
        return this.client.get(`/podcast/search/${query}`, params);
    }
    getById(id) {
        return this.client.get(`/podcast/${id}`);
    }
    getEpisodeById(id) {
        return this.client.get(`/podcast/episode/${id}`);
    }
    deleteEpisodeById(id) {
        return this.client.delete(`/podcast/episode/${id}`);
    }
    getFeedByPodcastId(podcastId) {
        return this.client.get(`/podcast/${podcastId}/feed`);
    }
    getEpisodes(ticketId, skip = 0, limit = 5) {
        const params = new URLSearchParams({
            skip: skip.toString(),
            limit: limit.toString(),
        });
        return this.client.get(`/podcast/${ticketId}/episode`, params);
    }
    setPlaybackState(episodeId, state) {
        return this.client.post(`/podcast/episode/${episodeId}/playback-state`, state);
    }
    getUserHistory(userId, skip = 0, limit = 5) {
        const params = new URLSearchParams({
            skip: skip.toString(),
            limit: limit.toString(),
        });
        return this.client.get(`/podcast//history/${userId}`, params);
    }
    getAbo(podcastId, userId) {
        return this.client.get(`/podcast/abo/${podcastId}/${userId}`);
    }
    addAbo(podcastId, userId) {
        return this.client.post(`/podcast/abo`, {
            userId,
            podcastId
        });
    }
    import(feedUrl) {
        return this.client.post(`/podcast/import`, {}, { feedUrl });
    }
    deleteAbo(podcastId, userId) {
        return this.client.delete(`/podcast/abo/${podcastId}/${userId}`);
    }
}
