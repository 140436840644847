import { PodcastProvider, useLocalPodcast } from '@/modules/global/components/podcast/podcast-provider';
import { PodcastFilter, PodcastListType } from '@nimey/podcast-global-entity';
import styles from './list.module.scss';
import { ArtworkWrapper, PodcastArtwork } from '@/modules/global/components/podcast/artwork';
import { PodcastTitle } from '@/modules/global/components/podcast/title';
import { Link } from 'react-router-dom';
import { usePodcastList } from '@/modules/backend/hooks/use-podcast-list';
import { Carousel, Tiles } from '@/modules/global/components/list';
import { useIsMobile } from '@/modules/global/hooks/use-is-mobile';

export enum ListLayout {
  CAROUSEL = 'carousel',
  TILES = 'tiles',
  LIST = 'list',
}
type PodcastListProps = {
  layout: ListLayout,
  listType: PodcastListType,
  title: string,
  filter?: PodcastFilter
}

const InnerPodcastRendererCarousel = () => {
  const { podcast } = useLocalPodcast()
  const isMobile = useIsMobile();
  return (
    <div className={styles['podcast-horizontal']}>
      <Link to={`/player/pod/${podcast?.id}`}>
        <ArtworkWrapper width={isMobile ? 80 : 200}><PodcastArtwork podcastId={podcast?.id} /></ArtworkWrapper>
        <div className={styles.title}><PodcastTitle /></div>
      </Link>
    </div>
  )
}
export const PodcastRendererCarousel = (props: {id: string}) => {
  return (
    <PodcastProvider podcastId={props.id}>
      <InnerPodcastRendererCarousel />
    </PodcastProvider>
  )
}

export const PodcastList = (props: PodcastListProps) => {
  const passProps = usePodcastList(props.filter || {}, props.listType);
  if(props.layout === ListLayout.CAROUSEL) return <Carousel entryRenderer={PodcastRendererCarousel} {...passProps} {...props} />
  if(props.layout === ListLayout.TILES) return <Tiles entryRenderer={PodcastRendererCarousel} {...passProps} {...props} />
  
  return <></>
}