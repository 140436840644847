import { PropsWithChildren, useEffect, useMemo, useReducer, useRef } from 'react';
import { MediaPlayerDispatchContext, MediaPlayerStateContext, initialState } from '../context/media-player-context';
import { mediaPlayerReducer } from '../reducer/media-player-reducer';
import { getAsyncActions } from '../reducer/media-player-async-actions';
import { MediaPlayerActionKind } from '../types/media-player-action';
import { usePodcastEpisode } from '@/modules/backend/hooks/use-podcast-episode';
import { setMediaSession } from '../util/media-session';
import { usePodcast } from '@/modules/backend/hooks/use-podcast';
import { UpdatePlayerState } from './update-player-state';
import { useAutoplay } from '../hooks/use-autoplay';

export const MediaPlayerProvider = (props: PropsWithChildren) => {
  const audioRef = useRef<HTMLAudioElement>(null);
  const sourceRef = useRef<HTMLSourceElement>(null);
  const [state, dispatch] = useReducer(mediaPlayerReducer, {
    ...initialState,
  });

  const { episode, load } = usePodcastEpisode(state.episodeId);

  const pod = usePodcast(episode?.podcastId);
  useEffect(() => {
    if(!pod.podcast || pod.podcast.id !== state.episode?.podcastId) pod.load();
  }, [state.episode?.podcastId, pod])
  useEffect(() => {
    if(!state.episode || state.episode.id !== state.episodeId) load();
  }, [state.episode, state.episodeId, load])

  useEffect(() => {
    if(episode && episode.podcastId !== pod.podcast?.id) pod.load()
  }, [episode, pod])

  useEffect(() => {
    if(episode) dispatch({type: MediaPlayerActionKind.SET_EPISODE, payload: episode})
  }, [episode])

  useEffect(() => {
    const onKeyPress = (e: KeyboardEvent) => {
      if(e.target && 'tagName' in e.target) {
        if(['INPUT', 'TEXTAREA'].includes(e.target.tagName as string)) {
          return;
        }
      }

      if(audioRef.current?.paused) audioRef.current.play();
      else if(audioRef.current?.paused === false) audioRef.current.pause();
      e.preventDefault();  
    }
    window.addEventListener('keypress', onKeyPress);

    return (() => {
      window.removeEventListener('keypress', onKeyPress);
    })
  }, [])

  const lastEpisodeId = useRef<string>();

  useEffect(() => {
    const enclosure = state?.episode?.enclosure ? state.episode.enclosure[0] : {url: ''};
    sourceRef.current!.src = enclosure.url;

    if(state.episode && state.episodeId !== lastEpisodeId.current) {
      audioRef.current!.load();
      if(state.episode.playbackState && !state.episode.playbackState.completed) {
        if(state.episode.playbackState.episodePlayedSeconds < state.episode.length) {
          audioRef.current!.currentTime = state.episode.playbackState.episodePlayedSeconds
        }
      }

      if(!state.isRestoredEpisode) audioRef.current!.play().catch((e) => {});

      lastEpisodeId.current = state.episodeId;
    }

  }, [state.episode, state.episodeId, pod.podcast])

  useEffect(() => {
    if(state.episode && pod.podcast) setMediaSession(audioRef.current!, state.episode, pod.podcast);
  }, [state.episode, pod.podcast])


  const asyncActions = getAsyncActions(state, dispatch, audioRef);

  useEffect(() => {
    dispatch({type: MediaPlayerActionKind.SET_AUDIO_REF, payload: audioRef.current})
  }, [])

  useAutoplay(audioRef.current, state.episodeId, true, dispatch);

  const onPlay = () => dispatch({type: MediaPlayerActionKind.SET_PLAY, payload: true});
  const onPause = () => dispatch({type: MediaPlayerActionKind.SET_PLAY, payload: false});

  useEffect(() => {
    const audio = audioRef.current;
    if(audio) {
      audio.addEventListener('play', onPlay)
      audio.addEventListener('pause', onPause)
    }

    return () => {
      if(audio) {
        audio.removeEventListener('play', onPlay)
        audio.removeEventListener('pause', onPause)
      }
    }
  }, [audioRef])

  return (
    <MediaPlayerStateContext.Provider value={state}>
      <MediaPlayerDispatchContext.Provider value={{dispatch, asyncActions}}>
        <UpdatePlayerState audioElement={audioRef.current} episodeId={state.episodeId} />
        {props.children}
        <div>
          <audio ref={audioRef} autoPlay={false}>
            <source ref={sourceRef} />
          </audio>
        </div>
      </MediaPlayerDispatchContext.Provider>
    </MediaPlayerStateContext.Provider>
  );
}