import React from 'react';
import { AuthState } from '../types/auth-state';
import { AuthAction } from '../types/auth-action';
import { LOCAL_STORAGE_KEY_CURRENT_ACCOUNT, LOCAL_STORAGE_KEY_LIST, LOCAL_STORAGE_KEY_TOKEN_SETS } from '../config/local-storage';
import { getAsyncActions } from '../reducer/auth-async-actions';
import { TokenInput, TokenSet } from '../util/token-set';

const persitedAccountsStr = localStorage.getItem(LOCAL_STORAGE_KEY_LIST);
const currentAccountStr = localStorage.getItem(LOCAL_STORAGE_KEY_CURRENT_ACCOUNT);
const tokenSetsStr = localStorage.getItem(LOCAL_STORAGE_KEY_TOKEN_SETS);
const tokensSets: Record<string, TokenInput> = tokenSetsStr ? JSON.parse(tokenSetsStr) : {}

export const initialState: AuthState = {
  accounts: persitedAccountsStr? JSON.parse(persitedAccountsStr) : {
  },
  currentAccountId: currentAccountStr ? JSON.parse(currentAccountStr) : undefined,
  isLoading: false,
  tokenSets: {
    ...Object.fromEntries(Object.entries(tokensSets).map(v => [v[0], new TokenSet(v[1])]))
  },
}

type AuthDispatchContextType = {dispatch: React.Dispatch<AuthAction>, asyncActions?: ReturnType<typeof getAsyncActions>};

export const AuthStateContext = React.createContext<AuthState>(initialState);
export const AuthDispatchContext = React.createContext<AuthDispatchContextType>({dispatch: () => {}});