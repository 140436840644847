import { usePodcastSearch } from '@/modules/backend/hooks/use-podcast-search';
import { useValueByPath } from '..';
import { QuestionProps } from '../types'
import { Fragment, useEffect, useState } from 'react';
import { LoadMore } from '@/modules/layout/components/load-more';
import { useDebounce } from '@/modules/global/hooks/use-debounce';
import { usePodcastSearchResultEntity } from '@/modules/backend/hooks/use-podcast-search-result-entity';
import { PodcastProvider, useLocalPodcast } from '../../podcast/podcast-provider';
import { Podcast } from '@nimey/podcast-global-entity';
import { PodcastTitle } from '../../podcast/title';
import { ArtworkWrapper, PodcastArtwork } from '../../podcast/artwork';

const ResultPodcast = (props: QuestionProps & {podcast: Podcast}) => {
  const [ value, setValue ] = useValueByPath(props.path);
  return (
      <PodcastProvider podcastId={props.podcast.id}>
        <label style={{display: 'flex', alignItems: 'center', gap: '.5em', margin: '.5em 0'}}>
          <input type='radio' checked={value === props.podcast.feedUrl} onChange={(e) => setValue(props.podcast.feedUrl)}  name={props.path} />
          <ArtworkWrapper width={50}><PodcastArtwork /></ArtworkWrapper>
          <PodcastTitle />
        </label>
      </PodcastProvider>
  );
}

const ResultEntry = (props: QuestionProps & {id: string}) => {
  const { entity, type } = usePodcastSearchResultEntity(props.id);
  
  if(type === 'podcast') return <ResultPodcast {...props} {...{podcast: entity as Podcast}} />
  
  return <></>
}

const SearchResult = (props: QuestionProps & {query: string}) => {
  const { query } = props;
  const { allIds, loadPage, pages } = usePodcastSearch(query);
  useEffect(() => {
    loadPage(1);
  }, [ query ])

  return (
    <div>
      {allIds.map(id => <ResultEntry key={id} {...props} id={id} />)}
    </div>
  );
}

export const QuestionFeedUrl = (props: QuestionProps) => {
  const [ value, setValue ] = useValueByPath(props.path);
  const [ searchQuery, setSearchQuery ] = useState<string>('')
  const debouncedQuery = useDebounce(searchQuery);

  const { question } = props;
  return (
    <div>
      <p><strong>{ question.label }</strong></p>
      <div><input placeholder={'Podcast suchen'} value={searchQuery || ''} onChange={e => setSearchQuery(e.currentTarget.value)} /></div>
      {debouncedQuery ? <SearchResult {...props} query={debouncedQuery as string} /> : ''}

      <div><input type='text' placeholder={'Feed URL'} value={value || ''} onChange={(e) => {setValue(e.currentTarget.value)}} /></div>
    </div>
  );
}
