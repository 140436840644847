import { PodcastEpisode } from '@nimey/podcast-global-entity';
import { BackendAction, BackendActionKind } from '../types/backend-action';
import { BackendState } from '../types/backend-state';

export const backendReducer = (oldState: BackendState, actions: BackendAction | Array<BackendAction>): BackendState => {
  let state = {...oldState}; 
  for(const action of Array.isArray(actions) ? actions : [actions]) {
    switch(action.type) {
      case BackendActionKind.FLUSH_TAGS:
        const tags = Array.from(action.payload.tags || new Set<string>());
        const filteredEntities = state.entities;
        for(const type in filteredEntities) {
          filteredEntities[type] = Object.fromEntries(Object.entries(filteredEntities[type]).map(e => {
            e[1].invalidated = true;
            return e;
          }))
        }

        const filteredLists = state.entityLists;
        for(const type in filteredLists) {
          filteredLists[type] = Object.fromEntries(Object.entries(filteredLists[type]).map(e => {
            e[1].invalidated = true;
            return e;
          }))
        }

        state = {
          ...state,
          entities: filteredEntities,
          entityLists: filteredLists,
        }
        break;
      case BackendActionKind.RESET:
        state = {
          ...state,
          entities: {},
          entityLists: {},
        }
        break;
      case BackendActionKind.SET_CURRENT_PLAYLIST_ID:
        state = {
          ...state,
          currentPlaylistId: action.payload,
        }
        break;
      case BackendActionKind.UPDATE_LOCAL_PLAYBACK_STATE:
        if(state.entities['podcast-episode'] && action.payload.episodeId) {
          state.entities['podcast-episode'][action.payload.episodeId].entity = {
            ...state.entities['podcast-episode'][action.payload.episodeId].entity,
            playbackState: action.payload.playbackState
          } as PodcastEpisode
        }
        break;
      case BackendActionKind.ADD_ENTITY:
        state = {
          ...state,
          entities: {
            ...state.entities,
            [action.payload.type]: {
              ...(state.entities[action.payload.type] || {}),
              [action.payload.entity.id]: {
                entity: action.payload.entity,
                tags: action.payload.tags || new Set<string>(),
              },
            },
          },
        };
        break;
  
      case BackendActionKind.REMOVE_ENTITY:
        const {[action.payload.id]: removed, ...newEntities} = state.entities[action.payload.type]
        state = {
          ...state,
          entities: {
            ...state.entities,
            [action.payload.type]: {...newEntities},
          },
        };
        break;
  
      case BackendActionKind.ADD_ENTITY_LIST:
        const pages = ((state.entityLists[action.payload.type] || {})[action.payload.filterKey]?.pages || [])
        const listTags = action.payload.tags || new Set<string>();
        state = {
          ...state,
          entityLists: {
            ...(state.entityLists || {}),
            [action.payload.type]: {
              ...(state.entityLists[action.payload.type] || {}),
              [action.payload.filterKey]: {
                listMeta: action.payload.meta,
                tags: listTags,
                pages: pages
              }
            }
          }
        };
        break;
        case BackendActionKind.ADD_ENTITY_LIST_PAGE:
          const newPages = (state.entityLists[action.payload.type][action.payload.filterKey].pages || []).filter(p => p.pageNumber !== action.payload.pageNumber);

          newPages.push({
            pageNumber: action.payload.pageNumber,
            ids: action.payload.ids,
          })

          newPages.sort((a, b) => a.pageNumber - b.pageNumber);

          state = {
            ...state,
            entityLists: {
              ...(state.entityLists || {}),
              [action.payload.type]: {
                ...(state.entityLists[action.payload.type] || {}),
                [action.payload.filterKey]: {
                  ...(state.entityLists[action.payload.type][action.payload.filterKey] || {}),
                  pages: [...newPages],
                }
              }
            }
          };
          break;
    }
  }

  return state;  
}