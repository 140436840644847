import { Gutter } from '@/modules/layout/components/gutter';
import { UserSearchForm } from './user-search-form';
import { UserSearchResult } from './user-search-result';
import { useSearchParams } from 'react-router-dom';

const useQueryParam = () : [string, (v: string) => void] => {
  const [ searchParams, setSearchParams ] = useSearchParams();
  const setSearchValue = (v: string) => {
    if(searchParams.get('query') !== v) {
      searchParams.set('query', v);
      setSearchParams(searchParams)
    }
  }

  return [searchParams.get('query') || '', setSearchValue];
}

export const UserBoard = () => {
  const [ searchValue, setSearchValue ] = useQueryParam();
  
  return (
    <Gutter>
      <h1>USERS</h1>
      <UserSearchForm {...{searchValue, setSearchValue}} />
      {searchValue ? (
        <UserSearchResult query={searchValue} />
      ) : ''}
    </Gutter>
  );
}