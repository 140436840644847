import { useCallback, useContext } from 'react';
import { BackendDispatchContext, BackendStateContext } from '../context/backend-context';
import { Feed, PodcastEpisode } from '@nimey/podcast-global-entity';

export const usePodcastFeed = (podcastId?: string) => {
  const { asyncActions } = useContext(BackendDispatchContext)
  const state = useContext(BackendStateContext);

  const loadCallback = useCallback((id: string) => id && asyncActions!.loadPodcastFeed(id), [asyncActions])

  const entity = (podcastId && state.entities['podcast-feed']) ? (state.entities['podcast-feed'][podcastId] || undefined) : undefined;
  const isLoaded = !!entity;

  const load = useCallback(async (force: boolean = false) => (!isLoaded || force) && podcastId && loadCallback(podcastId), [podcastId, loadCallback, isLoaded])

  return {
    feed: entity?.entity as Feed | undefined,
    isLoaded: !!entity,
    load,
  };
}