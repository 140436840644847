import { useContext } from 'react';
import { BackendDispatchContext, BackendStateContext } from '../context/backend-context';
import { Organization } from '@nimey/podcast-global-entity';

export const useOrganization = (id?: string) => {
  const { asyncActions } = useContext(BackendDispatchContext)
  const state = useContext(BackendStateContext);
  const entity = id && state.entities['organization'] ? state.entities['organization'][id] || undefined : undefined;
  const isLoaded = !!entity;
  return {
    organization: entity?.entity as Organization | undefined,
    isLoaded: !!entity,
    load: async (force: boolean = false) => id && (!isLoaded || force) && asyncActions!.loadOrganization(id)
  };
}