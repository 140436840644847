import { useCallback, useContext, useState } from 'react';
import { BackendDispatchContext } from '../context/backend-context';
import { BaseError, FeatureFlag } from '@nimey/podcast-global-entity';

export const useRemoveGlobalFeature = (flag: FeatureFlag) => {
  const [ loading, setLoading ] = useState<boolean>(false);
  const [ lastError, setError ] = useState<BaseError>();
  const { asyncActions } = useContext(BackendDispatchContext)

  const remove = useCallback(async () => {
    setLoading(true);
    try {
      await asyncActions!.removeGlobalFeature(flag)
      setLoading(false);
    } catch(e) {
      setError(e as BaseError);
    }
    setLoading(false);
    return undefined;
  }, [flag, asyncActions])


  return {
    loading,
    lastError,
    remove
  };
}