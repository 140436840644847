import { useContext, useState } from 'react';
import { BackendDispatchContext } from '../context/backend-context';
import { BaseError } from '@nimey/podcast-global-entity';

export const useExecuteTicketAction = (ticketId: string, actionType: string) => {
  const { asyncActions } = useContext(BackendDispatchContext)
  const [ loading, setLoading ] = useState<boolean>(false);
  const [ lastError, setError ] = useState<BaseError>();

  const execute = async (input: Record<string, string>) => {
    setLoading(true);
    try {
      const message = await asyncActions!.executeTicketAction(ticketId, actionType, input);
      setLoading(false);
      return message;
    } catch(e) {
      setError(e as BaseError);
    }
    setLoading(false);
    return undefined;
  }

  return {
    loading,
    lastError,
    execute,
  }
}