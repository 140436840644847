import { QuotaName, ValidationError } from '../../index.js';
import { assertBaseTicketCreatePayloadValid, assertBaseTicketValid } from './base.js';
import { TicketType } from './ticket-type.js';
export function isQuotaRequestTicket(ticket) {
    return ticket.type === TicketType.QUOTA_REQUEST;
}
export function isQuotaRequestTicketCreatePayload(ticket) {
    return ticket.type === TicketType.QUOTA_REQUEST;
}
export function assertQuotaRequestTicketCreatePayloadValid(ticket) {
    if (!ticket.quotaName)
        throw new ValidationError(1, 'quota name is required');
    if (!(Object.values(QuotaName).includes(ticket.quotaName)))
        throw new ValidationError(1, 'unknown quota name ' + ticket.quotaName);
    if (!ticket.requestValue)
        throw new ValidationError(1, 'request value is required');
    if (!(typeof ticket.requestValue === 'number'))
        throw new ValidationError(1, 'request value must be number');
    if (!ticket.requestReason)
        throw new ValidationError(1, 'request reason is required');
    assertBaseTicketCreatePayloadValid(ticket);
}
export function assertQuotaRequestTicketValid(ticket) {
    if (!ticket.id)
        throw new ValidationError(1714474243, 'org id is required');
    assertQuotaRequestTicketCreatePayloadValid(ticket);
    assertBaseTicketValid(ticket);
}
