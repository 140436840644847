import { useTicketMessageList } from '@/modules/backend/hooks/use-ticket-message-list';
import { TicketMessage } from './ticket-message';
import { AddTicketMessage } from './add-ticket-message';

import styles from './tickets.module.scss';
import { LoadMore } from '@/modules/layout/components/load-more';

type TicketMessagesProps = {
  ticketId: string;
}

export const TicketMessages = (props: TicketMessagesProps) => {
  const { allIds, loadMore, hasMore } = useTicketMessageList(props.ticketId);

  return (
    <div>
      <ul className={styles['message-list']}>
        <AddTicketMessage ticketId={props.ticketId} />
        {allIds.map(messageId => <TicketMessage key={messageId} ticketMessageId={messageId} />)}
        <li><LoadMore {...{hasMore, loadMore}} /></li>
      </ul>
    </div>
  )
}
