import { useUpdatePlaybackState } from '@/modules/backend/hooks/use-update-playback-state';
import { useState, useEffect } from 'react';
import { withCurrentUserId } from '@/modules/backend/hoc/with-current-userid';

export const UpdatePlayerStateWithUser = (props: {audioElement?: HTMLAudioElement | null, episodeId?: string}) => {
  const [pos, setPos] = useState<number>(0);
  useUpdatePlaybackState(props.episodeId, pos)
  const onTimeUpdate = () => {
    setPos(props.audioElement?.currentTime || 0);
  }

  useEffect(() => {
    const audioRef = props.audioElement;
    if(audioRef) {
      audioRef.addEventListener('timeupdate', onTimeUpdate);
    }

    return () => {
      if(!audioRef) return;
      audioRef.removeEventListener('timeupdate', onTimeUpdate);
    }
  }, [props.audioElement])

  return <></>
}

export const UpdatePlayerState = withCurrentUserId(UpdatePlayerStateWithUser, () => <></>)