import { useEffect } from 'react';
import { useCurrentAccount } from '../../auth/hooks/use-current-account';
import { useNotify } from '../hooks/use-notify';
import { NotificationChannel } from '../types/notification';
import { OnAccountChangeNotification } from './notification/on-account-change';

export const RegisterAccountChange = () => {
  const acc = useCurrentAccount();
  const notify = useNotify();
  useEffect(() => {
    if(acc) {
      const last = localStorage.getItem('last-notification')
      if(last !== acc.id) {
        localStorage.setItem('last-notification', acc.id);
        notify(NotificationChannel.FULL_SCREEN, OnAccountChangeNotification)
      }
    }
  }, [acc, notify])

  return <></>
}