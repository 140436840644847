import { Validator } from '@nimey/react-ui';
import React from 'react';

export function withValidator<P extends object>(
  WrappedComponent: React.ComponentType<P>,
  name: string,
): React.FC<P> {
  const WithValidator: React.FC<P> = ({ ...props }:  P) => {
    
    return <Validator name={name}><WrappedComponent {...props as P} /></Validator>
  };
  
  return WithValidator;
}