import { Duration } from '@nimey/units';
import { useEffect, useRef, useState } from 'react';

type ValueType = string | number | undefined | boolean | Array<string | number | undefined | boolean>;

export function useDebounceWithTimeout(value: ValueType, delay: Duration | number = Duration.millis(200), timeout: Duration | number = Duration.seconds(3)) {
  const timerRef = useRef<ReturnType<typeof setTimeout>>();
  const lastChange = useRef<number>();
  const [debouncedValue, setDebouncedValue] = useState<ValueType>(value);

  useEffect(() => {
    const currentTimer = timerRef.current;

    if(currentTimer) clearTimeout(timerRef.current);

    if(!lastChange.current) lastChange.current = Date.now();
    if(Date.now() - lastChange.current > (+timeout)) {
      setDebouncedValue(value);
      lastChange.current = Date.now();
    } else {
      timerRef.current = setTimeout(() => {
        setDebouncedValue(value);
      }, +delay)
    }

    return () => {
      if(currentTimer) clearTimeout(currentTimer);
    }

  }, [value, delay, timeout])

  return debouncedValue;
}