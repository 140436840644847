import { useEffect, useMemo, useState } from 'react';

export const useIsMobile = () => {
  const [ windowSize, setWindowSize ] = useState<number>(window.innerWidth)
  useEffect(() => {
    const callback = () => setWindowSize(window.innerWidth);
    window.addEventListener('resize', callback);

    return () => {
      window.removeEventListener('resize', callback);
    }
  })

  return useMemo(() => {
    if(!('matchMedia' in window)) return false;
    return window.matchMedia('(max-width: 500px)').matches
  }, [ windowSize ]);
}