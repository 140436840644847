import { useTicketActions } from '@/modules/backend/hooks/use-ticket-actions';
import { FlexCenter } from '@/modules/layout/components/grid/flex-center';
import { Spinner } from '@/modules/layout/components/spinner';
import { BaseTicket } from '@nimey/podcast-global-entity';
import { Fragment, act, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TicketAction } from './ticket-action/base';

type TicketActionsProps = {
  ticket: BaseTicket
}
export const TicketActions = (props: TicketActionsProps) => {
  const { ticket } = props
  const [ loading, setLoading ] = useState<boolean>(true);
  const {actions, load} = useTicketActions(ticket.id);

  const { t } = useTranslation('support')

  useEffect(() => {
    setLoading(true);
    load().then(() => setLoading(false))
  }, [])

  return (
    <div>
      <p>{ t('ticket.actions.title') }</p>
      {loading ? <FlexCenter><Spinner size='2em' /></FlexCenter> : (
        <ul style={{padding: 0}}>
          {(actions || []).map((action, idx) => <TicketAction key={idx} {...{ticket, action, load}} />)}
        </ul>
      )}
    </div>
  );
}